import React from 'react';
import { ArrangementOrder, HeadCell } from '../types';
import { ORDER } from '../utils/table';

interface TUseOrder<T> {
	order: ArrangementOrder;
	orderBy: HeadCell<T> | undefined;
	handleRequestSort: (p: HeadCell<T>) => void;
}

function useOrder<T>(defaultProp: HeadCell<T> | undefined): TUseOrder<T> {
	const [order, setOrder] = React.useState<ArrangementOrder>(ORDER.DESC);
	const [orderBy, setOrderBy] = React.useState<HeadCell<T> | undefined>(
		defaultProp
	);

	const handleRequestSort = (headCell: HeadCell<T>) => {
		const isAsc = orderBy && orderBy.id === headCell.id && order === ORDER.ASC;
		const newOrder = isAsc ? ORDER.DESC : ORDER.ASC;
		setOrder(newOrder);
		setOrderBy(headCell);
	};

	return { order, orderBy, handleRequestSort };
}

export default useOrder;
