import React from 'react';
import _get from 'lodash.get';

const useSelectRow = (path: string) => {
	const [selected, setSelected] = React.useState<string[]>([]);

	const handleSelectAll = (isChecked: boolean, rows: any[]) => {
		if (isChecked) {
			const newSelectedId: string[] = rows.map(row => _get(row, path, ''));
			setSelected(newSelectedId);
			return;
		}
		setSelected([]);
	};

	const handleSelect = (name: string) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected: string[] = [];

		let params = [];
		switch (selectedIndex) {
			case -1:
				params = [selected, name];
				break;
			case 0:
				params = [selected.slice(1)];
				break;
			case selected.length - 1:
				params = [selected.slice(0, -1)];
				break;
			default:
				params = [
					selected.slice(0, selectedIndex),
					selected.slice(selectedIndex + 1),
				];
				break;
		}
		newSelected = newSelected.concat(...params);
		setSelected(newSelected);
	};

	const isSelected = (name: string) => selected.indexOf(name) !== -1;

	const clearSelected = () => setSelected([]);

	return { selected, isSelected, handleSelect, handleSelectAll, clearSelected };
};

export default useSelectRow;
