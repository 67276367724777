export const PATH_IDS = {
	PROPERTY_ADD: 'property-add',
};

export const PATHS = {
	DASHBOARD: '/dashboard',
	NOT_FOUND: '/404',
	FORMS: '/forms',
	FORM: '/form/:id',
	FORM_ADD: '/form-add',
	PROPERTIES: '/properties',
	PROPERTY_EDIT: '/property/:id/:tab?',
	PROPERTY_ADD: '/property-add',
	PROPERTY_WIZARD: '/property-wizard/:id/step/:step?',
	OFFERS: '/offers',
	OFFER: '/offer/:id/:tab?',
	PROPERTY_OFFER: '/property/:propertyId/offer/:offerId/:tab?',
	PROPERTY_FORM: '/property/:propertyId/form/:formId',
	USERS_LIST: '/users',
	USERS_EDIT: '/users/:id',
	USER_ADD: '/users-add',
	USER_PROFILE: '/user/',
	FILL_FORM_DATA: '/item/:itemId/form/:formId'
};

export const AUTH_PATHS = {
	LOGIN: '/login',
	COMPLETE_PASSWORD: '/completePassword',
};

export const MAINTENANCE_PATHS = {
	FORBIDDEN: '/403',
};
