import React from 'react';
import { useSelector } from 'react-redux';
import {
	Collapse,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { IconChevronDown, IconChevronUp } from '@tabler/icons';
import _get from 'lodash.get';
import { DefaultRootStateProps } from '../../../../../types';
import { NavGroupProps } from '../NavGroup';
import { usePermission } from '../../../../../hooks';
import { minLevel } from '../../config';
import Menu from './Menu';
import { useStyles } from './styles';

export interface NavCollapseProps {
	menu: NavGroupProps['item'];
	level: number;
}

const NavCollapse = ({ menu, level }: NavCollapseProps) => {
	const { userCan } = usePermission();
	const classes = useStyles();
	const customization = useSelector(
		(state: DefaultRootStateProps) => state.customization
	);
	const action = _get(menu, 'action', '');
	const objectTarget = _get(menu, 'objectTarget', '');
	const isByPassCheckPermission = _get(menu, 'isByPassCheckPermission', false);

	const isDenied = !userCan(objectTarget)(action)(isByPassCheckPermission);

	const [open, setOpen] = React.useState(false);
	const [selected, setSelected] = React.useState<string | null | undefined>(
		null
	);

	const handleClick = () => {
		setOpen(!open);
		setSelected(!selected ? menu.id : null);
	};

	// menu collapse & item
	const menus = menu.children?.map(item => (
		<Menu key={item.id} item={item} level={level} />
	));

	const Icon = menu.icon!;
	const menuIcon = menu.icon ? (
		<Icon
			strokeWidth={1.5}
			style={{ fontSize: '1.3rem' }}
			className={classes.listCustomIcon}
		/>
	) : (
		<FiberManualRecordIcon
			className={
				selected === menu.id
					? classes.listCustomIconSubActive
					: classes.listCustomIconSub
			}
			fontSize={level > 0 ? 'inherit' : 'medium'}
		/>
	);

	const menuIconClass = !menu.icon ? classes.listIcon : classes.menuIcon;

	if (isDenied) return <></>;

	const IconChevron = open ? IconChevronUp : IconChevronDown;

	return (
		<>
			<ListItem
				className={level > minLevel ? classes.listItemNoBack : classes.listItem}
				sx={{ borderRadius: `${customization.borderRadius}px` }}
				selected={selected === menu.id}
				button
				onClick={handleClick}
				style={{ paddingLeft: `${level * 23}px` }}
			>
				<ListItemIcon className={menuIconClass}>{menuIcon}</ListItemIcon>
				<ListItemText
					primary={
						<Typography
							variant={selected === menu.id ? 'h5' : 'body1'}
							color='inherit'
							className={classes.listItemTypography}
						>
							{menu.title}
						</Typography>
					}
					secondary={
						menu.caption && (
							<Typography
								variant='caption'
								className={classes.subMenuCaption}
								display='block'
								gutterBottom
							>
								{menu.caption}
							</Typography>
						)
					}
				/>
				<IconChevron
					stroke={1.5}
					size='1rem'
					className={
						level > minLevel ? classes.collapseIconSub : classes.collapseIcon
					}
				/>
			</ListItem>
			<Collapse in={open} timeout='auto' unmountOnExit>
				<List component='div' disablePadding className={classes.collapseWrapper}>
					{menus}
				</List>
			</Collapse>
		</>
	);
};

export default NavCollapse;
