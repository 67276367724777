import _get from 'lodash.get';
import _isEmpty from 'lodash.isempty';
import { formatCurrency, getOfferPriceValue } from '../../../helpers';
import {
	TOffer,
	HeadCell,
	TListingAgent,
	TOfferHomeSale,
	TCounter,
} from '../../../types';
import config from '../../../config';
import { ON_THE_MARKET_STATUS } from './config';
import { STATUS as PROPERTY_STATUS } from '../property/config';
import { TAction } from './ActionButton';

export const CONTINGENCY_STATUS = {
	done: 'Done',
	inProcess: 'In Process',
	overdue: 'OVERDUE',
};

const FUNDING_PURCHASE_TYPES = {
	cash: 'Cash',
	financing: 'Financing',
};

export const CONTINGENCY_TYPES = {
	saleOfHomeContingency: 'SaleOfHomeContingency',
	financeContingency: 'FinanceContingency',
	inspectionContingency: 'InspectionContingency',
	appraisalContingency: 'AppraisalContingency',
};

export const getContingencyStatus = (offer: TOffer, name: string) => {
	const contigencyValue = _get(offer, ['Contingencies', `${name}`]);
	const isRemoved = _get(offer, ['Contingencies', `${name}Removed`]);
	let status = contigencyValue === 'No' ? '' : CONTINGENCY_STATUS.inProcess;
	if (isRemoved === 'Yes') {
		status = CONTINGENCY_STATUS.done;
	}

	return {
		status,
	};
};

export const headCells: HeadCell<TOffer>[] = [
	{
		id: 'OfferNo',
		numeric: false,
		disablePadding: false,
		label: 'Offer No',
	},
	{
		id: 'BuyerInfo.FirstName',
		numeric: false,
		disablePadding: false,
		label: 'Buyer \nName',
	},
	{
		id: 'AgentInfo.FirstName',
		numeric: false,
		disablePadding: false,
		label: 'Buyer \nAgent \nName',
	},
	{
		id: 'OfferPrice',
		numeric: false,
		disablePadding: false,
		label: 'Offer Price',
		pathFn: offer => getOfferPriceValue(offer),
	},
	{
		id: 'Funding.PurchaseType',
		numeric: false,
		disablePadding: false,
		label: 'Funding \nType',
	},
	{
		id: 'Funding.LoanType',
		numeric: false,
		disablePadding: false,
		label: 'Loan \nType',
	},
	{
		id: 'Funding.DownPayment',
		numeric: false,
		disablePadding: false,
		label: 'Down \nPayment',
	},
	{
		id: 'FinanceContingency',
		numeric: false,
		disablePadding: false,
		label: 'Financing',
	},
	{
		id: 'InspectionContingency',
		numeric: false,
		disablePadding: false,
		label: 'Inspection',
	},
	{
		id: 'AppraisalContingency',
		numeric: false,
		disablePadding: false,
		label: 'Appraisal',
	},
	{
		id: 'SaleOfHomeContingency',
		numeric: false,
		disablePadding: false,
		label: 'Home Sale',
	},
	{
		id: 'ClosingPeriod',
		numeric: false,
		disablePadding: false,
		label: 'Closing \n Period',
	},
	{
		id: 'Step.messages.seller',
		numeric: false,
		disablePadding: false,
		label: 'Status',
	},
];

export function getSortVariable<T>(
	headCell: HeadCell<T> | undefined,
	order: string
) {
	if (!headCell) return {};
	return {
		SortBy: [JSON.stringify({ [headCell.id]: order })],
	};
}

export const displayContingency = (
	counter: TOffer | TCounter,
	path: string,
	purchaseTypePath: string = 'Funding.PurchaseType'
): string => {
	if (path.includes(CONTINGENCY_TYPES.saleOfHomeContingency)) {
		return _get(counter, `${path}`) || 'N/A';
	}

	if (
		path.includes(CONTINGENCY_TYPES.financeContingency) &&
		_get(counter, purchaseTypePath, '') === FUNDING_PURCHASE_TYPES.cash
	) {
		return 'N/A';
	}

	const contingency = _get(counter, `${path}`);
	if (!contingency) return 'N/A';

	const duration = _get(counter, `${path}Days`);
	return +duration ? `${duration} days` : 'No';
};

const OFFER_FUNDING_TYPE = {
	cash: 'Cash',
	financing: 'Financing',
};

export const getProofOfFunds = (offer: TOffer): string => {
	const funding = _get(offer, 'Funding') || {};
	const {
		PurchaseType: type,
		CashProofOfFundsAmount: cashPof,
		FinancingProofOfFundsAmount: financingPof,
	} = funding;
	if (!type) return '';
	const pofValue = type === OFFER_FUNDING_TYPE.cash ? cashPof : financingPof;
	return formatCurrency(pofValue || 0);
};

export const getClosingListingAgentName = (homeSale: TOfferHomeSale) =>
	_get(homeSale, ['Closing', 'ListingAgent']);

export const getOnMarketListingAgentName = (homeSale: TOfferHomeSale) =>
	_get(homeSale, ['OnMarket', 'ListingAgent']);

export const getListingAgentName = (listingAgent: TListingAgent) =>
	_get(listingAgent, 'FirstName')
		? `${_get(listingAgent, 'FirstName')} ${_get(listingAgent, 'LastName')}`
		: config.defaultText.noValue;

export const displayLoanType = (offer: TOffer): string =>
	_get(offer, 'Funding.PurchaseType', '') === FUNDING_PURCHASE_TYPES.cash
		? 'N/A'
		: _get(offer, 'Funding.LoanType', '');

export const isOnMarket = (propertyStatus: string) => {
	if (_isEmpty(propertyStatus)) return false;
	return propertyStatus.toLocaleLowerCase() === ON_THE_MARKET_STATUS;
};

export const displayDownPayment = (
	offer: TOffer,
	path: string = 'Funding.DownPayment'
) => {
	const downPayment = _get(offer, path, '');
	return downPayment ? formatCurrency(downPayment) : config.defaultText.noValue;
};

const CANCEL_ACTION = 'Cancel Offer';
export const isCancelAction = (action: TAction) =>
	_get(action, 'action', '') === CANCEL_ACTION;

export const propertyClosedStatus = {
	Id: PROPERTY_STATUS.CLOSED,
	Name: 'Property Closed',
};

export const getOfferStatusFilter = (statusIds: number[] = []) => {
	if (_isEmpty(statusIds)) return { StepIds: undefined };
	if (statusIds.includes(propertyClosedStatus.Id)) {
		const offerStatusIds = statusIds.filter(id => id !== propertyClosedStatus.Id);
		return {
			StepIds: _isEmpty(offerStatusIds) ? undefined : offerStatusIds,
			PropertyStatusIds: [propertyClosedStatus.Id],
		};
	}
	return { StepIds: statusIds };
};

export const getCancelAction = (allowActions: TAction[] = []) =>
	allowActions.find(isCancelAction);
