import { NavItemType } from '../types';
import { forms } from './forms';
import { properties } from './properties';
import { offers } from './offers';
import { dashboard } from './dashboard';
import { users } from './users';

const menuGroup = {
	id: 'menu',
	title: '',
	type: 'group',
	children: [dashboard, forms, properties, offers, users],
};

const menuItems: { items: NavItemType[] } = {
	items: [menuGroup],
};

export default menuItems;
