import { Redirect, useLocation } from 'react-router-dom';
// project imports
import { useAuth } from '../../hooks';
import config from '../../config';
import { GuardProps } from '../../types';
import { AUTH_PATHS } from '../../routes/path';

// -----------------------|| GUEST GUARD ||-----------------------//

/**
 * Guest guard for routes having no auth required
 */

const GuestGuard = ({ children }: GuardProps) => {
	const { isLoggedIn, isRequiredNewPassword } = useAuth();
	const location = useLocation();

	if (isRequiredNewPassword && location.pathname !== AUTH_PATHS.COMPLETE_PASSWORD) {
		return <Redirect to={AUTH_PATHS.COMPLETE_PASSWORD} />;
	}

	if (isLoggedIn) {
		return <Redirect to={config.defaultPath} />;
	}

	return children;
};

export default GuestGuard;
