import ReactDOM from 'react-dom';

// third party
import { BrowserRouter } from 'react-router-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';

// project imports
import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import * as serviceWorker from './serviceWorker';
import App from './App';
import config from './config';
import reducer from './store/reducer';

// style + assets
import './assets/scss/style.scss';

import { client } from './dataProvider';

Sentry.init({ dsn: config.sentry.dsn, environment: config.sentry.env });

export const store = createStore(reducer);

ReactDOM.render(
	<ApolloProvider client={client}>
		<Provider store={store}>
			<BrowserRouter basename={config.basename}>
				<App />
			</BrowserRouter>
		</Provider>
	</ApolloProvider>,
	// eslint-disable-next-line no-undef
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
