// material-ui
import { makeStyles } from '@material-ui/core/styles';
import LogoIcon from "../assets/images/logo.svg";
import LogoIconS from "../assets/images/logo_s.svg";

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from './../../assets/images/logo-dark.svg';
 * import logo from './../../assets/images/logo.svg';
 *
 */

// -----------------------|| LOGO SVG ||-----------------------//

const useStyles = makeStyles({
	logo: {
		width: '85px',
	},
	subTitleAlert: {
		color: 'red',
		fontWeight: 500,
		fontFamily: 'Poppins,sans-serif',
		fontSize: '18px',
		lineHeight: '22px',
		letterSpacing: '5px'
	}
});

const Logo = () => {
	const classes = useStyles();

	return process.env.REACT_APP_ENV_NAME 
		? 
		<>
			<div style={{textAlign: 'center'}}>
				<img src={LogoIconS} className={classes.logo} alt='logo' />
				<div className={classes.subTitleAlert}>{process.env.REACT_APP_ENV_NAME}</div>
			</div>
		</>
		: <img src={LogoIcon} className={classes.logo} alt='logo' />;
};

export default Logo;
