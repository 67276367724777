export const updatePropertyStep = /* GraphQL */ `
	mutation UpdatePropertyData($PropertyId: ID!, $NewPropertyWizardStep: String) {
		updatePropertyData(
			PropertyId: $PropertyId
			NewPropertyWizardStep: $NewPropertyWizardStep
		) {
			PropertyId
			NewPropertyWizardStep
		}
	}
`;

export const updateSellerInformation = /* GraphQL */ `
	mutation UpdatePropertyData($PropertyId: ID!, $SellerInfo: SellerInfoInput) {
		updatePropertyData(PropertyId: $PropertyId, SellerInfo: $SellerInfo) {
			PropertyId
		}
	}
`;

export const updatePropertyData = ({
	hasYearBuilt = true,
	hasVestingType = true,
}: {
	hasYearBuilt?: boolean;
	hasVestingType?: boolean;
}): string => /* GraphQL */ `
  mutation UpdatePropertyData(
    $AdditionalInfo: String
    $BuyerAgentFeePct: Float
    $ListPriceRange: ListPriceRangeInput
    $ListingAgentFeePct: Float
    $ListingTerminationDate: AWSDate
    $OfferDueDate: AWSDate
    $ListingTermsConditions: String
    $PropertyId: ID!
    ${hasYearBuilt ? `$YearBuilt: YearBuiltInput` : ''}
    ${hasVestingType ? `$VestingType: VestingTypeInput` : ''}
    $CalendarUrl: AWSURL
  ) {
    updatePropertyData(
      AdditionalInfo: $AdditionalInfo
      BuyerAgentFeePct: $BuyerAgentFeePct
      ListPriceRange: $ListPriceRange
      ListingAgentFeePct: $ListingAgentFeePct
      ListingTerminationDate: $ListingTerminationDate
      OfferDueDate: $OfferDueDate
      ListingTermsConditions: $ListingTermsConditions
      PropertyId: $PropertyId
      ${hasYearBuilt ? `YearBuilt: $YearBuilt` : ''}
      ${hasVestingType ? `VestingType: $VestingType` : ''}
      CalendarUrl: $CalendarUrl
    ) {
      AdditionalInfo
      BuyerAgentFeePct
      ListingTerminationDate
      OfferDueDate
      ListingTermsConditions
      CalendarUrl
      HomeViewingCalendarProfileId
      CreatedAt
      CreatedBy
      DTPropertyId
      ListPriceRange {
        Max
        MaxFormatted
        Min
        MinFormatted
      }
      ListingAgentFeePct
      PropertyData {
        Bedrooms
        City
        County
        FullAddress
        FullBath
        LandUse
        LivingArea
        LotArea
        OwnerNames
        State
        StreetAddress
        VestingOwnershipRight
        VestingTypeMapped
        VestingTypeOverride
        VestingTypeUsed
        YearBuilt
        YearBuiltDisp
        YearBuiltOverride
        YearBuiltUsed
        Zip
      }
      PropertyId
      PropertyNo
      PropertyReport {
        ObjectKey
        PresignedUrl
        UpdatedAt
      }
      ReportStatus {
        TaxReportTaxResourceMessage
        TaxReportTaxResourceStatus
        TaxReportTaxResourceUpdatedAt
        TaxReportTotalViewMessage
        TaxReportTotalViewStatus
        TaxReportTotalViewUpdatedAt
      }
      SellerInfo {
        Email
        FirstName
        LastName
        Phone
      }
      StatusId
      Thumbnail {
        file {
          path
        }
        id
        key
        name
        presignedUrl
        size
        type
      }
      UpdatedAt
      UpdatedBy
    }
  }
`;

export const updateSellerCounterOfferFormData = /* GraphQL */ `
	mutation Formdata_SubmitSellerCounterOffer(
		$CounterOfferId: String!
		$FormData: AWSJSON!
		$FormId: String!
	) {
		formdata_SubmitSellerCounterOffer(
			CounterOfferId: $CounterOfferId
			FormData: $FormData
			FormId: $FormId
		)
	}
`;

export const updatePropertyStatus = /* GraphQL */ `
	mutation UpdatePropertyStatus($PropertyId: ID!, $StatusId: Int!) {
		updatePropertyStatus(PropertyId: $PropertyId, StatusId: $StatusId) {
			StatusId
		}
	}
`;

export const createSeller = /* GraphQL */ `
	mutation CreateProperty($SellerInfo: SellerInfoInput) {
		createProperty(SellerInfo: $SellerInfo) {
			PropertyId
			SellerInfo {
				Email
				FirstName
				LastName
				Phone
			}
		}
	}
`;

export const updateDoc = /* GraphQL */ `
	mutation UpdateDoc(
		$PropertyId: String!
		$ObjectKey: String!
		$DocStatus: String!
		$DocType: String!
	) {
		docs_UpdateDocStatus(
			PropertyId: $PropertyId
			ObjectKey: $ObjectKey
			DocStatus: $DocStatus
		) {
			ObjectKey
		}
		docs_UpdateDocType(
			PropertyId: $PropertyId
			ObjectKey: $ObjectKey
			DocType: $DocType
		) {
			ObjectKey
		}
	}
`;

export const updateDocPermissions = /* GraphQL */ `
	mutation UpdateDocPermissions(
		$PropertyId: String!
		$ObjectKey: String!
		$DocPermissions: [String!]!
	) {
		docs_UpdateDocPermission(
			PropertyId: $PropertyId
			ObjectKey: $ObjectKey
			DocPermissions: $DocPermissions
		) {
			DocPermissions
			DocScope
			DocStatus
			DocType
			EventTime
			File {
				name
				type
				size
			}
			FileId
			ItemType
			ObjectKey
			OfferId
			OfferNo
			PropertyId
		}
	}
`;

export const createNewUser = /* GraphQL */ `
	mutation Users_CreateUser(
		$BrokerageDRE: String
		$BrokerageName: String
		$DRENumber: String
		$Email: AWSEmail!
		$FirstName: String
		$LastName: String
		$LicenseType: String
		$MiddleName: String
		$Phone: AWSPhone
		$UserGroup: String
	) {
		users_CreateUser(
			BrokerageDRE: $BrokerageDRE
			BrokerageName: $BrokerageName
			DRENumber: $DRENumber
			Email: $Email
			FirstName: $FirstName
			LastName: $LastName
			LicenseType: $LicenseType
			MiddleName: $MiddleName
			Phone: $Phone
			UserGroup: $UserGroup
		) {
			BrokerageDRE
			BrokerageName
			CreatedAt
			CreatedBy
			DRENumber
			Email
			Enabled
			FirstName
			LastName
			LicenseType
			MiddleName
			Phone
			UpdatedAt
			UpdatedBy
			UserGroups {
				Description
				GroupName
			}
			UserId
			UserStatus
		}
	}
`;
export const updateUserProfile = /* GraphQL */ `
	mutation Users_UpdateUserProfile(
		$BrokerageDRE: String
		$BrokerageName: String
		$DRENumber: String
		$FirstName: String
		$LastName: String
		$LicenseType: String
		$MiddleName: String
		$Phone: AWSPhone
		$UserId: String
	) {
		users_UpdateUserProfile(
			BrokerageDRE: $BrokerageDRE
			BrokerageName: $BrokerageName
			DRENumber: $DRENumber
			FirstName: $FirstName
			LastName: $LastName
			LicenseType: $LicenseType
			MiddleName: $MiddleName
			Phone: $Phone
			UserId: $UserId
		) {
			BrokerageDRE
			BrokerageName
			CreatedAt
			CreatedBy
			DRENumber
			Email
			Enabled
			FirstName
			LastName
			LicenseType
			MiddleName
			Phone
			UpdatedAt
			UpdatedBy
			UserGroups {
				Description
				GroupName
			}
			UserId
			UserStatus
		}
	}
`;
export const updateMyProfile = /* GraphQL */ `
	mutation Users_UpdateMyUserProfile(
		$BrokerageDRE: String
		$BrokerageName: String
		$FirstName: String
		$MiddleName: String
		$LastName: String
		$Phone: AWSPhone
		$DRENumber: String
		$LicenseType: String
	) {
		users_UpdateMyUserProfile(
			BrokerageDRE: $BrokerageDRE
			BrokerageName: $BrokerageName
			FirstName: $FirstName
			MiddleName: $MiddleName
			LastName: $LastName
			Phone: $Phone
			LicenseType: $LicenseType
			DRENumber: $DRENumber
		) {
			BrokerageDRE
			BrokerageName
			Email
			Enabled
			FirstName
			LastName
			Phone
			UserGroups {
				Description
				GroupName
			}
			UserId
			UserStatus
		}
	}
`;

export const approvePropertyForm = /* GraphQL */ `
	mutation approvePropertyForm($FormId: String!, $PropertyId: String!) {
		formdata_ApprovePropertyForm(FormId: $FormId, PropertyId: $PropertyId)
	}
`;

export const askReworkPropertyForm = /* GraphQL */ `
	mutation askReworkPropertyForm($FormId: String!, $PropertyId: String!) {
		formdata_AskReworkPropertyForm(FormId: $FormId, PropertyId: $PropertyId)
	}
`;

export const saveFormData = /* GraphQL */ `
	mutation saveFormData(
		$FormData: AWSJSON!
		$FormId: String!
		$ItemId: String!
	) {
		formdata_SaveFormData(FormData: $FormData, FormId: $FormId, ItemId: $ItemId) {
			urls {
				id
				url
			}
		}
	}
`;

export const submitFormData = /* GraphQL */ `
	mutation submitFormData(
		$FormData: AWSJSON!
		$FormId: String!
		$ItemId: String!
	) {
		formdata_SubmitFormData(
			FormData: $FormData
			FormId: $FormId
			ItemId: $ItemId
		) {
			urls {
				id
				url
			}
		}
	}
`;

export const updatePropertyJurisdictionalCostPayors = /* GraphQL */ `
	mutation UpdatePropertyJurisdictionalCostPayors(
		$HOADetectedOverride: Boolean!
		$PaymentOverride: [PropertyJurisdictionalCostPaymentInput]!
		$PropertyId: ID!
	) {
		updatePropertyJurisdictionalCostPayors(
			HOADetectedOverride: $HOADetectedOverride
			PaymentOverride: $PaymentOverride
			PropertyId: $PropertyId
		) {
			HOADetected
			HOADetectedOverride
			Items {
				CanOverridePayAmount
				CanOverridePayor
				Groups
				ItemKey
				ItemName
				Order
				PayAmount
				PayAmountOverride {
					Amount
					AmountType
				}
				Payor
				PayorId
				PayorIdOverride
				PayorOverride
			}
			PayorOptions {
				Payor
				PayorId
			}
			ReadOnly
		}
	}
`;

export const updatePAUploadedDate = /* GraphQL */ `
	mutation UpdatePropertyData($PAUploadedDate: AWSDate, $PropertyId: ID!) {
		updatePropertyData(PAUploadedDate: $PAUploadedDate, PropertyId: $PropertyId) {
			PropertyId
			PAUploadedDate
		}
	}
`;

export const updatePropertyEscrowNumber = /* GraphQL */ `
	mutation UpdatePropertyData($PropertyId: ID!, $EscrowNumber: String) {
		updatePropertyData(PropertyId: $PropertyId, EscrowNumber: $EscrowNumber) {
			PropertyId
			EscrowNumber
		}
	}
`;

export const updateStep2Fields = /* GraphQL */ `
	mutation UpdatePropertyData(
		$PropertyId: ID!
		$OfferDueDate: AWSDate
		$AdditionalInfo: String
		$CalendarUrl: AWSURL
	) {
		updatePropertyData(
			PropertyId: $PropertyId
			OfferDueDate: $OfferDueDate
			AdditionalInfo: $AdditionalInfo
			CalendarUrl: $CalendarUrl
		) {
			PropertyId
			OfferDueDate
			AdditionalInfo
			CalendarUrl
		}
	}
`;
