/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const approveProperty = /* GraphQL */ `
	mutation ApproveProperty($PropertyId: ID!) {
		approveProperty(PropertyId: $PropertyId) {
			AdditionalInfo
			BuyerAgentFeePct
			CalendarUrl
			CreatedAt
			CreatedBy
			DTPropertyId
			Docs {
				DocPermissions
				DocScope
				DocStatus
				DocType
				EventTime
				File {
					name
					size
					type
				}
				FileId
				ItemType
				ObjectKey
				OfferId
				OfferNo
				PropertyId
				QuestionDefinition {
					name
					title
					type
				}
				QuestionId
			}
			ExternalId
			HomeViewingCalendarProfileId
			ListPriceRange {
				Max
				MaxFormatted
				Min
				MinFormatted
			}
			ListingAgentFeePct
			ListingDetails {
				ListPrice
			}
			ListingTerminationDate
			ListingTermsConditions
			NewPropertyWizardStep
			NileAgentId
			OfferDueDate
			Offers {
				AdditionalBuyerInfo
				AdditionalBuyers
				AgentInfo {
					DRENumber
					Email
					FirstName
					LastName
					Name
					Phone
				}
				BuyerInfo {
					FirstName
					LastName
					MiddleName
					TitleName
				}
				BuyerType
				CounterOffer {
					BuyerCounter {
						AppraisalContingency
						AppraisalContingencyDays
						ClosingPeriod
						FinanceContingency
						FinanceContingencyDays
						Furniture
						FurnitureOther
						InspectionContingency
						InspectionContingencyDays
						IsBestOffer
						OfferPrice
						Position
						RentBack
						RentBackDailyAmount
						RentBackDays
						RentBackInititated
						SaleOfHomeContingency
						Status
						SubmittedAt
					}
					CounterOfferId
					Forms {
						DataStatus
						FormGroup
						FormId
						FormTypeId
						Name
						Order
					}
					Position
					SellerCounter {
						AppraisalContingency
						AppraisalContingencyDays
						ClosingPeriod
						FinanceContingency
						FinanceContingencyDays
						Furniture
						FurnitureOther
						InspectionContingency
						InspectionContingencyDays
						IsBestOffer
						OfferPrice
						Position
						RentBack
						RentBackDailyAmount
						RentBackDays
						RentBackInititated
						SaleOfHomeContingency
						Status
						SubmittedAt
					}
				}
				CounterOfferHistory {
					BuyerCounter {
						AppraisalContingency
						AppraisalContingencyDays
						ClosingPeriod
						FinanceContingency
						FinanceContingencyDays
						Furniture
						FurnitureOther
						InspectionContingency
						InspectionContingencyDays
						IsBestOffer
						OfferPrice
						Position
						RentBack
						RentBackDailyAmount
						RentBackDays
						RentBackInititated
						SaleOfHomeContingency
						Status
						SubmittedAt
					}
					Position
					SellerCounter {
						AppraisalContingency
						AppraisalContingencyDays
						ClosingPeriod
						FinanceContingency
						FinanceContingencyDays
						Furniture
						FurnitureOther
						InspectionContingency
						InspectionContingencyDays
						IsBestOffer
						OfferPrice
						Position
						RentBack
						RentBackDailyAmount
						RentBackDays
						RentBackInititated
						SaleOfHomeContingency
						Status
						SubmittedAt
					}
				}
				CreatedAt
				CreatedBy
				Entity {
					EntityType
					IndividualOnBehalf
					Name
					TitleName
				}
				FirmInfo {
					CompanyName
					CoordinatorEmail
					CoordinatorFirstName
					CoordinatorLastName
					CoordinatorPhone
					DRENumber
				}
				Funding {
					CashProofOfFundsAmount
					DownPayment
					FicoScore
					FinancingProofOfFundsAmount
					LoanType
					PreapprovedForLoan
					PurchaseType
				}
				HomeSale {
					Closing {
						EscrowCompany
						ExpectedClosingPeriod
						HasBuyerReleasedContingencies
						ListingAgent {
							Email
							FirstName
							LastName
							Phone
						}
					}
					OnMarket {
						BrokerageFirm
						HasOffers
						ListingAgent {
							Email
							FirstName
							LastName
						}
						OnMarketDate
					}
					Property {
						City
						State
						Status
						Street
						Zip
					}
				}
				NileAgentId
				Offer {
					AppraisalContingency
					AppraisalContingencyDays
					ClosingPeriod
					FinanceContingency
					FinanceContingencyDays
					Furniture
					FurnitureOther
					InspectionContingency
					InspectionContingencyDays
					OfferPrice
					SaleOfHomeContingency
					SubmittedAt
				}
				OfferId
				OfferNo
				PropertyData {
					Bedrooms
					City
					County
					FullAddress
					FullBath
					LandUse
					LivingArea
					LotArea
					OwnerNames
					State
					StreetAddress
					VestingType
					YearBuilt
					Zip
				}
				PropertyId
				PropertyNo
				SellerAccepted
				Step {
					allowed_actions {
						action
						form_action
						next_step_id
					}
					allowed_upload_file_types {
						allow_files_count
						allow_single_file_max_size_mb
						file_type
					}
					buyer_can_submit_offer
					display_on_advisor_portal
					display_on_seller_dashboard
					id
					messages {
						advisor
						buyer
						seller
					}
					name
					waiting_seller_to_act
				}
				StepId
				UpdatedAt
				UpdatedBy
			}
			PropertyData {
				Bedrooms
				City
				County
				FullAddress
				FullBath
				LandUse
				LivingArea
				LotArea
				OwnerNames
				State
				StreetAddress
				VestingOwnershipRight
				VestingTypeMapped
				VestingTypeOverride
				VestingTypeUsed
				YearBuilt
				YearBuiltDisp
				YearBuiltOverride
				YearBuiltUsed
				Zip
			}
			PropertyFormDataStatuses {
				CategoryId
				FormDataApproved
				FormDataApprovedAt
				FormDataCreatedAt
				FormDataStatus
				FormDataSubmitted
				FormDataUpdatedAt
				FormId
				FormTypeId
				Title
			}
			PropertyFormsSubmitted
			PropertyId
			PropertyNo
			PropertyReport {
				ObjectKey
				PresignedUrl
				UpdatedAt
			}
			ReportStatus {
				TaxReportTaxResourceMessage
				TaxReportTaxResourceStatus
				TaxReportTaxResourceUpdatedAt
				TaxReportTotalViewMessage
				TaxReportTotalViewStatus
				TaxReportTotalViewUpdatedAt
			}
			SellerInfo {
				Email
				FirstName
				LastName
				Phone
			}
			StatusId
			Thumbnail {
				file {
					path
				}
				id
				key
				name
				presignedUrl
				size
				type
			}
			UpdatedAt
			UpdatedBy
		}
	}
`;
export const createForm = /* GraphQL */ `
	mutation CreateForm($Form: ModelFormInput!) {
		createForm(Form: $Form) {
			CategoryId
			CreatedAt
			CreatedBy
			FormId
			FormStatus
			FormTypeId
			Template
			Title
			UpdatedAt
			UpdatedBy
		}
	}
`;
export const createProperty = /* GraphQL */ `
	mutation CreateProperty($SellerInfo: SellerInfoInput) {
		createProperty(SellerInfo: $SellerInfo) {
			AdditionalInfo
			BuyerAgentFeePct
			CalendarUrl
			CreatedAt
			CreatedBy
			DTPropertyId
			Docs {
				DocPermissions
				DocScope
				DocStatus
				DocType
				EventTime
				File {
					name
					size
					type
				}
				FileId
				ItemType
				ObjectKey
				OfferId
				OfferNo
				PropertyId
				QuestionDefinition {
					name
					title
					type
				}
				QuestionId
			}
			ExternalId
			HomeViewingCalendarProfileId
			ListPriceRange {
				Max
				MaxFormatted
				Min
				MinFormatted
			}
			ListingAgentFeePct
			ListingDetails {
				ListPrice
			}
			ListingTerminationDate
			ListingTermsConditions
			NewPropertyWizardStep
			NileAgentId
			OfferDueDate
			Offers {
				AdditionalBuyerInfo
				AdditionalBuyers
				AgentInfo {
					DRENumber
					Email
					FirstName
					LastName
					Name
					Phone
				}
				BuyerInfo {
					FirstName
					LastName
					MiddleName
					TitleName
				}
				BuyerType
				CounterOffer {
					BuyerCounter {
						AppraisalContingency
						AppraisalContingencyDays
						ClosingPeriod
						FinanceContingency
						FinanceContingencyDays
						Furniture
						FurnitureOther
						InspectionContingency
						InspectionContingencyDays
						IsBestOffer
						OfferPrice
						Position
						RentBack
						RentBackDailyAmount
						RentBackDays
						RentBackInititated
						SaleOfHomeContingency
						Status
						SubmittedAt
					}
					CounterOfferId
					Forms {
						DataStatus
						FormGroup
						FormId
						FormTypeId
						Name
						Order
					}
					Position
					SellerCounter {
						AppraisalContingency
						AppraisalContingencyDays
						ClosingPeriod
						FinanceContingency
						FinanceContingencyDays
						Furniture
						FurnitureOther
						InspectionContingency
						InspectionContingencyDays
						IsBestOffer
						OfferPrice
						Position
						RentBack
						RentBackDailyAmount
						RentBackDays
						RentBackInititated
						SaleOfHomeContingency
						Status
						SubmittedAt
					}
				}
				CounterOfferHistory {
					BuyerCounter {
						AppraisalContingency
						AppraisalContingencyDays
						ClosingPeriod
						FinanceContingency
						FinanceContingencyDays
						Furniture
						FurnitureOther
						InspectionContingency
						InspectionContingencyDays
						IsBestOffer
						OfferPrice
						Position
						RentBack
						RentBackDailyAmount
						RentBackDays
						RentBackInititated
						SaleOfHomeContingency
						Status
						SubmittedAt
					}
					Position
					SellerCounter {
						AppraisalContingency
						AppraisalContingencyDays
						ClosingPeriod
						FinanceContingency
						FinanceContingencyDays
						Furniture
						FurnitureOther
						InspectionContingency
						InspectionContingencyDays
						IsBestOffer
						OfferPrice
						Position
						RentBack
						RentBackDailyAmount
						RentBackDays
						RentBackInititated
						SaleOfHomeContingency
						Status
						SubmittedAt
					}
				}
				CreatedAt
				CreatedBy
				Entity {
					EntityType
					IndividualOnBehalf
					Name
					TitleName
				}
				FirmInfo {
					CompanyName
					CoordinatorEmail
					CoordinatorFirstName
					CoordinatorLastName
					CoordinatorPhone
					DRENumber
				}
				Funding {
					CashProofOfFundsAmount
					DownPayment
					FicoScore
					FinancingProofOfFundsAmount
					LoanType
					PreapprovedForLoan
					PurchaseType
				}
				HomeSale {
					Closing {
						EscrowCompany
						ExpectedClosingPeriod
						HasBuyerReleasedContingencies
						ListingAgent {
							Email
							FirstName
							LastName
							Phone
						}
					}
					OnMarket {
						BrokerageFirm
						HasOffers
						ListingAgent {
							Email
							FirstName
							LastName
						}
						OnMarketDate
					}
					Property {
						City
						State
						Status
						Street
						Zip
					}
				}
				NileAgentId
				Offer {
					AppraisalContingency
					AppraisalContingencyDays
					ClosingPeriod
					FinanceContingency
					FinanceContingencyDays
					Furniture
					FurnitureOther
					InspectionContingency
					InspectionContingencyDays
					OfferPrice
					SaleOfHomeContingency
					SubmittedAt
				}
				OfferId
				OfferNo
				PropertyData {
					Bedrooms
					City
					County
					FullAddress
					FullBath
					LandUse
					LivingArea
					LotArea
					OwnerNames
					State
					StreetAddress
					VestingType
					YearBuilt
					Zip
				}
				PropertyId
				PropertyNo
				SellerAccepted
				Step {
					allowed_actions {
						action
						form_action
						next_step_id
					}
					allowed_upload_file_types {
						allow_files_count
						allow_single_file_max_size_mb
						file_type
					}
					buyer_can_submit_offer
					display_on_advisor_portal
					display_on_seller_dashboard
					id
					messages {
						advisor
						buyer
						seller
					}
					name
					waiting_seller_to_act
				}
				StepId
				UpdatedAt
				UpdatedBy
			}
			PropertyData {
				Bedrooms
				City
				County
				FullAddress
				FullBath
				LandUse
				LivingArea
				LotArea
				OwnerNames
				State
				StreetAddress
				VestingOwnershipRight
				VestingTypeMapped
				VestingTypeOverride
				VestingTypeUsed
				YearBuilt
				YearBuiltDisp
				YearBuiltOverride
				YearBuiltUsed
				Zip
			}
			PropertyFormDataStatuses {
				CategoryId
				FormDataApproved
				FormDataApprovedAt
				FormDataCreatedAt
				FormDataStatus
				FormDataSubmitted
				FormDataUpdatedAt
				FormId
				FormTypeId
				Title
			}
			PropertyFormsSubmitted
			PropertyId
			PropertyNo
			PropertyReport {
				ObjectKey
				PresignedUrl
				UpdatedAt
			}
			ReportStatus {
				TaxReportTaxResourceMessage
				TaxReportTaxResourceStatus
				TaxReportTaxResourceUpdatedAt
				TaxReportTotalViewMessage
				TaxReportTotalViewStatus
				TaxReportTotalViewUpdatedAt
			}
			SellerInfo {
				Email
				FirstName
				LastName
				Phone
			}
			StatusId
			Thumbnail {
				file {
					path
				}
				id
				key
				name
				presignedUrl
				size
				type
			}
			UpdatedAt
			UpdatedBy
		}
	}
`;
export const deleteForm = /* GraphQL */ `
	mutation DeleteForm($FormId: ID!) {
		deleteForm(FormId: $FormId) {
			CategoryId
			CreatedAt
			CreatedBy
			FormId
			FormStatus
			FormTypeId
			Template
			Title
			UpdatedAt
			UpdatedBy
		}
	}
`;
export const deleteProperty = /* GraphQL */ `
	mutation DeleteProperty($PropertyId: ID!) {
		deleteProperty(PropertyId: $PropertyId) {
			AdditionalInfo
			BuyerAgentFeePct
			CalendarUrl
			CreatedAt
			CreatedBy
			DTPropertyId
			Docs {
				DocPermissions
				DocScope
				DocStatus
				DocType
				EventTime
				File {
					name
					size
					type
				}
				FileId
				ItemType
				ObjectKey
				OfferId
				OfferNo
				PropertyId
				QuestionDefinition {
					name
					title
					type
				}
				QuestionId
			}
			ExternalId
			HomeViewingCalendarProfileId
			ListPriceRange {
				Max
				MaxFormatted
				Min
				MinFormatted
			}
			ListingAgentFeePct
			ListingDetails {
				ListPrice
			}
			ListingTerminationDate
			ListingTermsConditions
			NewPropertyWizardStep
			NileAgentId
			OfferDueDate
			Offers {
				AdditionalBuyerInfo
				AdditionalBuyers
				AgentInfo {
					DRENumber
					Email
					FirstName
					LastName
					Name
					Phone
				}
				BuyerInfo {
					FirstName
					LastName
					MiddleName
					TitleName
				}
				BuyerType
				CounterOffer {
					BuyerCounter {
						AppraisalContingency
						AppraisalContingencyDays
						ClosingPeriod
						FinanceContingency
						FinanceContingencyDays
						Furniture
						FurnitureOther
						InspectionContingency
						InspectionContingencyDays
						IsBestOffer
						OfferPrice
						Position
						RentBack
						RentBackDailyAmount
						RentBackDays
						RentBackInititated
						SaleOfHomeContingency
						Status
						SubmittedAt
					}
					CounterOfferId
					Forms {
						DataStatus
						FormGroup
						FormId
						FormTypeId
						Name
						Order
					}
					Position
					SellerCounter {
						AppraisalContingency
						AppraisalContingencyDays
						ClosingPeriod
						FinanceContingency
						FinanceContingencyDays
						Furniture
						FurnitureOther
						InspectionContingency
						InspectionContingencyDays
						IsBestOffer
						OfferPrice
						Position
						RentBack
						RentBackDailyAmount
						RentBackDays
						RentBackInititated
						SaleOfHomeContingency
						Status
						SubmittedAt
					}
				}
				CounterOfferHistory {
					BuyerCounter {
						AppraisalContingency
						AppraisalContingencyDays
						ClosingPeriod
						FinanceContingency
						FinanceContingencyDays
						Furniture
						FurnitureOther
						InspectionContingency
						InspectionContingencyDays
						IsBestOffer
						OfferPrice
						Position
						RentBack
						RentBackDailyAmount
						RentBackDays
						RentBackInititated
						SaleOfHomeContingency
						Status
						SubmittedAt
					}
					Position
					SellerCounter {
						AppraisalContingency
						AppraisalContingencyDays
						ClosingPeriod
						FinanceContingency
						FinanceContingencyDays
						Furniture
						FurnitureOther
						InspectionContingency
						InspectionContingencyDays
						IsBestOffer
						OfferPrice
						Position
						RentBack
						RentBackDailyAmount
						RentBackDays
						RentBackInititated
						SaleOfHomeContingency
						Status
						SubmittedAt
					}
				}
				CreatedAt
				CreatedBy
				Entity {
					EntityType
					IndividualOnBehalf
					Name
					TitleName
				}
				FirmInfo {
					CompanyName
					CoordinatorEmail
					CoordinatorFirstName
					CoordinatorLastName
					CoordinatorPhone
					DRENumber
				}
				Funding {
					CashProofOfFundsAmount
					DownPayment
					FicoScore
					FinancingProofOfFundsAmount
					LoanType
					PreapprovedForLoan
					PurchaseType
				}
				HomeSale {
					Closing {
						EscrowCompany
						ExpectedClosingPeriod
						HasBuyerReleasedContingencies
						ListingAgent {
							Email
							FirstName
							LastName
							Phone
						}
					}
					OnMarket {
						BrokerageFirm
						HasOffers
						ListingAgent {
							Email
							FirstName
							LastName
						}
						OnMarketDate
					}
					Property {
						City
						State
						Status
						Street
						Zip
					}
				}
				NileAgentId
				Offer {
					AppraisalContingency
					AppraisalContingencyDays
					ClosingPeriod
					FinanceContingency
					FinanceContingencyDays
					Furniture
					FurnitureOther
					InspectionContingency
					InspectionContingencyDays
					OfferPrice
					SaleOfHomeContingency
					SubmittedAt
				}
				OfferId
				OfferNo
				PropertyData {
					Bedrooms
					City
					County
					FullAddress
					FullBath
					LandUse
					LivingArea
					LotArea
					OwnerNames
					State
					StreetAddress
					VestingType
					YearBuilt
					Zip
				}
				PropertyId
				PropertyNo
				SellerAccepted
				Step {
					allowed_actions {
						action
						form_action
						next_step_id
					}
					allowed_upload_file_types {
						allow_files_count
						allow_single_file_max_size_mb
						file_type
					}
					buyer_can_submit_offer
					display_on_advisor_portal
					display_on_seller_dashboard
					id
					messages {
						advisor
						buyer
						seller
					}
					name
					waiting_seller_to_act
				}
				StepId
				UpdatedAt
				UpdatedBy
			}
			PropertyData {
				Bedrooms
				City
				County
				FullAddress
				FullBath
				LandUse
				LivingArea
				LotArea
				OwnerNames
				State
				StreetAddress
				VestingOwnershipRight
				VestingTypeMapped
				VestingTypeOverride
				VestingTypeUsed
				YearBuilt
				YearBuiltDisp
				YearBuiltOverride
				YearBuiltUsed
				Zip
			}
			PropertyFormDataStatuses {
				CategoryId
				FormDataApproved
				FormDataApprovedAt
				FormDataCreatedAt
				FormDataStatus
				FormDataSubmitted
				FormDataUpdatedAt
				FormId
				FormTypeId
				Title
			}
			PropertyFormsSubmitted
			PropertyId
			PropertyNo
			PropertyReport {
				ObjectKey
				PresignedUrl
				UpdatedAt
			}
			ReportStatus {
				TaxReportTaxResourceMessage
				TaxReportTaxResourceStatus
				TaxReportTaxResourceUpdatedAt
				TaxReportTotalViewMessage
				TaxReportTotalViewStatus
				TaxReportTotalViewUpdatedAt
			}
			SellerInfo {
				Email
				FirstName
				LastName
				Phone
			}
			StatusId
			Thumbnail {
				file {
					path
				}
				id
				key
				name
				presignedUrl
				size
				type
			}
			UpdatedAt
			UpdatedBy
		}
	}
`;
export const docs_UpdateDocPermission = /* GraphQL */ `
	mutation Docs_UpdateDocPermission(
		$DocPermissions: [String!]!
		$ObjectKey: String!
		$PropertyId: String!
	) {
		docs_UpdateDocPermission(
			DocPermissions: $DocPermissions
			ObjectKey: $ObjectKey
			PropertyId: $PropertyId
		) {
			DocPermissions
			DocScope
			DocStatus
			DocType
			EventTime
			File {
				name
				size
				type
			}
			FileId
			ItemType
			ObjectKey
			OfferId
			OfferNo
			PropertyId
			QuestionDefinition {
				name
				title
				type
			}
			QuestionId
		}
	}
`;
export const docs_UpdateDocStatus = /* GraphQL */ `
	mutation Docs_UpdateDocStatus(
		$DocStatus: String!
		$ObjectKey: String!
		$PropertyId: String!
	) {
		docs_UpdateDocStatus(
			DocStatus: $DocStatus
			ObjectKey: $ObjectKey
			PropertyId: $PropertyId
		) {
			DocPermissions
			DocScope
			DocStatus
			DocType
			EventTime
			File {
				name
				size
				type
			}
			FileId
			ItemType
			ObjectKey
			OfferId
			OfferNo
			PropertyId
			QuestionDefinition {
				name
				title
				type
			}
			QuestionId
		}
	}
`;
export const docs_UpdateDocType = /* GraphQL */ `
	mutation Docs_UpdateDocType(
		$DocType: String!
		$ObjectKey: String!
		$PropertyId: String!
	) {
		docs_UpdateDocType(
			DocType: $DocType
			ObjectKey: $ObjectKey
			PropertyId: $PropertyId
		) {
			DocPermissions
			DocScope
			DocStatus
			DocType
			EventTime
			File {
				name
				size
				type
			}
			FileId
			ItemType
			ObjectKey
			OfferId
			OfferNo
			PropertyId
			QuestionDefinition {
				name
				title
				type
			}
			QuestionId
		}
	}
`;
export const docs_updateDocItem = /* GraphQL */ `
	mutation Docs_updateDocItem($UpdatedDocItem: UpdatedDocItemInput!) {
		docs_updateDocItem(UpdatedDocItem: $UpdatedDocItem) {
			DocPermissions
			DocScope
			DocStatus
			DocType
			EventName
			EventTime
			File {
				name
				size
				type
			}
			FileId
			ItemType
			ObjectKey
			OfferId
			OfferNo
			PropertyId
			QuestionId
		}
	}
`;
export const formdata_ApprovePropertyForm = /* GraphQL */ `
	mutation Formdata_ApprovePropertyForm($FormId: String!, $PropertyId: String!) {
		formdata_ApprovePropertyForm(FormId: $FormId, PropertyId: $PropertyId)
	}
`;
export const formdata_AskReworkPropertyForm = /* GraphQL */ `
	mutation Formdata_AskReworkPropertyForm(
		$FormId: String!
		$PropertyId: String!
	) {
		formdata_AskReworkPropertyForm(FormId: $FormId, PropertyId: $PropertyId)
	}
`;
export const formdata_SubmitSellerCounterOffer = /* GraphQL */ `
	mutation Formdata_SubmitSellerCounterOffer(
		$CounterOfferId: String!
		$FormData: AWSJSON!
		$FormId: String!
	) {
		formdata_SubmitSellerCounterOffer(
			CounterOfferId: $CounterOfferId
			FormData: $FormData
			FormId: $FormId
		)
	}
`;
export const homeviewingcalendar_CreateBooking = /* GraphQL */ `
	mutation Homeviewingcalendar_CreateBooking(
		$Booking: AWSJSON!
		$JumpToDate: String
		$PropertyId: String!
	) {
		homeviewingcalendar_CreateBooking(
			Booking: $Booking
			JumpToDate: $JumpToDate
			PropertyId: $PropertyId
		)
	}
`;
export const homeviewingcalendar_UpdateProfile = /* GraphQL */ `
	mutation Homeviewingcalendar_UpdateProfile(
		$Profile: AWSJSON!
		$PropertyId: String!
	) {
		homeviewingcalendar_UpdateProfile(Profile: $Profile, PropertyId: $PropertyId)
	}
`;
export const selectDataTreeProperty = /* GraphQL */ `
	mutation SelectDataTreeProperty($DataTreePropertyId: Int!, $PropertyId: ID!) {
		selectDataTreeProperty(
			DataTreePropertyId: $DataTreePropertyId
			PropertyId: $PropertyId
		) {
			AdditionalInfo
			BuyerAgentFeePct
			CalendarUrl
			CreatedAt
			CreatedBy
			DTPropertyId
			Docs {
				DocPermissions
				DocScope
				DocStatus
				DocType
				EventTime
				File {
					name
					size
					type
				}
				FileId
				ItemType
				ObjectKey
				OfferId
				OfferNo
				PropertyId
				QuestionDefinition {
					name
					title
					type
				}
				QuestionId
			}
			ExternalId
			HomeViewingCalendarProfileId
			ListPriceRange {
				Max
				MaxFormatted
				Min
				MinFormatted
			}
			ListingAgentFeePct
			ListingDetails {
				ListPrice
			}
			ListingTerminationDate
			ListingTermsConditions
			NewPropertyWizardStep
			NileAgentId
			OfferDueDate
			Offers {
				AdditionalBuyerInfo
				AdditionalBuyers
				AgentInfo {
					DRENumber
					Email
					FirstName
					LastName
					Name
					Phone
				}
				BuyerInfo {
					FirstName
					LastName
					MiddleName
					TitleName
				}
				BuyerType
				CounterOffer {
					BuyerCounter {
						AppraisalContingency
						AppraisalContingencyDays
						ClosingPeriod
						FinanceContingency
						FinanceContingencyDays
						Furniture
						FurnitureOther
						InspectionContingency
						InspectionContingencyDays
						IsBestOffer
						OfferPrice
						Position
						RentBack
						RentBackDailyAmount
						RentBackDays
						RentBackInititated
						SaleOfHomeContingency
						Status
						SubmittedAt
					}
					CounterOfferId
					Forms {
						DataStatus
						FormGroup
						FormId
						FormTypeId
						Name
						Order
					}
					Position
					SellerCounter {
						AppraisalContingency
						AppraisalContingencyDays
						ClosingPeriod
						FinanceContingency
						FinanceContingencyDays
						Furniture
						FurnitureOther
						InspectionContingency
						InspectionContingencyDays
						IsBestOffer
						OfferPrice
						Position
						RentBack
						RentBackDailyAmount
						RentBackDays
						RentBackInititated
						SaleOfHomeContingency
						Status
						SubmittedAt
					}
				}
				CounterOfferHistory {
					BuyerCounter {
						AppraisalContingency
						AppraisalContingencyDays
						ClosingPeriod
						FinanceContingency
						FinanceContingencyDays
						Furniture
						FurnitureOther
						InspectionContingency
						InspectionContingencyDays
						IsBestOffer
						OfferPrice
						Position
						RentBack
						RentBackDailyAmount
						RentBackDays
						RentBackInititated
						SaleOfHomeContingency
						Status
						SubmittedAt
					}
					Position
					SellerCounter {
						AppraisalContingency
						AppraisalContingencyDays
						ClosingPeriod
						FinanceContingency
						FinanceContingencyDays
						Furniture
						FurnitureOther
						InspectionContingency
						InspectionContingencyDays
						IsBestOffer
						OfferPrice
						Position
						RentBack
						RentBackDailyAmount
						RentBackDays
						RentBackInititated
						SaleOfHomeContingency
						Status
						SubmittedAt
					}
				}
				CreatedAt
				CreatedBy
				Entity {
					EntityType
					IndividualOnBehalf
					Name
					TitleName
				}
				FirmInfo {
					CompanyName
					CoordinatorEmail
					CoordinatorFirstName
					CoordinatorLastName
					CoordinatorPhone
					DRENumber
				}
				Funding {
					CashProofOfFundsAmount
					DownPayment
					FicoScore
					FinancingProofOfFundsAmount
					LoanType
					PreapprovedForLoan
					PurchaseType
				}
				HomeSale {
					Closing {
						EscrowCompany
						ExpectedClosingPeriod
						HasBuyerReleasedContingencies
						ListingAgent {
							Email
							FirstName
							LastName
							Phone
						}
					}
					OnMarket {
						BrokerageFirm
						HasOffers
						ListingAgent {
							Email
							FirstName
							LastName
						}
						OnMarketDate
					}
					Property {
						City
						State
						Status
						Street
						Zip
					}
				}
				NileAgentId
				Offer {
					AppraisalContingency
					AppraisalContingencyDays
					ClosingPeriod
					FinanceContingency
					FinanceContingencyDays
					Furniture
					FurnitureOther
					InspectionContingency
					InspectionContingencyDays
					OfferPrice
					SaleOfHomeContingency
					SubmittedAt
				}
				OfferId
				OfferNo
				PropertyData {
					Bedrooms
					City
					County
					FullAddress
					FullBath
					LandUse
					LivingArea
					LotArea
					OwnerNames
					State
					StreetAddress
					VestingType
					YearBuilt
					Zip
				}
				PropertyId
				PropertyNo
				SellerAccepted
				Step {
					allowed_actions {
						action
						form_action
						next_step_id
					}
					allowed_upload_file_types {
						allow_files_count
						allow_single_file_max_size_mb
						file_type
					}
					buyer_can_submit_offer
					display_on_advisor_portal
					display_on_seller_dashboard
					id
					messages {
						advisor
						buyer
						seller
					}
					name
					waiting_seller_to_act
				}
				StepId
				UpdatedAt
				UpdatedBy
			}
			PropertyData {
				Bedrooms
				City
				County
				FullAddress
				FullBath
				LandUse
				LivingArea
				LotArea
				OwnerNames
				State
				StreetAddress
				VestingOwnershipRight
				VestingTypeMapped
				VestingTypeOverride
				VestingTypeUsed
				YearBuilt
				YearBuiltDisp
				YearBuiltOverride
				YearBuiltUsed
				Zip
			}
			PropertyFormDataStatuses {
				CategoryId
				FormDataApproved
				FormDataApprovedAt
				FormDataCreatedAt
				FormDataStatus
				FormDataSubmitted
				FormDataUpdatedAt
				FormId
				FormTypeId
				Title
			}
			PropertyFormsSubmitted
			PropertyId
			PropertyNo
			PropertyReport {
				ObjectKey
				PresignedUrl
				UpdatedAt
			}
			ReportStatus {
				TaxReportTaxResourceMessage
				TaxReportTaxResourceStatus
				TaxReportTaxResourceUpdatedAt
				TaxReportTotalViewMessage
				TaxReportTotalViewStatus
				TaxReportTotalViewUpdatedAt
			}
			SellerInfo {
				Email
				FirstName
				LastName
				Phone
			}
			StatusId
			Thumbnail {
				file {
					path
				}
				id
				key
				name
				presignedUrl
				size
				type
			}
			UpdatedAt
			UpdatedBy
		}
	}
`;
export const updateForm = /* GraphQL */ `
	mutation UpdateForm($Form: ModelFormInput!, $FormId: ID!) {
		updateForm(Form: $Form, FormId: $FormId) {
			CategoryId
			CreatedAt
			CreatedBy
			FormId
			FormStatus
			FormTypeId
			Template
			Title
			UpdatedAt
			UpdatedBy
		}
	}
`;
export const updateOfferStep = /* GraphQL */ `
	mutation UpdateOfferStep($NewStepId: Int!, $OfferId: ID!) {
		updateOfferStep(NewStepId: $NewStepId, OfferId: $OfferId) {
			AdditionalBuyerInfo
			AdditionalBuyers
			AgentInfo {
				DRENumber
				Email
				FirstName
				LastName
				Name
				Phone
			}
			BuyerInfo {
				FirstName
				LastName
				MiddleName
				TitleName
			}
			BuyerType
			Contingencies {
				AllRemoved
				AnyAndAll
				AppraisalContingency
				AppraisalContingencyDays
				AppraisalContingencyRemoved
				AppraisalContingencyRemovedAt
				FinanceContingency
				FinanceContingencyDays
				FinanceContingencyRemoved
				FinanceContingencyRemovedAt
				InspectionContingency
				InspectionContingencyDays
				InspectionContingencyRemoved
				InspectionContingencyRemovedAt
				SaleOfHomeContingency
				SaleOfHomeContingencyRemoved
				SaleOfHomeContingencyRemovedAt
			}
			CounterOffer {
				BuyerCounter {
					AppraisalContingency
					AppraisalContingencyDays
					ClosingPeriod
					FinanceContingency
					FinanceContingencyDays
					Furniture
					FurnitureOther
					InspectionContingency
					InspectionContingencyDays
					IsBestOffer
					OfferPrice
					Position
					RentBack
					RentBackDailyAmount
					RentBackDays
					RentBackInititated
					SaleOfHomeContingency
					Status
					SubmittedAt
				}
				CounterOfferId
				Forms {
					DataStatus
					FormGroup
					FormId
					FormTypeId
					Name
					Order
				}
				Position
				SellerCounter {
					AppraisalContingency
					AppraisalContingencyDays
					ClosingPeriod
					FinanceContingency
					FinanceContingencyDays
					Furniture
					FurnitureOther
					InspectionContingency
					InspectionContingencyDays
					IsBestOffer
					OfferPrice
					Position
					RentBack
					RentBackDailyAmount
					RentBackDays
					RentBackInititated
					SaleOfHomeContingency
					Status
					SubmittedAt
				}
			}
			CounterOfferHistory {
				BuyerCounter {
					AppraisalContingency
					AppraisalContingencyDays
					ClosingPeriod
					FinanceContingency
					FinanceContingencyDays
					Furniture
					FurnitureOther
					InspectionContingency
					InspectionContingencyDays
					IsBestOffer
					OfferPrice
					Position
					RentBack
					RentBackDailyAmount
					RentBackDays
					RentBackInititated
					SaleOfHomeContingency
					Status
					SubmittedAt
				}
				Position
				SellerCounter {
					AppraisalContingency
					AppraisalContingencyDays
					ClosingPeriod
					FinanceContingency
					FinanceContingencyDays
					Furniture
					FurnitureOther
					InspectionContingency
					InspectionContingencyDays
					IsBestOffer
					OfferPrice
					Position
					RentBack
					RentBackDailyAmount
					RentBackDays
					RentBackInititated
					SaleOfHomeContingency
					Status
					SubmittedAt
				}
			}
			CreatedAt
			CreatedBy
			Docs {
				DocPermissions
				DocScope
				DocStatus
				DocType
				EventTime
				File {
					name
					size
					type
				}
				FileId
				ItemType
				ObjectKey
				OfferId
				OfferNo
				PropertyId
				QuestionDefinition {
					name
					title
					type
				}
				QuestionId
			}
			Entity {
				EntityType
				IndividualOnBehalf
				Name
				TitleName
			}
			FirmInfo {
				CompanyName
				CoordinatorEmail
				CoordinatorFirstName
				CoordinatorLastName
				CoordinatorPhone
				DRENumber
			}
			Funding {
				CashProofOfFundsAmount
				DownPayment
				FicoScore
				FinancingProofOfFundsAmount
				LoanType
				PreapprovedForLoan
				PurchaseType
			}
			HomeSale {
				Closing {
					EscrowCompany
					ExpectedClosingPeriod
					HasBuyerReleasedContingencies
					ListingAgent {
						Email
						FirstName
						LastName
						Phone
					}
				}
				OnMarket {
					BrokerageFirm
					HasOffers
					ListingAgent {
						Email
						FirstName
						LastName
					}
					OnMarketDate
				}
				Property {
					City
					State
					Status
					Street
					Zip
				}
			}
			NileAgentId
			Offer {
				AppraisalContingency
				AppraisalContingencyDays
				ClosingPeriod
				FinanceContingency
				FinanceContingencyDays
				Furniture
				FurnitureOther
				InspectionContingency
				InspectionContingencyDays
				OfferPrice
				SaleOfHomeContingency
				SubmittedAt
			}
			OfferId
			OfferNo
			Property {
				BuyerAgentFeePct
				CreatedAt
				CreatedBy
				DTPropertyId
				ListPriceRange {
					Max
					MaxFormatted
					Min
					MinFormatted
				}
				ListingAgentFeePct
				ListingDetails {
					ListPrice
				}
				ListingTerminationDate
				NileAgentId
				OfferDueDate
				PropertyId
				PropertyNo
				ReportStatus {
					TaxReportTaxResourceMessage
					TaxReportTaxResourceStatus
					TaxReportTaxResourceUpdatedAt
					TaxReportTotalViewMessage
					TaxReportTotalViewStatus
					TaxReportTotalViewUpdatedAt
				}
				SellerInfo {
					Email
					FirstName
					LastName
					Phone
				}
				StatusId
				UpdatedAt
				UpdatedBy
			}
			PropertyData {
				Bedrooms
				City
				County
				FullAddress
				FullBath
				LandUse
				LivingArea
				LotArea
				OwnerNames
				State
				StreetAddress
				VestingType
				YearBuilt
				Zip
			}
			PropertyId
			PropertyNo
			SCARReport {
				ObjectKey
				PresignedUrl
				UpdatedAt
			}
			SellerAccepted
			Step {
				allowed_actions {
					action
					form_action
					next_step_id
				}
				allowed_upload_file_types {
					allow_files_count
					allow_single_file_max_size_mb
					file_type
				}
				buyer_can_submit_offer
				display_on_advisor_portal
				display_on_seller_dashboard
				id
				messages {
					advisor
					buyer
					seller
				}
				name
				waiting_seller_to_act
			}
			StepId
			UpdatedAt
			UpdatedBy
		}
	}
`;
export const updatePropertyData = /* GraphQL */ `
	mutation UpdatePropertyData(
		$AdditionalInfo: String
		$BuyerAgentFeePct: Float
		$CalendarUrl: AWSURL
		$ListPriceRange: ListPriceRangeInput
		$ListingAgentFeePct: Float
		$ListingTerminationDate: AWSDate
		$ListingTermsConditions: String
		$NewPropertyWizardStep: String
		$OfferDueDate: AWSDate
		$PropertyId: ID!
		$SellerInfo: SellerInfoInput
		$VestingType: VestingTypeInput
		$YearBuilt: YearBuiltInput
	) {
		updatePropertyData(
			AdditionalInfo: $AdditionalInfo
			BuyerAgentFeePct: $BuyerAgentFeePct
			CalendarUrl: $CalendarUrl
			ListPriceRange: $ListPriceRange
			ListingAgentFeePct: $ListingAgentFeePct
			ListingTerminationDate: $ListingTerminationDate
			ListingTermsConditions: $ListingTermsConditions
			NewPropertyWizardStep: $NewPropertyWizardStep
			OfferDueDate: $OfferDueDate
			PropertyId: $PropertyId
			SellerInfo: $SellerInfo
			VestingType: $VestingType
			YearBuilt: $YearBuilt
		) {
			AdditionalInfo
			BuyerAgentFeePct
			CalendarUrl
			CreatedAt
			CreatedBy
			DTPropertyId
			Docs {
				DocPermissions
				DocScope
				DocStatus
				DocType
				EventTime
				File {
					name
					size
					type
				}
				FileId
				ItemType
				ObjectKey
				OfferId
				OfferNo
				PropertyId
				QuestionDefinition {
					name
					title
					type
				}
				QuestionId
			}
			ExternalId
			HomeViewingCalendarProfileId
			ListPriceRange {
				Max
				MaxFormatted
				Min
				MinFormatted
			}
			ListingAgentFeePct
			ListingDetails {
				ListPrice
			}
			ListingTerminationDate
			ListingTermsConditions
			NewPropertyWizardStep
			NileAgentId
			OfferDueDate
			Offers {
				AdditionalBuyerInfo
				AdditionalBuyers
				AgentInfo {
					DRENumber
					Email
					FirstName
					LastName
					Name
					Phone
				}
				BuyerInfo {
					FirstName
					LastName
					MiddleName
					TitleName
				}
				BuyerType
				CounterOffer {
					BuyerCounter {
						AppraisalContingency
						AppraisalContingencyDays
						ClosingPeriod
						FinanceContingency
						FinanceContingencyDays
						Furniture
						FurnitureOther
						InspectionContingency
						InspectionContingencyDays
						IsBestOffer
						OfferPrice
						Position
						RentBack
						RentBackDailyAmount
						RentBackDays
						RentBackInititated
						SaleOfHomeContingency
						Status
						SubmittedAt
					}
					CounterOfferId
					Forms {
						DataStatus
						FormGroup
						FormId
						FormTypeId
						Name
						Order
					}
					Position
					SellerCounter {
						AppraisalContingency
						AppraisalContingencyDays
						ClosingPeriod
						FinanceContingency
						FinanceContingencyDays
						Furniture
						FurnitureOther
						InspectionContingency
						InspectionContingencyDays
						IsBestOffer
						OfferPrice
						Position
						RentBack
						RentBackDailyAmount
						RentBackDays
						RentBackInititated
						SaleOfHomeContingency
						Status
						SubmittedAt
					}
				}
				CounterOfferHistory {
					BuyerCounter {
						AppraisalContingency
						AppraisalContingencyDays
						ClosingPeriod
						FinanceContingency
						FinanceContingencyDays
						Furniture
						FurnitureOther
						InspectionContingency
						InspectionContingencyDays
						IsBestOffer
						OfferPrice
						Position
						RentBack
						RentBackDailyAmount
						RentBackDays
						RentBackInititated
						SaleOfHomeContingency
						Status
						SubmittedAt
					}
					Position
					SellerCounter {
						AppraisalContingency
						AppraisalContingencyDays
						ClosingPeriod
						FinanceContingency
						FinanceContingencyDays
						Furniture
						FurnitureOther
						InspectionContingency
						InspectionContingencyDays
						IsBestOffer
						OfferPrice
						Position
						RentBack
						RentBackDailyAmount
						RentBackDays
						RentBackInititated
						SaleOfHomeContingency
						Status
						SubmittedAt
					}
				}
				CreatedAt
				CreatedBy
				Entity {
					EntityType
					IndividualOnBehalf
					Name
					TitleName
				}
				FirmInfo {
					CompanyName
					CoordinatorEmail
					CoordinatorFirstName
					CoordinatorLastName
					CoordinatorPhone
					DRENumber
				}
				Funding {
					CashProofOfFundsAmount
					DownPayment
					FicoScore
					FinancingProofOfFundsAmount
					LoanType
					PreapprovedForLoan
					PurchaseType
				}
				HomeSale {
					Closing {
						EscrowCompany
						ExpectedClosingPeriod
						HasBuyerReleasedContingencies
						ListingAgent {
							Email
							FirstName
							LastName
							Phone
						}
					}
					OnMarket {
						BrokerageFirm
						HasOffers
						ListingAgent {
							Email
							FirstName
							LastName
						}
						OnMarketDate
					}
					Property {
						City
						State
						Status
						Street
						Zip
					}
				}
				NileAgentId
				Offer {
					AppraisalContingency
					AppraisalContingencyDays
					ClosingPeriod
					FinanceContingency
					FinanceContingencyDays
					Furniture
					FurnitureOther
					InspectionContingency
					InspectionContingencyDays
					OfferPrice
					SaleOfHomeContingency
					SubmittedAt
				}
				OfferId
				OfferNo
				PropertyData {
					Bedrooms
					City
					County
					FullAddress
					FullBath
					LandUse
					LivingArea
					LotArea
					OwnerNames
					State
					StreetAddress
					VestingType
					YearBuilt
					Zip
				}
				PropertyId
				PropertyNo
				SellerAccepted
				Step {
					allowed_actions {
						action
						form_action
						next_step_id
					}
					allowed_upload_file_types {
						allow_files_count
						allow_single_file_max_size_mb
						file_type
					}
					buyer_can_submit_offer
					display_on_advisor_portal
					display_on_seller_dashboard
					id
					messages {
						advisor
						buyer
						seller
					}
					name
					waiting_seller_to_act
				}
				StepId
				UpdatedAt
				UpdatedBy
			}
			PropertyData {
				Bedrooms
				City
				County
				FullAddress
				FullBath
				LandUse
				LivingArea
				LotArea
				OwnerNames
				State
				StreetAddress
				VestingOwnershipRight
				VestingTypeMapped
				VestingTypeOverride
				VestingTypeUsed
				YearBuilt
				YearBuiltDisp
				YearBuiltOverride
				YearBuiltUsed
				Zip
			}
			PropertyFormDataStatuses {
				CategoryId
				FormDataApproved
				FormDataApprovedAt
				FormDataCreatedAt
				FormDataStatus
				FormDataSubmitted
				FormDataUpdatedAt
				FormId
				FormTypeId
				Title
			}
			PropertyFormsSubmitted
			PropertyId
			PropertyNo
			PropertyReport {
				ObjectKey
				PresignedUrl
				UpdatedAt
			}
			ReportStatus {
				TaxReportTaxResourceMessage
				TaxReportTaxResourceStatus
				TaxReportTaxResourceUpdatedAt
				TaxReportTotalViewMessage
				TaxReportTotalViewStatus
				TaxReportTotalViewUpdatedAt
			}
			SellerInfo {
				Email
				FirstName
				LastName
				Phone
			}
			StatusId
			Thumbnail {
				file {
					path
				}
				id
				key
				name
				presignedUrl
				size
				type
			}
			UpdatedAt
			UpdatedBy
		}
	}
`;
export const updatePropertyReportStatus = /* GraphQL */ `
	mutation UpdatePropertyReportStatus(
		$ReportStatus: ModelPropertyReportStatusInput!
	) {
		updatePropertyReportStatus(ReportStatus: $ReportStatus) {
			DTPropertyId
			PropertyId
			TaxReportTaxResourceMessage
			TaxReportTaxResourceStatus
			TaxReportTaxResourceUpdatedAt
			TaxReportTotalViewMessage
			TaxReportTotalViewStatus
			TaxReportTotalViewUpdatedAt
		}
	}
`;
export const updatePropertyStatus = /* GraphQL */ `
	mutation UpdatePropertyStatus($PropertyId: ID!, $StatusId: Int!) {
		updatePropertyStatus(PropertyId: $PropertyId, StatusId: $StatusId) {
			AdditionalInfo
			BuyerAgentFeePct
			CalendarUrl
			CreatedAt
			CreatedBy
			DTPropertyId
			Docs {
				DocPermissions
				DocScope
				DocStatus
				DocType
				EventTime
				File {
					name
					size
					type
				}
				FileId
				ItemType
				ObjectKey
				OfferId
				OfferNo
				PropertyId
				QuestionDefinition {
					name
					title
					type
				}
				QuestionId
			}
			ExternalId
			HomeViewingCalendarProfileId
			ListPriceRange {
				Max
				MaxFormatted
				Min
				MinFormatted
			}
			ListingAgentFeePct
			ListingDetails {
				ListPrice
			}
			ListingTerminationDate
			ListingTermsConditions
			NewPropertyWizardStep
			NileAgentId
			OfferDueDate
			Offers {
				AdditionalBuyerInfo
				AdditionalBuyers
				AgentInfo {
					DRENumber
					Email
					FirstName
					LastName
					Name
					Phone
				}
				BuyerInfo {
					FirstName
					LastName
					MiddleName
					TitleName
				}
				BuyerType
				CounterOffer {
					BuyerCounter {
						AppraisalContingency
						AppraisalContingencyDays
						ClosingPeriod
						FinanceContingency
						FinanceContingencyDays
						Furniture
						FurnitureOther
						InspectionContingency
						InspectionContingencyDays
						IsBestOffer
						OfferPrice
						Position
						RentBack
						RentBackDailyAmount
						RentBackDays
						RentBackInititated
						SaleOfHomeContingency
						Status
						SubmittedAt
					}
					CounterOfferId
					Forms {
						DataStatus
						FormGroup
						FormId
						FormTypeId
						Name
						Order
					}
					Position
					SellerCounter {
						AppraisalContingency
						AppraisalContingencyDays
						ClosingPeriod
						FinanceContingency
						FinanceContingencyDays
						Furniture
						FurnitureOther
						InspectionContingency
						InspectionContingencyDays
						IsBestOffer
						OfferPrice
						Position
						RentBack
						RentBackDailyAmount
						RentBackDays
						RentBackInititated
						SaleOfHomeContingency
						Status
						SubmittedAt
					}
				}
				CounterOfferHistory {
					BuyerCounter {
						AppraisalContingency
						AppraisalContingencyDays
						ClosingPeriod
						FinanceContingency
						FinanceContingencyDays
						Furniture
						FurnitureOther
						InspectionContingency
						InspectionContingencyDays
						IsBestOffer
						OfferPrice
						Position
						RentBack
						RentBackDailyAmount
						RentBackDays
						RentBackInititated
						SaleOfHomeContingency
						Status
						SubmittedAt
					}
					Position
					SellerCounter {
						AppraisalContingency
						AppraisalContingencyDays
						ClosingPeriod
						FinanceContingency
						FinanceContingencyDays
						Furniture
						FurnitureOther
						InspectionContingency
						InspectionContingencyDays
						IsBestOffer
						OfferPrice
						Position
						RentBack
						RentBackDailyAmount
						RentBackDays
						RentBackInititated
						SaleOfHomeContingency
						Status
						SubmittedAt
					}
				}
				CreatedAt
				CreatedBy
				Entity {
					EntityType
					IndividualOnBehalf
					Name
					TitleName
				}
				FirmInfo {
					CompanyName
					CoordinatorEmail
					CoordinatorFirstName
					CoordinatorLastName
					CoordinatorPhone
					DRENumber
				}
				Funding {
					CashProofOfFundsAmount
					DownPayment
					FicoScore
					FinancingProofOfFundsAmount
					LoanType
					PreapprovedForLoan
					PurchaseType
				}
				HomeSale {
					Closing {
						EscrowCompany
						ExpectedClosingPeriod
						HasBuyerReleasedContingencies
						ListingAgent {
							Email
							FirstName
							LastName
							Phone
						}
					}
					OnMarket {
						BrokerageFirm
						HasOffers
						ListingAgent {
							Email
							FirstName
							LastName
						}
						OnMarketDate
					}
					Property {
						City
						State
						Status
						Street
						Zip
					}
				}
				NileAgentId
				Offer {
					AppraisalContingency
					AppraisalContingencyDays
					ClosingPeriod
					FinanceContingency
					FinanceContingencyDays
					Furniture
					FurnitureOther
					InspectionContingency
					InspectionContingencyDays
					OfferPrice
					SaleOfHomeContingency
					SubmittedAt
				}
				OfferId
				OfferNo
				PropertyData {
					Bedrooms
					City
					County
					FullAddress
					FullBath
					LandUse
					LivingArea
					LotArea
					OwnerNames
					State
					StreetAddress
					VestingType
					YearBuilt
					Zip
				}
				PropertyId
				PropertyNo
				SellerAccepted
				Step {
					allowed_actions {
						action
						form_action
						next_step_id
					}
					allowed_upload_file_types {
						allow_files_count
						allow_single_file_max_size_mb
						file_type
					}
					buyer_can_submit_offer
					display_on_advisor_portal
					display_on_seller_dashboard
					id
					messages {
						advisor
						buyer
						seller
					}
					name
					waiting_seller_to_act
				}
				StepId
				UpdatedAt
				UpdatedBy
			}
			PropertyData {
				Bedrooms
				City
				County
				FullAddress
				FullBath
				LandUse
				LivingArea
				LotArea
				OwnerNames
				State
				StreetAddress
				VestingOwnershipRight
				VestingTypeMapped
				VestingTypeOverride
				VestingTypeUsed
				YearBuilt
				YearBuiltDisp
				YearBuiltOverride
				YearBuiltUsed
				Zip
			}
			PropertyFormDataStatuses {
				CategoryId
				FormDataApproved
				FormDataApprovedAt
				FormDataCreatedAt
				FormDataStatus
				FormDataSubmitted
				FormDataUpdatedAt
				FormId
				FormTypeId
				Title
			}
			PropertyFormsSubmitted
			PropertyId
			PropertyNo
			PropertyReport {
				ObjectKey
				PresignedUrl
				UpdatedAt
			}
			ReportStatus {
				TaxReportTaxResourceMessage
				TaxReportTaxResourceStatus
				TaxReportTaxResourceUpdatedAt
				TaxReportTotalViewMessage
				TaxReportTotalViewStatus
				TaxReportTotalViewUpdatedAt
			}
			SellerInfo {
				Email
				FirstName
				LastName
				Phone
			}
			StatusId
			Thumbnail {
				file {
					path
				}
				id
				key
				name
				presignedUrl
				size
				type
			}
			UpdatedAt
			UpdatedBy
		}
	}
`;
export const users_AddUserToUserGroup = /* GraphQL */ `
	mutation Users_AddUserToUserGroup($UserGroup: String!, $UserId: String!) {
		users_AddUserToUserGroup(UserGroup: $UserGroup, UserId: $UserId) {
			CreatedAt
			CreatedBy
			DRENumber
			Email
			Enabled
			FirstName
			LastName
			LicenseType
			MiddleName
			Phone
			UpdatedAt
			UpdatedBy
			UserGroups {
				Description
				GroupName
			}
			UserId
			UserStatus
		}
	}
`;
export const users_CreateUser = /* GraphQL */ `
	mutation Users_CreateUser(
		$DRENumber: String
		$Email: AWSEmail!
		$FirstName: String
		$LastName: String
		$LicenseType: String
		$MiddleName: String
		$Phone: AWSPhone
		$UserGroup: String
	) {
		users_CreateUser(
			DRENumber: $DRENumber
			Email: $Email
			FirstName: $FirstName
			LastName: $LastName
			LicenseType: $LicenseType
			MiddleName: $MiddleName
			Phone: $Phone
			UserGroup: $UserGroup
		) {
			CreatedAt
			CreatedBy
			DRENumber
			Email
			Enabled
			FirstName
			LastName
			LicenseType
			MiddleName
			Phone
			UpdatedAt
			UpdatedBy
			UserGroups {
				Description
				GroupName
			}
			UserId
			UserStatus
		}
	}
`;
export const users_DisableUser = /* GraphQL */ `
	mutation Users_DisableUser($UserId: String!) {
		users_DisableUser(UserId: $UserId) {
			CreatedAt
			CreatedBy
			DRENumber
			Email
			Enabled
			FirstName
			LastName
			LicenseType
			MiddleName
			Phone
			UpdatedAt
			UpdatedBy
			UserGroups {
				Description
				GroupName
			}
			UserId
			UserStatus
		}
	}
`;
export const users_EnableUser = /* GraphQL */ `
	mutation Users_EnableUser($UserId: String!) {
		users_EnableUser(UserId: $UserId) {
			CreatedAt
			CreatedBy
			DRENumber
			Email
			Enabled
			FirstName
			LastName
			LicenseType
			MiddleName
			Phone
			UpdatedAt
			UpdatedBy
			UserGroups {
				Description
				GroupName
			}
			UserId
			UserStatus
		}
	}
`;
export const users_RemoveUserFromUserGroup = /* GraphQL */ `
	mutation Users_RemoveUserFromUserGroup($UserGroup: String!, $UserId: String!) {
		users_RemoveUserFromUserGroup(UserGroup: $UserGroup, UserId: $UserId) {
			CreatedAt
			CreatedBy
			DRENumber
			Email
			Enabled
			FirstName
			LastName
			LicenseType
			MiddleName
			Phone
			UpdatedAt
			UpdatedBy
			UserGroups {
				Description
				GroupName
			}
			UserId
			UserStatus
		}
	}
`;
export const users_UpdateMyUserProfile = /* GraphQL */ `
	mutation Users_UpdateMyUserProfile(
		$DRENumber: String
		$FirstName: String
		$LastName: String
		$LicenseType: String
		$MiddleName: String
		$Phone: AWSPhone
	) {
		users_UpdateMyUserProfile(
			DRENumber: $DRENumber
			FirstName: $FirstName
			LastName: $LastName
			LicenseType: $LicenseType
			MiddleName: $MiddleName
			Phone: $Phone
		) {
			CreatedAt
			CreatedBy
			DRENumber
			Email
			Enabled
			FirstName
			LastName
			LicenseType
			MiddleName
			Phone
			UpdatedAt
			UpdatedBy
			UserGroups {
				Description
				GroupName
			}
			UserId
			UserStatus
		}
	}
`;
export const users_UpdateUserProfile = /* GraphQL */ `
	mutation Users_UpdateUserProfile(
		$DRENumber: String
		$FirstName: String
		$LastName: String
		$LicenseType: String
		$MiddleName: String
		$Phone: AWSPhone
		$UserId: String
	) {
		users_UpdateUserProfile(
			DRENumber: $DRENumber
			FirstName: $FirstName
			LastName: $LastName
			LicenseType: $LicenseType
			MiddleName: $MiddleName
			Phone: $Phone
			UserId: $UserId
		) {
			CreatedAt
			CreatedBy
			DRENumber
			Email
			Enabled
			FirstName
			LastName
			LicenseType
			MiddleName
			Phone
			UpdatedAt
			UpdatedBy
			UserGroups {
				Description
				GroupName
			}
			UserId
			UserStatus
		}
	}
`;
