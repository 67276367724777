import moment from 'moment';
import 'moment-timezone';
import { CALENDAR_ACTIONS } from './constants';
import { getDate } from '../helpers';

interface CalendarReducerActionProps {
	type: string;
	payload?: any;
}
const initialState = {
	config: {},
	error: undefined,
	isLoading: false,
	property: undefined,
	appointments: [],
	isSelecting: false,
	selectingCells: {},
	clientTimeZone: moment.tz.guess(),
};

const calendarReducer = (
	state = initialState,
	{ type, payload }: CalendarReducerActionProps
) => {
	switch (type) {
		case CALENDAR_ACTIONS.setConfig:
			return {
				...state,
				config: payload,
			};
		case CALENDAR_ACTIONS.setAvailability:
			return {
				...state,
				availability: payload,
			};
		case CALENDAR_ACTIONS.setBreakTime:
			return {
				...state,
				breakTime: payload,
			};
		case CALENDAR_ACTIONS.setStartOfWeek:
			return {
				...state,
				startOfWeek: payload,
			};
		case CALENDAR_ACTIONS.setEndOfWeek:
			return {
				...state,
				endOfWeek: payload,
			};
		case CALENDAR_ACTIONS.setError:
			return {
				...state,
				error: payload,
			};
		case CALENDAR_ACTIONS.setIsLoading:
			return {
				...state,
				isLoading: payload,
			};
		case CALENDAR_ACTIONS.setAppointments:
			return {
				...state,
				appointments: payload,
			};
		case CALENDAR_ACTIONS.addAppointments:
			return {
				...state,
				appointments: [...state.appointments, ...payload],
			};
		case CALENDAR_ACTIONS.setProperty:
			return {
				...state,
				property: payload,
			};
		case CALENDAR_ACTIONS.beginSelectingCell: {
			if (!payload) return state;
			return {
				...state,
				isSelecting: true,
				selectingCells: {
					date: getDate(payload),
					startCell: payload,
					endCell: payload,
				},
			};
		}
		case CALENDAR_ACTIONS.stopSelectingCell: {
			return { ...state, isSelecting: false, selectingCells: undefined };
		}
		case CALENDAR_ACTIONS.addSelectedCell: {
			const { selectingCells, isSelecting } = state;
			if (!isSelecting || !payload) return state;

			return {
				...state,
				selectingCells: { ...selectingCells, endCell: payload },
			};
		}
		case CALENDAR_ACTIONS.setClientTimeZone:
			return {
				...state,
				clientTimeZone: payload,
			};
		default:
			return state;
	}
};

export default calendarReducer;
