import _get from 'lodash.get';
import { TPermission } from '../types';

const actions = {
	create: 'Create',
	delete: 'Delete',
	manage: 'Manage',
	update: 'Update',
	view: 'View',
};

const objectTargets = {
	properties: 'Properties',
	forms: 'Forms',
	users: 'Users',
	offers: 'Offers',
};

const userCan = (
	action: string,
	objectTarget: string,
	permissions: TPermission
) => {
	const key = `Can${action}${objectTarget}`;
	return _get(permissions, key, false) || false;
};

export { actions, userCan, objectTargets };
