import { useState } from 'react';
import _get from 'lodash.get';
import { gql, useLazyQuery, ApolloError } from '@apollo/client';
import _isEmpty from 'lodash.isempty';
import { TFile, TUploadFile } from '../types';
import { generateFiles } from '../helpers/file';
import { restProvider } from '../dataProvider';
import useSnackBar from './useSnackBar';

type Props = {
	name: string;
	query: string;
	generateVariables: (files: TUploadFile[]) => Object;
	uploadDoneCallback?: Function;
	mappingFilesCallback?: (files: TFile[]) => any[];
};

type TPutUrl = { id: string; url: string };

type TData = { [key: string]: TPutUrl[] };

const useUploadFiles = ({
	name,
	query,
	generateVariables,
	uploadDoneCallback,
	mappingFilesCallback,
}: Props) => {
	const showSnackBar = useSnackBar();

	const [doUpload, { loading }] = useLazyQuery(gql(query), {
		onCompleted: async (data: TData) => {
			const putUrls = _get(data, name, []);
			if (_isEmpty(putUrls)) return;

			await Promise.all(
				files.map((file, index) =>
					restProvider.uploadToS3(_get(putUrls, [index, 'url'], ''), {
						body: file,
						type: file.type,
					})
				)
			);
			if (uploadDoneCallback) {
				uploadDoneCallback();
			}
			setIsUploadingToS3(false);
		},
		onError: (error: ApolloError) => {
			showSnackBar({
				message: _get(error, 'message', 'Something went wrong'),
				variant: 'alert',
				alertSeverity: 'error',
			});
			setIsUploadingToS3(false);
		},
	});

	const [isUploadingToS3, setIsUploadingToS3] = useState(false);
	const [files, setFiles] = useState<TFile[]>([]);

	const uploadFiles = () => {
		if (_isEmpty(files)) return;
		// If file contain id mean old files, no upload then
		if (_get(files, '0.id', '')) return;

		const mappingFiles = mappingFilesCallback || generateFiles;
		const uploadFiles = mappingFiles(files);
		doUpload({
			variables: generateVariables(uploadFiles),
		});
		setIsUploadingToS3(true);
	};

	const isUploadLoading = isUploadingToS3 || loading;

	return {
		files,
		onFilesChange: setFiles,
		uploadFiles,
		isUploadLoading,
	};
};

export default useUploadFiles;
