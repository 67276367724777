import { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import MainLayout from '../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import AuthGuard from '../utils/route-guard/AuthGuard';
import RouteWrapper from '../utils/route-guard/RouteWrapper';
import { actions, objectTargets } from '../helpers';
import { PATHS } from './path';

const DashboardPage = Loadable(lazy(() => import('../views/pages/dashboard')));
const FormListPage = Loadable(
	lazy(() => import('../views/pages/form/ListPage'))
);

const ApplicationFormEdit = Loadable(
	lazy(() => import('../views/pages/form/EditPage'))
);

const ApplicationFormAdd = Loadable(
	lazy(() => import('../views/application/form/FormAdd'))
);

const PropertyListPage = Loadable(
	lazy(() => import('../views/pages/property/ListPage'))
);

const PropertyEditPage = Loadable(
	lazy(() => import('../views/pages/property/EditPage'))
);

const PropertyAddPage = Loadable(
	lazy(() => import('../views/pages/property/AddPage'))
);

const PropertyWizardPage = Loadable(
	lazy(() => import('../views/pages/property/WizardPage'))
);

const OfferListPage = Loadable(
	lazy(() => import('../views/pages/offer/ListPage'))
);

const PropertyOfferEditPage = Loadable(
	lazy(() => import('../views/pages/offer/EditPropertyOfferPage'))
);

const UserProfilePage = Loadable(
	lazy(() => import('../views/pages/user/ProfilePage'))
);

const UserListPage = Loadable(
	lazy(() => import('../views/pages/user/ListPage'))
);

const UserEditPage = Loadable(
	lazy(() => import('../views/pages/user/EditPage'))
);

const UserAddPage = Loadable(lazy(() => import('../views/pages/user/AddPage')));

const PropertyFormPage = Loadable(
	lazy(() => import('../views/pages/property/FormPage'))
);

const FillFormDataPage = Loadable(
	lazy(() => import('../views/pages/form/FillFormDataPage'))
);

const MainRoutes = () => {
	const location = useLocation();

	return (
		<Route path={Object.values(PATHS)}>
			<MainLayout>
				<Switch location={location} key={location.pathname}>
					<AuthGuard>
						<>
							<Route exact path={PATHS.DASHBOARD} component={DashboardPage} />
							<Route path={PATHS.USER_PROFILE} component={UserProfilePage} />
							<RouteWrapper
								exact
								action={actions.view}
								objectTarget={objectTargets.forms}
								path={PATHS.FORMS}
								component={FormListPage}
							/>
							<RouteWrapper
								exact
								action={actions.create}
								objectTarget={objectTargets.forms}
								path={PATHS.FORM_ADD}
								component={ApplicationFormAdd}
							/>
							<RouteWrapper
								exact
								action={actions.update}
								objectTarget={objectTargets.forms}
								path={PATHS.FORM}
								component={ApplicationFormEdit}
							/>
							<RouteWrapper
								exact
								action={actions.view}
								objectTarget={objectTargets.offers}
								path={PATHS.OFFERS}
								component={OfferListPage}
							/>
							<RouteWrapper
								exact
								action={actions.update}
								objectTarget={objectTargets.properties}
								path={PATHS.PROPERTY_OFFER}
								component={PropertyOfferEditPage}
							/>
							<RouteWrapper
								exact
								action={actions.view}
								objectTarget={objectTargets.properties}
								path={PATHS.PROPERTY_FORM}
								component={PropertyFormPage}
							/>
							<RouteWrapper
								exact
								action={actions.view}
								objectTarget={objectTargets.properties}
								path={PATHS.FILL_FORM_DATA}
								component={FillFormDataPage}
							/>
							<RouteWrapper
								exact
								action={actions.create}
								objectTarget={objectTargets.properties}
								path={PATHS.PROPERTY_ADD}
								component={PropertyAddPage}
							/>
							<RouteWrapper
								exact
								action={actions.create}
								objectTarget={objectTargets.properties}
								path={PATHS.PROPERTY_WIZARD}
								component={PropertyWizardPage}
							/>
							<RouteWrapper
								exact
								action={actions.view}
								objectTarget={objectTargets.properties}
								path={PATHS.PROPERTIES}
								component={PropertyListPage}
							/>
							<RouteWrapper
								exact
								action={actions.update}
								objectTarget={objectTargets.properties}
								path={PATHS.PROPERTY_EDIT}
								component={PropertyEditPage}
							/>
							<RouteWrapper
								exact
								action={actions.manage}
								objectTarget={objectTargets.users}
								path={PATHS.USERS_LIST}
								component={UserListPage}
							/>
							<RouteWrapper
								exact
								action={actions.manage}
								objectTarget={objectTargets.users}
								path={PATHS.USERS_EDIT}
								component={UserEditPage}
							/>
							<RouteWrapper
								exact
								action={actions.manage}
								objectTarget={objectTargets.users}
								path={PATHS.USER_ADD}
								component={UserAddPage}
							/>
						</>
					</AuthGuard>
				</Switch>
			</MainLayout>
		</Route>
	);
};
export default MainRoutes;
