import { FieldPolicy } from '@apollo/client';
import _uniqBy from 'lodash.uniqby';
import { TGetOffers } from '../../types';

export const getOffers: FieldPolicy = {
	keyArgs: ['Query', 'StepIds'],
	merge<T extends TGetOffers>(existing: T | undefined, incoming: T) {
		if (!existing) return incoming;

		const newItems = _uniqBy([...existing.Items, ...incoming.Items], 'OfferId');

		return {
			Items: newItems,
			Count: existing.Count + incoming.Count,
			Total: incoming.Total,
			PageIndex: incoming.PageIndex,
			PageSize: incoming.PageSize,
		};
	},
};
