import { Redirect } from 'react-router-dom';

// project imports
import { useAuth } from '../../hooks';
import { GuardProps } from '../../types';
import { AUTH_PATHS } from '../../routes/path';

// -----------------------|| AUTH GUARD ||-----------------------//

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }: GuardProps) => {
	const { isLoggedIn } = useAuth();

	if (!isLoggedIn) {
		return <Redirect to={AUTH_PATHS.LOGIN} />;
	}

	return children;
};

export default AuthGuard;
