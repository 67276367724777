import _isEmpty from 'lodash.isempty';
import axios from 'axios';

const uploadFile = (url: string, file: any, options: { headers: Object }) => {
	if (_isEmpty(url)) return false;
	return axios.put(url, file, {
		headers: {
			...options.headers,
			Authorization: '',
		},
	});
};

export { uploadFile };
