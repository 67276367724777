import * as queries from './queries';
import * as mutations from './mutations';
import * as subscriptions from './subscriptions';
import * as customs from '../dataProvider/customGraphql';

const GraphQl = {
	...queries,
	...mutations,
	...subscriptions,
	...customs,
};

export default GraphQl;
