import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from '../store/actions';

type TOptions = {
	type?: string;
	open?: true;
	message?: string;
	anchorOrigin?: string;
	variant?: string;
	alertSeverity?: string;
	transition?: string;
	close?: boolean;
	actionButton?: string;
};

const useSnackBar = () => {
	const dispatch = useDispatch();

	const show = (options: TOptions) =>
		dispatch({
			type: SNACKBAR_OPEN,
			open: true,
			message: 'Something went wrong',
			variant: 'alert',
			alertSeverity: 'error',
			anchorOrigin: { vertical: 'top', horizontal: 'center' },
			...options,
		});

	return show;
};

export default useSnackBar;
