import { useSelector } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, StyledEngineProvider } from '@material-ui/core';
import Routes from './routes';
import { DefaultRootStateProps } from './types';
import theme from './themes';
import Locales from './ui-component/Locales';
import NavigationScroll from './layout/NavigationScroll';
import Snackbar from './ui-component/extended/Snackbar';
import { AWSProvider } from './contexts/AWSContext';
import { AclProvider } from './contexts/Acl';

const App = () => {
	const customization = useSelector(
		(state: DefaultRootStateProps) => state.customization
	);

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme(customization)}>
				<CssBaseline />
				{/* RTL layout */}
				{/* <RTLLayout> */}
				<Locales>
					<NavigationScroll>
						<AclProvider>
							<AWSProvider>
								<>
									<Routes />
									<Snackbar />
								</>
							</AWSProvider>
						</AclProvider>
					</NavigationScroll>
				</Locales>
				{/* </RTLLayout> */}
			</ThemeProvider>
		</StyledEngineProvider>
	);
};

export default App;
