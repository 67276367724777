import React, { createContext, useEffect, useReducer } from 'react';

import { CognitoUser } from 'amazon-cognito-identity-js';
import Amplify, { Auth } from 'aws-amplify';
import { AWS_STATE_CHANGED } from '../store/actions';

// project imports
import Loader from '../ui-component/Loader';
import config from '../config';
import { initialLoginContextProps } from '../types';

Amplify.configure({
	Auth: {
		region: config.awsAppSyncConfig.region,
		userPoolId: config.awsAppSyncConfig.userPoolId,
		userPoolWebClientId: config.awsAppSyncConfig.userPoolWebClientId,
	},
});

// reducer - state management
const reducer = (
	state = initialState,
	action: {
		type: string;
		payload: {
			isLoggedIn: boolean;
			user: CognitoUser | null;
			isRequiredNewPassword?: boolean;
		};
	}
) => {
	switch (action.type) {
		case AWS_STATE_CHANGED: {
			const { isLoggedIn, user, isRequiredNewPassword } = action.payload;

			return {
				...state,
				isLoggedIn,
				isRequiredNewPassword,
				isInitialized: true,
				user,
			};
		}
		default: {
			return { ...state };
		}
	}
};

const initialState: initialLoginContextProps = {
	isLoggedIn: false,
	isInitialized: false,
	user: null,
	isRequiredNewPassword: false,
};

type TParams = {
	username: string;
	password: string;
};

const AWSContext = createContext({
	...initialState,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	login: (value: TParams) => Promise.resolve(),
	logout: () => Promise.resolve(),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	completePassword: (password: string) => Promise.resolve(),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	forgotPassword: (email: string) => Promise.resolve(),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	confirmPassword: (username: string, otpCode: string, newPassword: string) =>
		Promise.resolve(),
});

export const AWSProvider = ({ children }: { children: React.ReactElement }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const isRequiredNewPassword = (user: { challengeName: string | null }) =>
		user.challengeName === 'NEW_PASSWORD_REQUIRED';

	const login = async ({ username, password }: TParams) => {
		const user = await Auth.signIn(username, password);
		const isUserRequiredNewPassword = isRequiredNewPassword(user);
		dispatch({
			type: AWS_STATE_CHANGED,
			payload: {
				isLoggedIn: !isUserRequiredNewPassword,
				isRequiredNewPassword: isUserRequiredNewPassword,
				user,
			},
		});
		// We need to reload this page because after apollo client can't get jwt token
		// @TODO we need to find out best solution for this case after
		if(!isUserRequiredNewPassword) window.location.reload();
	};

	const completePassword = async (newPassword: string) => {
		const { user } = state;
		const newUser = await Auth.completeNewPassword(user, newPassword);
		dispatch({
			type: AWS_STATE_CHANGED,
			payload: {
				isLoggedIn: true,
				isRequiredNewPassword: false,
				user: newUser,
			},
		});
		// We need to reload this page because after apollo client can't get jwt token
		// @TODO we need to find out best solution for this case after
		window.location.reload();
	};

	const logout = async () => {
		await Auth.signOut();
		dispatch({
			type: AWS_STATE_CHANGED,
			payload: {
				isLoggedIn: false,
				user: null,
			},
		});
	};

	const forgotPassword = async (email: string) => {
		await Auth.forgotPassword(email);
		dispatch({
			type: AWS_STATE_CHANGED,
			payload: {
				isLoggedIn: false,
				isRequiredNewPassword: false,
				user: null,
			},
		});
	};

	const confirmPassword = async (
		username: string,
		otpCode: string,
		newPassword: string
	) => {
		await Auth.forgotPasswordSubmit(username, otpCode, newPassword);
		dispatch({
			type: AWS_STATE_CHANGED,
			payload: {
				isLoggedIn: false,
				user: null,
			},
		});
	};

	useEffect(() => {
		const updateAuthState = async () => {
			await Auth.currentAuthenticatedUser()
				.then(user => {
					const isUserRequiredNewPassword = isRequiredNewPassword(user);
					dispatch({
						type: AWS_STATE_CHANGED,
						payload: {
							isLoggedIn: !isUserRequiredNewPassword,
							isRequiredNewPassword: isUserRequiredNewPassword,
							user,
						},
					});
				})
				.catch(() =>
					dispatch({
						type: AWS_STATE_CHANGED,
						payload: {
							isLoggedIn: false,
							user: null,
						},
					})
				);
		};

		updateAuthState();
	}, [dispatch]);

	if (!state.isInitialized) {
		return <Loader />;
	}

	return (
		<AWSContext.Provider
			value={{
				...state,
				login,
				logout,
				completePassword,
				forgotPassword,
				confirmPassword,
			}}
		>
			{children}
		</AWSContext.Provider>
	);
};

export default AWSContext;
