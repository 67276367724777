import config from '../config';

export const formatDate = (date: string | null): string =>
	`${
		date
			? new Intl.DateTimeFormat(config.locate.country, {}).format(new Date(date))
			: ''
	}`;

export const TIME_REGEX = '(10|11|12|0[1-9]):[0-5][0-9] [a|A|P|p]m';

export const isValidTime = (value: string): boolean =>
	new RegExp(TIME_REGEX).test(value);
