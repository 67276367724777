import { Route, RouteProps, Redirect } from 'react-router-dom';
import { usePermission } from '../../hooks';
import { MAINTENANCE_PATHS } from '../../routes/path';
import Loader from '../../ui-component/Loader';

interface RouteWrapperProps extends RouteProps {
	component: any;
	action: string;
	objectTarget: string;
}

const RouteWrapper = (props: RouteWrapperProps) => {
	const { component: Component, action, objectTarget, ...restOfProps } = props;
	const { permissions, userCan } = usePermission();

	if (permissions === undefined) {
		return <Loader />;
	}

	const isDenied = !userCan(objectTarget)(action)(false);

	return (
		<Route
			exact
			{...restOfProps}
			render={props =>
				isDenied ? (
					<Redirect to={MAINTENANCE_PATHS.FORBIDDEN} />
				) : (
					<Component {...props} />
				)
			}
		/>
	);
};

export default RouteWrapper;
