import _get from 'lodash.get';
import { TFormCategory } from '../types';

export const reorderFormTypes = (formTypes = []) =>
	formTypes.reduce((lastType, formType) => {
		const formTypeId = _get(formType, 'id');
		const formTypeName = _get(formType, 'Name', formTypeId);
		return { ...lastType, [formTypeId]: formTypeName };
	}, {});

export const reorderCategories = (listFormCategories: TFormCategory[] = []) =>
	listFormCategories.reduce((lastCategories, category) => {
		const categoryId = _get(category, 'id');
		const categoryName = _get(category, 'Title', categoryId);
		return {
			...lastCategories,
			[categoryId]: {
				categoryName,
				formTypes: reorderFormTypes(_get(category, 'FormTypes', [])),
			},
		};
	}, {});
