import { Redirect, Switch, HashRouter } from 'react-router-dom';
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import MaintenanceRoutes from './MaintenanceRoutes';
import config from '../config';

const Routes = () => (
	<HashRouter>
		<Switch>
			<Redirect exact from='/' to={config.defaultPath} />
			<>
				{/* Routes for authentication pages */}
				<AuthenticationRoutes />

				{/* Route for login */}
				<LoginRoutes />

				{/* Routes for main layouts */}
				<MainRoutes />
				<MaintenanceRoutes />
			</>
		</Switch>
	</HashRouter>
);

export default Routes;
