import axios from '../utils/axios';

type TUploadToS3Params = {
	body: File;
	type: string;
};

export const restProvider = {
	uploadToS3: (url: string, params: TUploadToS3Params) => {
		const { body, type } = params;
		return axios({
			method: 'PUT',
			url,
			data: body,
			headers: new Headers({
				Accept: 'application/json',
				'Content-Type': type || 'application/json',
			}),
		});
	},
};
