import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import { lazy } from 'react';
import MinimalLayout from "../layout/MinimalLayout";
import Loadable from '../ui-component/Loadable';
import GuestGuard from "../utils/route-guard/GuestGuard";
import NavMotion from "../layout/NavMotion";
// import auth options

const AuthCompletePassword = Loadable(
	lazy(() => import('../views/pages/authentication/completePassword'))
);
const ForgotPasswordComponent = Loadable(
	lazy(() => import('../views/pages/authentication/forgotPassword'))
);
const ConfirmationPasswordComponent = Loadable(
	lazy(() => import('../views/pages/authentication/confirmationPassword'))
);

const AuthenticationRoutes = () => {
	const location = useLocation();

	return (
		<Route path={['/completePassword', '/forgotPassword', '/confirmationPassword']}>
			<MinimalLayout>
				<Switch location={location} key={location.pathname}>
					<NavMotion>
						<GuestGuard>
							<>
								<Route path='/forgotPassword' component={ForgotPasswordComponent} />
								<Route path='/completePassword' component={AuthCompletePassword} />
								<Route path='/confirmationPassword/:username' component={ConfirmationPasswordComponent} />
							</>
						</GuestGuard>
					</NavMotion>
				</Switch>
			</MinimalLayout>
		</Route>
	);
};

export default AuthenticationRoutes;
