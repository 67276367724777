import _get from 'lodash.get';
import _isEmpty from 'lodash.isempty';
import { ApolloCache, FetchResult, FieldPolicy } from '@apollo/client';
import { TForm, TListForms } from '../../types';

export const listForms: FieldPolicy = {
	keyArgs: false,
	merge<T extends TListForms>(existing: T | undefined, incoming: T) {
		if (!existing) return incoming;

		return {
			items: [...existing.items, ...incoming.items],
			nextToken: incoming.nextToken,
		};
	},
};

export const createFormCache = (
	cache: ApolloCache<any>,
	result: FetchResult<TForm>
) => {
	cache.modify({
		fields: {
			listForms(existingForms: TListForms) {
				const newForm = _get(result, ['data', 'createForm'], {});
				if (_isEmpty(newForm)) return existingForms;

				const { items } = existingForms;
				return { ...existingForms, items: [...items, newForm] };
			},
		},
	});
};

export const updateFormCache = (
	cache: ApolloCache<any>,
	result: FetchResult<TForm>
) => {
	cache.modify({
		fields: {
			listForms(existingForms: TListForms) {
				const updatedForm = _get(result, ['data', 'updateForm'], {});
				if (_isEmpty(updatedForm)) return existingForms;

				const { items } = existingForms;
				const filteredItems = items.filter(
					item => item.FormId !== updatedForm.FormId
				);
				return { ...existingForms, items: [...filteredItems, updatedForm] };
			},
		},
	});
};
