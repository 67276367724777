import { useMutation, gql } from '@apollo/client';
import graphSchema from '../graphql';

const useFormSave = () => {
	const [doSave, { loading: isSaving, data: savedData }] = useMutation(
		gql(graphSchema.saveFormData)
	);
	const [doSubmit, { loading: isSubmitting, data: submittedData }] = useMutation(
		gql(graphSchema.submitFormData)
	);

	return {
		doSave,
		isSaving,
		savedData,
		doSubmit,
		isSubmitting,
		submittedData,
	};
};

export default useFormSave;
