import { Typography } from '@material-ui/core';
import NavItem from '../NavItem';
import { NavGroupProps } from '../NavGroup';
import NavCollapse from './index';

interface MenuProps {
	item: NavGroupProps['item'];
	level: number;
}

const Menu = ({ item, level }: MenuProps) => {
	const { type } = item;
	switch (type) {
		case 'collapse':
			return <NavCollapse key={item.id} menu={item} level={level + 1} />;
		case 'item':
			return <NavItem key={item.id} item={item} level={level + 1} />;
		default:
			return (
				<Typography key={item.id} variant='h6' color='error' align='center'>
					Menu Items Error
				</Typography>
			);
	}
};

export default Menu;
