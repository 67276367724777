import _get from 'lodash.get';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import _groupBy from 'lodash.groupby';
import {
	TDay,
	TAvailability,
	TBreakTime,
	TSelectingCells,
} from '../../../../../types';
import { store } from '../../../../../index';
import type { TAppointment } from '../../../../../types/YouCanBook/bookings';

import {
	UNAVAILABLE_GROUP,
	WEEK_DAYS,
	AVAILABILITY_SETTING,
	BUSY_APPOINTMENT_NAME,
} from './constants';

export const OVERLAY_ID = 'booking-overlay';
export const DATE_PICKER_ID = 'date-picker';
export const CELL_CLASS = 'cell';
const TIME_24H_FORMAT = 'HH:mm:ss';
const TIME_12H_FORMAT = 'hh:mm A';
export const DATE_FORMAT = 'YYYY-MM-DD';
const MOMENT_FORMAT = `${DATE_FORMAT} HH:mm:ss`;
export const DISPLAY_DATE_FORMAT = 'dddd, MMMM DD, YYYY';
export const DISPLAY_TIME_FORMAT = 'hh:mm A';

const today = moment().format(DATE_FORMAT);

export const getDisplayTime = (day: TDay): string =>
	hasDay(day)
		? `${_get(day, 'start')} - ${_get(day, 'end')}`
		: UNAVAILABLE_GROUP;

export const displayDays = (days: TDay[]): string =>
	days.map((day: TDay) => day.name).join(', ');

export const hasDay = ({ start, end }: TDay): boolean => Boolean(start || end);

export const from24To12Format = (dayString: string | Moment): string =>
	moment(dayString).format(TIME_12H_FORMAT);

export const from12To24Format = (dayString: string | Moment): string =>
	moment(dayString).format(TIME_24H_FORMAT);

type TGetTimeConfig = {
	config: Object;
	dateString: string | Moment;
	key: string;
};

type TCheckAvailableMoment = {
	dateName: string;
	config: Object;
	currentMoment: Moment;
	currentDate: string;
};

const getTimeConfig = ({ config, dateString, key }: TGetTimeConfig) => {
	const fieldConfig = _get(config, key);
	if (!fieldConfig) return '';
	const timeZone = _get(config, 'timeZone');
	return convertTimezoneToLocal(`${dateString} ${fieldConfig}`, timeZone);
};

export const convertAvailability = (config: Object): TAvailability =>
	WEEK_DAYS.reduce((result, day) => {
		if (!_get(config, `${day}Active`)) {
			return { ...result, [day]: { name: day } };
		}

		const start =
			getTimeConfig({ config, dateString: today, key: `${day}Start` }) ||
			_get(AVAILABILITY_SETTING, `${day}.start`);
		const end =
			getTimeConfig({ config, dateString: today, key: `${day}End` }) ||
			_get(AVAILABILITY_SETTING, `${day}.end`);

		return {
			...result,
			[day]: {
				name: day,
				start: from24To12Format(start),
				end: from24To12Format(end),
			},
		};
	}, {});

export const convertBreakTime = (config: Object): TBreakTime => {
	const start = getTimeConfig({ config, dateString: today, key: 'breakStart' });
	const end = getTimeConfig({ config, dateString: today, key: 'breakEnd' });

	return {
		breakStart: from24To12Format(start),
		breakEnd: from24To12Format(end),
	};
};

export const convertAvailabilityToSave = (
	availability: Object,
	timeZone: string
) =>
	Object.keys(availability).reduce((result, day) => {
		const data = _get(availability, [day]) || {};
		if (!hasDay(data)) return { ...result, [`${day}Active`]: false };

		const start = convertLocalToTimezone(`${today} ${data.start}`, timeZone);
		const end = convertLocalToTimezone(`${today} ${data.end}`, timeZone);

		return {
			...result,
			[`${day}Active`]: true,
			[`${day}Start`]: from12To24Format(start),
			[`${day}End`]: from12To24Format(end),
		};
	}, {});

export const convertBreakTimeToSave = (breakTime: Object, timeZone: string) =>
	Object.keys(breakTime).reduce((result, key) => {
		const time = convertLocalToTimezone(
			`${today} ${_get(breakTime, [key])}`,
			timeZone
		);

		return {
			...result,
			[key]: from12To24Format(time),
		};
	}, {});

export const convertTimezoneToLocal = (
	dateString: string,
	timeZone: string
): string => {
	const state = store.getState();
	const clientTimeZone = _get(state, ['calendar', 'clientTimeZone']);

	if (!timeZone) return moment(dateString).format(MOMENT_FORMAT);
	if (!clientTimeZone)
		return moment.tz(dateString, timeZone).local().format(MOMENT_FORMAT);
	return moment
		.tz(dateString, timeZone)
		.tz(clientTimeZone)
		.format(MOMENT_FORMAT);
};

export const convertLocalToTimezone = (
	dateString: string,
	timeZone: string
): string => {
	const state = store.getState();
	const clientTimeZone = _get(state, ['calendar', 'clientTimeZone']);

	if (!timeZone) return moment(dateString).format(MOMENT_FORMAT);
	if (!clientTimeZone)
		return moment(dateString).tz(timeZone).format(MOMENT_FORMAT);
	return moment
		.tz(dateString, `${DATE_FORMAT} ${DISPLAY_TIME_FORMAT}`, clientTimeZone)
		.tz(timeZone)
		.format(MOMENT_FORMAT);
};

const isActivatedMoment = ({
	dateName,
	config,
}: Omit<TCheckAvailableMoment, 'currentMoment' | 'currentDate'>) =>
	_get(config, `${dateName}Active`) || false;

type TCheckBetweenMoment = {
	start: string | Moment;
	end: string | Moment;
	current: string | Moment;
};

const isMomentBetween = ({
	start,
	end,
	current,
}: TCheckBetweenMoment): boolean => {
	if (!start || !end || !current) return false;

	const startTime = moment(
		moment(start).format(TIME_24H_FORMAT),
		TIME_24H_FORMAT
	);
	const endTime = moment(moment(end).format(TIME_24H_FORMAT), TIME_24H_FORMAT);
	const currentTime = moment(
		moment(current).format(TIME_24H_FORMAT),
		TIME_24H_FORMAT
	);

	// Handle spanning days
	if (startTime.isAfter(endTime)) {
		endTime.add(1, 'days');

		if (currentTime.hour() <= 12) {
			currentTime.add(1, 'days');
		}
	}

	return currentTime.isSameOrAfter(startTime) && currentTime.isBefore(endTime);
};

const isAvailableMoment = ({
	config,
	currentMoment,
	currentDate: dateString,
	dateName,
}: TCheckAvailableMoment) => {
	const startDate = getTimeConfig({
		config,
		dateString,
		key: `${dateName}Start`,
	});
	const endDate = getTimeConfig({ config, dateString, key: `${dateName}End` });

	return isMomentBetween({
		start: startDate,
		end: endDate,
		current: currentMoment,
	});
};

const isMomentInBreaking = ({
	config,
	currentMoment,
	currentDate: dateString,
}: Omit<TCheckAvailableMoment, 'dateName'>) => {
	const breakStart = getTimeConfig({ config, dateString, key: 'breakStart' });
	const breakEnd = getTimeConfig({ config, dateString, key: 'breakEnd' });

	return isMomentBetween({
		start: breakStart,
		end: breakEnd,
		current: currentMoment,
	});
};

const isMomentInPast = (currentMoment: string | Moment) =>
	moment().diff(currentMoment, 'hours') > 0;

const mapDateToName = (dateString: string | Date) =>
	new Date(dateString)
		.toLocaleString('en-us', { weekday: 'short' })
		.toLowerCase();

type TIsCellActivateParams = {
	dateString: Date | undefined;
	config: Object;
};
export const isCellActive = ({
	dateString,
	config,
}: TIsCellActivateParams): boolean => {
	if (!dateString) return false;

	const dateName = mapDateToName(dateString);

	const currentMoment = moment(dateString);

	if (isMomentInPast(currentMoment)) return false;
	if (!isActivatedMoment({ dateName, config })) return false;

	const currentDate = currentMoment.format(DATE_FORMAT);

	const isAvailable = isAvailableMoment({
		config,
		currentMoment,
		currentDate,
		dateName,
	});
	if (!isAvailable) return false;

	const isBreakingMoment = isMomentInBreaking({
		config,
		currentMoment,
		currentDate,
	});
	return !isBreakingMoment;
};

type TDisplayTime = {
	displayDate: string;
	displayTime: string;
};
export const formatTime = ({
	startDate,
	endDate,
}: Pick<TAppointment, 'startDate' | 'endDate'>): TDisplayTime => ({
	displayDate: displayDate(startDate),
	displayTime: `${displayTime(startDate)} - ${displayTime(endDate)}`,
});

export const isBusyAppointment = (data: TAppointment): boolean => {
	const { services } = data;
	const busyAppointmentType = services.find(
		service => service.name.toLowerCase() === BUSY_APPOINTMENT_NAME
	);
	return Boolean(busyAppointmentType);
};

export const getTime = (date: Date | string | Object): string =>
	moment(date).format(TIME_24H_FORMAT);
export const getDate = (date: Date | string | Object): string =>
	moment(date).format(DATE_FORMAT);
export const displayDate = (date: Date | string | Object): string =>
	moment(date).format(DISPLAY_DATE_FORMAT);
export const displayTime = (date: Date | string | Object): string =>
	moment(date).format(DISPLAY_TIME_FORMAT);

export const toMinutes = (date: Object): number => {
	const mDate = moment(date);
	const h = mDate.hours();
	const m = mDate.minutes();
	return +(+h * 60 + +m);
};

export const DAY_SCALE_CELL_HEIGHT = 67;
export const CELL_HEIGHT = 48;

export const getCellsProp = (
	{ startCell, endCell }: TSelectingCells,
	slotLengthMinutes: number
): Object => {
	const start = toMinutes(startCell);
	const end = toMinutes(endCell);
	const duration = Math.abs((end - start) / slotLengthMinutes) + 1;

	const top =
		(Math.min(start, end) / slotLengthMinutes) * CELL_HEIGHT +
		DAY_SCALE_CELL_HEIGHT;
	const height = duration * CELL_HEIGHT;

	return { top, height };
};

export const formatCells = (
	{ startCell, endCell }: TSelectingCells,
	slotLengthMinutes: number
): { start: Moment; end: Moment } => {
	const mStart = moment(startCell);
	const mEnd = moment(endCell);
	let start = mStart;
	let end = mEnd;
	if (mStart.isAfter(mEnd)) {
		start = mEnd;
		end = mStart;
	}
	end = end.add(slotLengthMinutes / 60, 'h');

	return { start, end };
};

export const isElementContains = (
	id: string,
	target: EventTarget | null
): boolean => {
	if (!target) return false;
	const el = document.getElementById(id);
	if (el && el.contains(target as Node)) return true;
	return false;
};

const getAnswerValue = (answer: Object) => _get(answer, '0.string', '') || '';

export const getBookingInfo = (
	appointmentData: TAppointment
): { fullName: string; dre: string; email: string } => {
	const { answers } = appointmentData;
	const values = _groupBy(answers, answer => answer.code) as any;
	return {
		fullName: `${getAnswerValue(values.FNAME)} ${getAnswerValue(values.LNAME)}`,
		email: getAnswerValue(values.EMAIL),
		dre: getAnswerValue(values.DRE),
	};
};
export const convertAppointmentsTime = (
	appointments: Array<TAppointment>
): Array<TAppointment> =>
	appointments.map(appointment => {
		const timeZone = _get(appointment, 'timeZone');
		const rawStart = _get(appointment, 'rawStart');
		const rawEnd = _get(appointment, 'rawEnd');

		return {
			...appointment,
			startDate: convertTimezoneToLocal(rawStart, timeZone),
			endDate: convertTimezoneToLocal(rawEnd, timeZone),
		};
	});
