import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	collapseIcon: {
		fontSize: '1rem',
		marginTop: 'auto',
		marginBottom: 'auto',
	},
	collapseIconSub: {
		fontSize: '1rem',
		marginTop: 'auto',
		marginBottom: 'auto',
	},
	menuIcon: {
		marginTop: 'auto',
		marginBottom: 'auto',
	},
	listIcon: {
		minWidth: '18px',
		marginTop: 'auto',
		marginBottom: 'auto',
	},
	listCustomIcon: {
		marginTop: 'auto',
		marginBottom: 'auto',
	},
	listItemTypography: {
		marginTop: 'auto',
		marginBottom: 'auto',
	},
	listCustomIconSub: {
		width: '6px',
		height: '6px',
	},
	listCustomIconSubActive: {
		width: '8px',
		height: '8px',
	},
	listItem: {
		marginBottom: '5px',
		alignItems: 'flex-start',
	},
	listItemNoBack: {
		marginBottom: '5px',
		backgroundColor: 'transparent !important',
		paddingTop: '8px',
		paddingBottom: '8px',
		alignItems: 'flex-start',
	},
	subMenuCaption: {
		...theme.typography.subMenuCaption,
	},
	collapseWrapper: {
		position: 'relative',
		'&:after': {
			content: "''",
			position: 'absolute',
			left: '32px',
			top: 0,
			height: '100%',
			width: '1px',
			opacity: theme.palette.mode === 'dark' ? 0.2 : 1,
			background:
				theme.palette.mode === 'dark'
					? theme.palette.dark.light
					: theme.palette.primary.light,
		},
	},
}));
