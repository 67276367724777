import { createAuthLink, AUTH_TYPE } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import Auth from '@aws-amplify/auth';
import {
	getProperty,
	getProperties,
	listForms,
	listSellRequests,
	getOffers,
	getUserProfiles,
} from './cache';

import config from '../config';

const graphqlConfig = {
	url: config.awsAppSyncConfig.endpoint,
	region: config.awsAppSyncConfig.region,
	auth: {
		type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
		jwtToken: async () =>
			(await Auth.currentSession()).getIdToken().getJwtToken(),
	},
};

const client = new ApolloClient({
	link: ApolloLink.from([
		// @ts-ignore
		createAuthLink(graphqlConfig),
		// @ts-ignore
		createSubscriptionHandshakeLink(graphqlConfig),
	]),
	cache: new InMemoryCache({
		typePolicies: {
			Query: {
				fields: {
					getProperty,
					getProperties,
					listForms,
					listSellRequests,
					getOffers,
					users_GetUserProfiles: getUserProfiles,
				},
			},
		},
	}),
	defaultOptions: {
		watchQuery: {
			fetchPolicy: 'cache-and-network',
			errorPolicy: 'all',
		},
	},
	connectToDevTools: true,
});

export { client };
