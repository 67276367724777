import config from '../config';

export const formatCurrency = (value: number | bigint): string =>
	`${
		value
			? new Intl.NumberFormat(config.locate.country, {
				style: 'currency',
				currency: config.locate.currency,
				minimumFractionDigits: config.locate.currencyDecimal,
			}).format(value)
			: ''
	}`;

export const convertPhoneValue = (value: string) => {
	if (value && value.includes('+')) return value;
	return value ? `+${value}` : value;
};
