import { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import GuestGuard from "../utils/route-guard/GuestGuard";
import MinimalLayout from "../layout/MinimalLayout";
import NavMotion from "../layout/NavMotion";
import Loadable from '../ui-component/Loadable';

// -----------------------|| AUTH ROUTING ||-----------------------//

// login routing
const AuthLogin = Loadable(
	lazy(() => import('../views/pages/authentication/login'))
);

const LoginRoutes = () => {
	const location = useLocation();

	return (
		<Route path={['/login', '/completePassword']}>
			<MinimalLayout>
				<Switch location={location} key={location.pathname}>
					<NavMotion>
						<GuestGuard>
							<>
								<Route path='/login' component={AuthLogin} />
							</>
						</GuestGuard>
					</NavMotion>
				</Switch>
			</MinimalLayout>
		</Route>
	);
};

export default LoginRoutes;
