/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const formdata_GetSellerCounterOffer = /* GraphQL */ `
	query Formdata_GetSellerCounterOffer(
		$CounterOfferId: String!
		$FormId: String!
	) {
		formdata_GetSellerCounterOffer(
			CounterOfferId: $CounterOfferId
			FormId: $FormId
		) {
			Form {
				CategoryId
				CreatedAt
				CreatedBy
				FormId
				FormStatus
				FormTypeId
				Template
				Title
				UpdatedAt
				UpdatedBy
			}
			FormData {
				DataStatus
				DataValue
				FormId
				ItemId
				ItemType
			}
			FormProperty
		}
	}
`;
export const getForm = /* GraphQL */ `
	query GetForm($FormId: ID!) {
		getForm(FormId: $FormId) {
			CategoryId
			CreatedAt
			CreatedBy
			FormId
			FormStatus
			FormTypeId
			Template
			Title
			UpdatedAt
			UpdatedBy
		}
	}
`;
export const getFormCategory = /* GraphQL */ `
	query GetFormCategory($CategoryId: ID!) {
		getFormCategory(CategoryId: $CategoryId) {
			Active
			FormTypes {
				Active
				Forms {
					CategoryId
					CreatedAt
					CreatedBy
					FormId
					FormStatus
					FormTypeId
					Template
					Title
					UpdatedAt
					UpdatedBy
				}
				Name
				Order
				id
			}
			Forms {
				CategoryId
				CreatedAt
				CreatedBy
				FormId
				FormStatus
				FormTypeId
				Template
				Title
				UpdatedAt
				UpdatedBy
			}
			ItemType
			Title
			id
		}
	}
`;
export const getOffer = /* GraphQL */ `
	query GetOffer($OfferId: ID!) {
		getOffer(OfferId: $OfferId) {
			AdditionalBuyerInfo
			AdditionalBuyers
			AgentInfo {
				DRENumber
				Email
				FirstName
				LastName
				Name
				Phone
			}
			BuyerInfo {
				FirstName
				LastName
				MiddleName
				TitleName
			}
			BuyerType
			Contingencies {
				AllRemoved
				AnyAndAll
				FinanceContingency
				FinanceContingencyRemoved
				FinanceContingencyRemovedAt
				InspectionContingency
				InspectionContingencyRemoved
				InspectionContingencyRemovedAt
				SaleOfHomeContingency
				SaleOfHomeContingencyRemoved
				SaleOfHomeContingencyRemovedAt
			}
			CounterOffer {
				BuyerCounter {
					ClosingPeriod
					FinanceContingency
					Furniture
					FurnitureOther
					InspectionContingency
					OfferPrice
					Position
					RentBack
					RentBackDailyAmount
					RentBackDays
					RentBackInititated
					SaleOfHomeContingency
					SubmittedAt
				}
				CounterOfferId
				Forms {
					DataStatus
					FormGroup
					FormId
					FormTypeId
					Name
					Order
				}
				Position
				SellerCounter {
					ClosingPeriod
					FinanceContingency
					Furniture
					FurnitureOther
					InspectionContingency
					OfferPrice
					Position
					RentBack
					RentBackDailyAmount
					RentBackDays
					RentBackInititated
					SaleOfHomeContingency
					SubmittedAt
				}
			}
			CreatedAt
			CreatedBy
			Entity {
				EntityType
				IndividualOnBehalf
				Name
				TitleName
			}
			FirmInfo {
				CompanyName
				CoordinatorEmail
				CoordinatorFirstName
				CoordinatorLastName
				CoordinatorPhone
				DRENumber
			}
			Funding {
				DownPayment
				FicoScore
				LoanType
				PreapprovedForLoan
				PurchaseType
			}
			NileAgentId
			Offer {
				ClosingPeriod
				Furniture
				FurnitureOther
				OfferPrice
				SaleOfHomeContingency
				FinanceContingency
				FinanceContingencyDays
				InspectionContingency
				InspectionContingencyDays
				AppraisalContingency
				AppraisalContingencyDays
			}
			OfferId
			OfferNo
			Property {
				BuyerAgentFeePct
				CreatedAt
				CreatedBy
				DTPropertyId
				ListPriceRange {
					Max
					MaxFormatted
					Min
					MinFormatted
				}
				ListingAgentFeePct
				ListingTerminationDate
				NileAgentId
				PropertyId
				PropertyNo
				ReportStatus {
					TaxReportTaxResourceMessage
					TaxReportTaxResourceStatus
					TaxReportTaxResourceUpdatedAt
					TaxReportTotalViewMessage
					TaxReportTotalViewStatus
					TaxReportTotalViewUpdatedAt
				}
				SellerInfo {
					Email
					FirstName
					LastName
					Phone
				}
				StatusId
				UpdatedAt
				UpdatedBy
			}
			PropertyData {
				Bedrooms
				City
				County
				FullAddress
				FullBath
				LandUse
				LivingArea
				LotArea
				OwnerNames
				State
				StreetAddress
				VestingType
				YearBuilt
				Zip
			}
			PropertyId
			PropertyNo
			SCARReport {
				ObjectKey
				PresignedUrl
				UpdatedAt
			}
			SellerAccepted
			Step {
				allowed_actions {
					action
					form_action
					next_step_id
				}
				allowed_upload_file_types {
					allow_files_count
					allow_single_file_max_size_mb
					file_type
				}
				buyer_can_submit_offer
				display_on_advisor_portal
				display_on_seller_dashboard
				id
				messages {
					advisor
					buyer
					seller
				}
				name
				waiting_seller_to_act
			}
			StepId
			UpdatedAt
			UpdatedBy
			CounterOfferHistory {
				BuyerCounter {
					AppraisalContingency
					AppraisalContingencyDays
					SubmittedAt
					Status
					SaleOfHomeContingency
					RentBackInititated
					RentBackDays
					RentBackDailyAmount
					RentBack
					Position
					OfferPrice
					IsBestOffer
					InspectionContingency
					InspectionContingencyDays
					FurnitureOther
					Furniture
					FinanceContingency
					FinanceContingencyDays
					ClosingPeriod
				}
				SellerCounter {
					AppraisalContingency
					AppraisalContingencyDays
					ClosingPeriod
					FinanceContingency
					FinanceContingencyDays
					Furniture
					FurnitureOther
					InspectionContingency
					InspectionContingencyDays
					OfferPrice
					IsBestOffer
					Position
					RentBack
					RentBackDailyAmount
					RentBackDays
					RentBackInititated
					SaleOfHomeContingency
					Status
					SubmittedAt
				}
			}
		}
	}
`;
export const getOfferDocPutUrls = /* GraphQL */ `
	query GetOfferDocPutUrls(
		$DocumentType: String!
		$Documents: [FileItemInput]!
		$OfferId: ID!
	) {
		getOfferDocPutUrls(
			DocumentType: $DocumentType
			Documents: $Documents
			OfferId: $OfferId
		) {
			id
			url
		}
	}
`;
export const getOfferStep = /* GraphQL */ `
	query GetOfferStep($StepId: Int!) {
		getOfferStep(StepId: $StepId) {
			allowed_actions {
				action
				form_action
				next_step_id
			}
			allowed_upload_file_types {
				allow_files_count
				allow_single_file_max_size_mb
				file_type
			}
			buyer_can_submit_offer
			display_on_advisor_portal
			display_on_seller_dashboard
			id
			messages {
				advisor
				buyer
				seller
			}
			name
			waiting_seller_to_act
		}
	}
`;
export const getOffers = /* GraphQL */ `
	query GetOffers(
		$PageIndex: Int
		$PageSize: Int
		$Query: String
		$SortBy: [AWSJSON]
		$StepIds: [Int]
	) {
		getOffers(
			PageIndex: $PageIndex
			PageSize: $PageSize
			Query: $Query
			SortBy: $SortBy
			StepIds: $StepIds
		) {
			Count
			Items {
				AdditionalBuyerInfo
				AdditionalBuyers
				AgentInfo {
					DRENumber
					Email
					FirstName
					LastName
					Name
					Phone
				}
				BuyerInfo {
					FirstName
					LastName
					MiddleName
					TitleName
				}
				BuyerType
				CounterOffer {
					BuyerCounter {
						ClosingPeriod
						FinanceContingency
						Furniture
						FurnitureOther
						InspectionContingency
						OfferPrice
						Position
						RentBack
						RentBackDailyAmount
						RentBackDays
						RentBackInititated
						SaleOfHomeContingency
						SubmittedAt
					}
					CounterOfferId
					Forms {
						DataStatus
						FormGroup
						FormId
						FormTypeId
						Name
						Order
					}
					Position
					SellerCounter {
						ClosingPeriod
						FinanceContingency
						Furniture
						FurnitureOther
						InspectionContingency
						OfferPrice
						Position
						RentBack
						RentBackDailyAmount
						RentBackDays
						RentBackInititated
						SaleOfHomeContingency
						SubmittedAt
					}
				}
				CreatedAt
				CreatedBy
				Entity {
					EntityType
					IndividualOnBehalf
					Name
					TitleName
				}
				FirmInfo {
					CompanyName
					CoordinatorEmail
					CoordinatorFirstName
					CoordinatorLastName
					CoordinatorPhone
					DRENumber
				}
				Funding {
					DownPayment
					FicoScore
					LoanType
					PreapprovedForLoan
					PurchaseType
				}
				NileAgentId
				Offer {
					ClosingPeriod
					FinanceContingency
					Furniture
					FurnitureOther
					InspectionContingency
					OfferPrice
					SaleOfHomeContingency
				}
				OfferId
				OfferNo
				PropertyData {
					Bedrooms
					City
					County
					FullAddress
					FullBath
					LandUse
					LivingArea
					LotArea
					OwnerNames
					State
					StreetAddress
					VestingType
					YearBuilt
					Zip
				}
				PropertyId
				PropertyNo
				SellerAccepted
				Step {
					allowed_actions {
						action
						form_action
						next_step_id
					}
					allowed_upload_file_types {
						allow_files_count
						allow_single_file_max_size_mb
						file_type
					}
					buyer_can_submit_offer
					display_on_advisor_portal
					display_on_seller_dashboard
					id
					messages {
						advisor
						buyer
						seller
					}
					name
					waiting_seller_to_act
				}
				StepId
				UpdatedAt
				UpdatedBy
			}
			PageIndex
			PageSize
			Total
		}
	}
`;
export const getProperties = /* GraphQL */ `
	query GetProperties(
		$PageIndex: Int
		$PageSize: Int
		$Query: String
		$SortBy: [AWSJSON]
		$StatusIds: [Int]
	) {
		getProperties(
			PageIndex: $PageIndex
			PageSize: $PageSize
			Query: $Query
			SortBy: $SortBy
			StatusIds: $StatusIds
		) {
			Count
			Items {
				BuyerAgentFeePct
				CreatedAt
				CreatedBy
				DTPropertyId
				ExternalId
				ListPriceRange {
					Max
					MaxFormatted
					Min
					MinFormatted
				}
				ListingAgentFeePct
				ListingTerminationDate
				NewPropertyWizardStep
				PropertyData {
					Bedrooms
					City
					County
					FullAddress
					FullBath
					LandUse
					LivingArea
					LotArea
					OwnerNames
					State
					StreetAddress
					VestingOwnershipRight
					VestingTypeMapped
					VestingTypeOverride
					VestingTypeUsed
					YearBuilt
					YearBuiltDisp
					YearBuiltOverride
					YearBuiltUsed
					Zip
				}
				PropertyId
				PropertyNo
				SellerInfo {
					Email
					FirstName
					LastName
					Phone
				}
				StatusId
				UpdatedAt
				UpdatedBy
			}
			PageIndex
			PageSize
			Total
		}
	}
`;
export const getProperty = /* GraphQL */ `
	query GetProperty($PropertyId: ID!) {
		getProperty(PropertyId: $PropertyId) {
			AdditionalInfo
			BuyerAgentFeePct
			CalendarUrl
			CreatedAt
			CreatedBy
			DTPropertyId
			ExternalId
			HomeViewingCalendarProfileId
			ListPriceRange {
				Max
				MaxFormatted
				Min
				MinFormatted
			}
			ListingAgentFeePct
			ListingTerminationDate
			ListingTermsConditions
			NewPropertyWizardStep
			NileAgentId
			PropertyData {
				Bedrooms
				City
				County
				FullAddress
				FullBath
				LandUse
				LivingArea
				LotArea
				OwnerNames
				State
				StreetAddress
				VestingOwnershipRight
				VestingTypeMapped
				VestingTypeOverride
				VestingTypeUsed
				YearBuilt
				YearBuiltDisp
				YearBuiltOverride
				YearBuiltUsed
				Zip
			}
			PropertyFormDataStatuses {
				CategoryId
				FormDataCreatedAt
				FormDataStatus
				FormDataSubmitted
				FormDataUpdatedAt
				FormId
				FormTypeId
				Title
			}
			PropertyFormsSubmitted
			PropertyId
			PropertyNo
			PropertyReport {
				ObjectKey
				PresignedUrl
				UpdatedAt
			}
			ReportStatus {
				TaxReportTaxResourceMessage
				TaxReportTaxResourceStatus
				TaxReportTaxResourceUpdatedAt
				TaxReportTotalViewMessage
				TaxReportTotalViewStatus
				TaxReportTotalViewUpdatedAt
			}
			SellerInfo {
				Email
				FirstName
				LastName
				Phone
			}
			StatusId
			Thumbnail {
				file {
					path
				}
				id
				key
				name
				presignedUrl
				size
				type
			}
			UpdatedAt
			UpdatedBy
		}
	}
`;
export const getPropertyOffers = `
query GetPropertyOffers($PropertyId: ID!) {
		getProperty(PropertyId: $PropertyId) {
			PropertyId
			Offers {
				AdditionalBuyerInfo
				AdditionalBuyers
				AgentInfo {
					DRENumber
					Email
					FirstName
					LastName
					Name
					Phone
				}
				BuyerInfo {
					FirstName
					LastName
					MiddleName
					TitleName
				}
				BuyerType
				CounterOffer {
					BuyerCounter {
						ClosingPeriod
						FinanceContingency
						Furniture
						FurnitureOther
						InspectionContingency
						OfferPrice
						Position
						RentBack
						RentBackDailyAmount
						RentBackDays
						RentBackInititated
						SaleOfHomeContingency
						SubmittedAt
					}
					CounterOfferId
					Forms {
						DataStatus
						FormGroup
						FormId
						FormTypeId
						Name
						Order
					}
					Position
					SellerCounter {
						ClosingPeriod
						FinanceContingency
						Furniture
						FurnitureOther
						InspectionContingency
						OfferPrice
						Position
						RentBack
						RentBackDailyAmount
						RentBackDays
						RentBackInititated
						SaleOfHomeContingency
						SubmittedAt
					}
				}
				CreatedAt
				CreatedBy
				Entity {
					EntityType
					IndividualOnBehalf
					Name
					TitleName
				}
				FirmInfo {
					CompanyName
					CoordinatorEmail
					CoordinatorFirstName
					CoordinatorLastName
					CoordinatorPhone
					DRENumber
				}
				Funding {
					DownPayment
					FicoScore
					LoanType
					PreapprovedForLoan
					PurchaseType
				}
				NileAgentId
				Offer {
					ClosingPeriod
					FinanceContingency
					Furniture
					FurnitureOther
					InspectionContingency
					OfferPrice
					SaleOfHomeContingency
				}
				OfferId
				OfferNo
				PropertyData {
					Bedrooms
					City
					County
					FullAddress
					FullBath
					LandUse
					LivingArea
					LotArea
					OwnerNames
					State
					StreetAddress
					VestingType
					YearBuilt
					Zip
				}
				PropertyId
				PropertyNo
				SellerAccepted
				Step {
					allowed_actions {
						action
						form_action
						next_step_id
					}
					allowed_upload_file_types {
						allow_files_count
						allow_single_file_max_size_mb
						file_type
					}
					buyer_can_submit_offer
					display_on_advisor_portal
					display_on_seller_dashboard
					id
					messages {
						advisor
						buyer
						seller
					}
					name
					waiting_seller_to_act
				}
				StepId
				UpdatedAt
				UpdatedBy
			}
		}
	}
`;
export const getPropertyDocuments = /* GraphQL */ `
	query GetProperty($PropertyId: ID!) {
		getProperty(PropertyId: $PropertyId) {
			PropertyId
			Offers {
				OfferId
				OfferNo
			}
			Docs {
				DocPermissions
				DocScope
				DocStatus
				DocType
				EventTime
				File {
					name
					type
					size
				}
				FileId
				ItemType
				ObjectKey
				OfferId
				OfferNo
				PropertyId
			}
		}
	}
`;
export const getPropertyThumbnailPutUrl = /* GraphQL */ `
	query GetPropertyThumbnailPutUrl($Photos: [FileItemInput]!, $PropertyId: ID!) {
		getPropertyThumbnailPutUrl(Photos: $Photos, PropertyId: $PropertyId) {
			id
			url
		}
	}
`;
export const homeviewingcalendar_GetBookings = /* GraphQL */ `
	query Homeviewingcalendar_GetBookings(
		$IncludeProfile: Boolean!
		$JumpToDate: String
		$PropertyId: String!
	) {
		homeviewingcalendar_GetBookings(
			IncludeProfile: $IncludeProfile
			JumpToDate: $JumpToDate
			PropertyId: $PropertyId
		)
	}
`;
export const listFormCategories = /* GraphQL */ `
	query ListFormCategories {
		listFormCategories {
			Active
			FormTypes {
				Active
				Forms {
					CategoryId
					CreatedAt
					CreatedBy
					FormId
					FormStatus
					FormTypeId
					Template
					Title
					UpdatedAt
					UpdatedBy
				}
				Name
				Order
				id
			}
			Forms {
				CategoryId
				CreatedAt
				CreatedBy
				FormId
				FormStatus
				FormTypeId
				Template
				Title
				UpdatedAt
				UpdatedBy
			}
			ItemType
			Title
			id
		}
	}
`;
export const listForms = /* GraphQL */ `
	query ListForms($limit: Int, $nextToken: String) {
		listForms(limit: $limit, nextToken: $nextToken) {
			items {
				CategoryId
				CreatedAt
				CreatedBy
				FormId
				FormStatus
				FormTypeId
				Template
				Title
				UpdatedAt
				UpdatedBy
			}
			nextToken
		}
	}
`;
export const listOfferSteps = /* GraphQL */ `
	query ListOfferSteps {
		listOfferSteps {
			allowed_actions {
				action
				form_action
				next_step_id
			}
			allowed_upload_file_types {
				allow_files_count
				allow_single_file_max_size_mb
				file_type
			}
			buyer_can_submit_offer
			display_on_advisor_portal
			display_on_seller_dashboard
			id
			messages {
				advisor
				buyer
				seller
			}
			name
			waiting_seller_to_act
		}
	}
`;
export const listOffers = /* GraphQL */ `
	query ListOffers($limit: Int, $nextToken: String) {
		listOffers(limit: $limit, nextToken: $nextToken) {
			items {
				AdditionalBuyerInfo
				AdditionalBuyers
				AgentInfo {
					DRENumber
					Email
					FirstName
					LastName
					Name
					Phone
				}
				BuyerInfo {
					FirstName
					LastName
					MiddleName
					TitleName
				}
				BuyerType
				Contingencies {
					AllRemoved
					AnyAndAll
					FinanceContingency
					FinanceContingencyRemoved
					FinanceContingencyRemovedAt
					InspectionContingency
					InspectionContingencyRemoved
					InspectionContingencyRemovedAt
					SaleOfHomeContingency
					SaleOfHomeContingencyRemoved
					SaleOfHomeContingencyRemovedAt
				}
				CounterOffer {
					BuyerCounter {
						ClosingPeriod
						FinanceContingency
						Furniture
						FurnitureOther
						InspectionContingency
						OfferPrice
						Position
						RentBack
						RentBackDailyAmount
						RentBackDays
						RentBackInititated
						SaleOfHomeContingency
						SubmittedAt
					}
					CounterOfferId
					Forms {
						DataStatus
						FormGroup
						FormId
						FormTypeId
						Name
						Order
					}
					Position
					SellerCounter {
						ClosingPeriod
						FinanceContingency
						Furniture
						FurnitureOther
						InspectionContingency
						OfferPrice
						Position
						RentBack
						RentBackDailyAmount
						RentBackDays
						RentBackInititated
						SaleOfHomeContingency
						SubmittedAt
					}
				}
				CreatedAt
				CreatedBy
				Entity {
					EntityType
					IndividualOnBehalf
					Name
					TitleName
				}
				FirmInfo {
					CompanyName
					CoordinatorEmail
					CoordinatorFirstName
					CoordinatorLastName
					CoordinatorPhone
					DRENumber
				}
				Funding {
					DownPayment
					FicoScore
					LoanType
					PreapprovedForLoan
					PurchaseType
				}
				NileAgentId
				Offer {
					ClosingPeriod
					FinanceContingency
					Furniture
					FurnitureOther
					InspectionContingency
					OfferPrice
					SaleOfHomeContingency
				}
				OfferId
				OfferNo
				Property {
					BuyerAgentFeePct
					CreatedAt
					CreatedBy
					DTPropertyId
					ListPriceRange {
						Max
						MaxFormatted
						Min
						MinFormatted
					}
					ListingAgentFeePct
					ListingTerminationDate
					NileAgentId
					PropertyId
					PropertyNo
					ReportStatus {
						TaxReportTaxResourceMessage
						TaxReportTaxResourceStatus
						TaxReportTaxResourceUpdatedAt
						TaxReportTotalViewMessage
						TaxReportTotalViewStatus
						TaxReportTotalViewUpdatedAt
					}
					SellerInfo {
						Email
						FirstName
						LastName
						Phone
					}
					StatusId
					UpdatedAt
					UpdatedBy
				}
				PropertyData {
					Bedrooms
					City
					County
					FullAddress
					FullBath
					LandUse
					LivingArea
					LotArea
					OwnerNames
					State
					StreetAddress
					VestingType
					YearBuilt
					Zip
				}
				PropertyId
				PropertyNo
				SCARReport {
					ObjectKey
					PresignedUrl
					UpdatedAt
				}
				SellerAccepted
				Step {
					allowed_actions {
						action
						form_action
						next_step_id
					}
					allowed_upload_file_types {
						allow_files_count
						allow_single_file_max_size_mb
						file_type
					}
					buyer_can_submit_offer
					display_on_advisor_portal
					display_on_seller_dashboard
					id
					messages {
						advisor
						buyer
						seller
					}
					name
					waiting_seller_to_act
				}
				StepId
				UpdatedAt
				UpdatedBy
			}
			nextToken
		}
	}
`;
export const listProperties = /* GraphQL */ `
	query ListProperties($limit: Int, $nextToken: String) {
		listProperties(limit: $limit, nextToken: $nextToken) {
			items {
				AdditionalInfo
				BuyerAgentFeePct
				CalendarUrl
				CreatedAt
				CreatedBy
				DTPropertyId
				ExternalId
				HomeViewingCalendarProfileId
				ListPriceRange {
					Max
					MaxFormatted
					Min
					MinFormatted
				}
				ListingAgentFeePct
				ListingTerminationDate
				ListingTermsConditions
				NewPropertyWizardStep
				NileAgentId
				Offers {
					AdditionalBuyerInfo
					AdditionalBuyers
					AgentInfo {
						DRENumber
						Email
						FirstName
						LastName
						Name
						Phone
					}
					BuyerInfo {
						FirstName
						LastName
						MiddleName
						TitleName
					}
					BuyerType
					CounterOffer {
						BuyerCounter {
							ClosingPeriod
							FinanceContingency
							Furniture
							FurnitureOther
							InspectionContingency
							OfferPrice
							Position
							RentBack
							RentBackDailyAmount
							RentBackDays
							RentBackInititated
							SaleOfHomeContingency
							SubmittedAt
						}
						CounterOfferId
						Forms {
							DataStatus
							FormGroup
							FormId
							FormTypeId
							Name
							Order
						}
						Position
						SellerCounter {
							ClosingPeriod
							FinanceContingency
							Furniture
							FurnitureOther
							InspectionContingency
							OfferPrice
							Position
							RentBack
							RentBackDailyAmount
							RentBackDays
							RentBackInititated
							SaleOfHomeContingency
							SubmittedAt
						}
					}
					CreatedAt
					CreatedBy
					Entity {
						EntityType
						IndividualOnBehalf
						Name
						TitleName
					}
					FirmInfo {
						CompanyName
						CoordinatorEmail
						CoordinatorFirstName
						CoordinatorLastName
						CoordinatorPhone
						DRENumber
					}
					Funding {
						DownPayment
						FicoScore
						LoanType
						PreapprovedForLoan
						PurchaseType
					}
					NileAgentId
					Offer {
						ClosingPeriod
						FinanceContingency
						Furniture
						FurnitureOther
						InspectionContingency
						OfferPrice
						SaleOfHomeContingency
					}
					OfferId
					OfferNo
					PropertyData {
						Bedrooms
						City
						County
						FullAddress
						FullBath
						LandUse
						LivingArea
						LotArea
						OwnerNames
						State
						StreetAddress
						VestingType
						YearBuilt
						Zip
					}
					PropertyId
					PropertyNo
					SellerAccepted
					Step {
						allowed_actions {
							action
							form_action
							next_step_id
						}
						allowed_upload_file_types {
							allow_files_count
							allow_single_file_max_size_mb
							file_type
						}
						buyer_can_submit_offer
						display_on_advisor_portal
						display_on_seller_dashboard
						id
						messages {
							advisor
							buyer
							seller
						}
						name
						waiting_seller_to_act
					}
					StepId
					UpdatedAt
					UpdatedBy
				}
				PropertyData {
					Bedrooms
					City
					County
					FullAddress
					FullBath
					LandUse
					LivingArea
					LotArea
					OwnerNames
					State
					StreetAddress
					VestingOwnershipRight
					VestingTypeMapped
					VestingTypeOverride
					VestingTypeUsed
					YearBuilt
					YearBuiltDisp
					YearBuiltOverride
					YearBuiltUsed
					Zip
				}
				PropertyFormDataStatuses {
					CategoryId
					FormDataCreatedAt
					FormDataStatus
					FormDataSubmitted
					FormDataUpdatedAt
					FormId
					FormTypeId
					Title
				}
				PropertyFormsSubmitted
				PropertyId
				PropertyNo
				PropertyReport {
					ObjectKey
					PresignedUrl
					UpdatedAt
				}
				ReportStatus {
					TaxReportTaxResourceMessage
					TaxReportTaxResourceStatus
					TaxReportTaxResourceUpdatedAt
					TaxReportTotalViewMessage
					TaxReportTotalViewStatus
					TaxReportTotalViewUpdatedAt
				}
				SellerInfo {
					Email
					FirstName
					LastName
					Phone
				}
				StatusId
				Thumbnail {
					file {
						path
					}
					id
					key
					name
					presignedUrl
					size
					type
				}
				UpdatedAt
				UpdatedBy
			}
			nextToken
		}
	}
`;
export const listPropertyStatuses = /* GraphQL */ `
	query ListPropertyStatuses {
		listPropertyStatuses {
			Desc
			Id
			Name
			ViewableTo
		}
	}
`;
export const listSupportedVestingTypes = /* GraphQL */ `
	query ListSupportedVestingTypes {
		listSupportedVestingTypes
	}
`;
export const searchDataTreeProperties = /* GraphQL */ `
	query SearchDataTreeProperties($SearchText: String!) {
		searchDataTreeProperties(SearchText: $SearchText) {
			Count
			Properties {
				City
				County
				Owner
				PropertyId
				State
				StreetAddress
				Zip
			}
		}
	}
`;
export const searchOffers = /* GraphQL */ `
	query SearchOffers($SearchText: String!, $StepIds: [Int]) {
		searchOffers(SearchText: $SearchText, StepIds: $StepIds) {
			AdditionalBuyerInfo
			AdditionalBuyers
			AgentInfo {
				DRENumber
				Email
				FirstName
				LastName
				Name
				Phone
			}
			BuyerInfo {
				FirstName
				LastName
				MiddleName
				TitleName
			}
			BuyerType
			Contingencies {
				AllRemoved
				AnyAndAll
				FinanceContingency
				FinanceContingencyRemoved
				FinanceContingencyRemovedAt
				InspectionContingency
				InspectionContingencyRemoved
				InspectionContingencyRemovedAt
				SaleOfHomeContingency
				SaleOfHomeContingencyRemoved
				SaleOfHomeContingencyRemovedAt
			}
			CounterOffer {
				BuyerCounter {
					ClosingPeriod
					FinanceContingency
					Furniture
					FurnitureOther
					InspectionContingency
					OfferPrice
					Position
					RentBack
					RentBackDailyAmount
					RentBackDays
					RentBackInititated
					SaleOfHomeContingency
					SubmittedAt
				}
				CounterOfferId
				Forms {
					DataStatus
					FormGroup
					FormId
					FormTypeId
					Name
					Order
				}
				Position
				SellerCounter {
					ClosingPeriod
					FinanceContingency
					Furniture
					FurnitureOther
					InspectionContingency
					OfferPrice
					Position
					RentBack
					RentBackDailyAmount
					RentBackDays
					RentBackInititated
					SaleOfHomeContingency
					SubmittedAt
				}
			}
			CreatedAt
			CreatedBy
			Entity {
				EntityType
				IndividualOnBehalf
				Name
				TitleName
			}
			FirmInfo {
				CompanyName
				CoordinatorEmail
				CoordinatorFirstName
				CoordinatorLastName
				CoordinatorPhone
				DRENumber
			}
			Funding {
				DownPayment
				FicoScore
				LoanType
				PreapprovedForLoan
				PurchaseType
			}
			NileAgentId
			Offer {
				ClosingPeriod
				FinanceContingency
				Furniture
				FurnitureOther
				InspectionContingency
				OfferPrice
				SaleOfHomeContingency
			}
			OfferId
			OfferNo
			Property {
				BuyerAgentFeePct
				CreatedAt
				CreatedBy
				DTPropertyId
				ListPriceRange {
					Max
					MaxFormatted
					Min
					MinFormatted
				}
				ListingAgentFeePct
				ListingTerminationDate
				NileAgentId
				PropertyId
				PropertyNo
				ReportStatus {
					TaxReportTaxResourceMessage
					TaxReportTaxResourceStatus
					TaxReportTaxResourceUpdatedAt
					TaxReportTotalViewMessage
					TaxReportTotalViewStatus
					TaxReportTotalViewUpdatedAt
				}
				SellerInfo {
					Email
					FirstName
					LastName
					Phone
				}
				StatusId
				UpdatedAt
				UpdatedBy
			}
			PropertyData {
				Bedrooms
				City
				County
				FullAddress
				FullBath
				LandUse
				LivingArea
				LotArea
				OwnerNames
				State
				StreetAddress
				VestingType
				YearBuilt
				Zip
			}
			PropertyId
			PropertyNo
			SCARReport {
				ObjectKey
				PresignedUrl
				UpdatedAt
			}
			SellerAccepted
			Step {
				allowed_actions {
					action
					form_action
					next_step_id
				}
				allowed_upload_file_types {
					allow_files_count
					allow_single_file_max_size_mb
					file_type
				}
				buyer_can_submit_offer
				display_on_advisor_portal
				display_on_seller_dashboard
				id
				messages {
					advisor
					buyer
					seller
				}
				name
				waiting_seller_to_act
			}
			StepId
			UpdatedAt
			UpdatedBy
		}
	}
`;
export const searchProperties = /* GraphQL */ `
	query SearchProperties($SearchText: String!, $StatusIds: [Int]) {
		searchProperties(SearchText: $SearchText, StatusIds: $StatusIds) {
			AdditionalInfo
			BuyerAgentFeePct
			CalendarUrl
			CreatedAt
			CreatedBy
			DTPropertyId
			ExternalId
			HomeViewingCalendarProfileId
			ListPriceRange {
				Max
				MaxFormatted
				Min
				MinFormatted
			}
			ListingAgentFeePct
			ListingTerminationDate
			ListingTermsConditions
			NewPropertyWizardStep
			NileAgentId
			Offers {
				AdditionalBuyerInfo
				AdditionalBuyers
				AgentInfo {
					DRENumber
					Email
					FirstName
					LastName
					Name
					Phone
				}
				BuyerInfo {
					FirstName
					LastName
					MiddleName
					TitleName
				}
				BuyerType
				CounterOffer {
					BuyerCounter {
						ClosingPeriod
						FinanceContingency
						Furniture
						FurnitureOther
						InspectionContingency
						OfferPrice
						Position
						RentBack
						RentBackDailyAmount
						RentBackDays
						RentBackInititated
						SaleOfHomeContingency
						SubmittedAt
					}
					CounterOfferId
					Forms {
						DataStatus
						FormGroup
						FormId
						FormTypeId
						Name
						Order
					}
					Position
					SellerCounter {
						ClosingPeriod
						FinanceContingency
						Furniture
						FurnitureOther
						InspectionContingency
						OfferPrice
						Position
						RentBack
						RentBackDailyAmount
						RentBackDays
						RentBackInititated
						SaleOfHomeContingency
						SubmittedAt
					}
				}
				CreatedAt
				CreatedBy
				Entity {
					EntityType
					IndividualOnBehalf
					Name
					TitleName
				}
				FirmInfo {
					CompanyName
					CoordinatorEmail
					CoordinatorFirstName
					CoordinatorLastName
					CoordinatorPhone
					DRENumber
				}
				Funding {
					DownPayment
					FicoScore
					LoanType
					PreapprovedForLoan
					PurchaseType
				}
				NileAgentId
				Offer {
					ClosingPeriod
					FinanceContingency
					Furniture
					FurnitureOther
					InspectionContingency
					OfferPrice
					SaleOfHomeContingency
				}
				OfferId
				OfferNo
				PropertyData {
					Bedrooms
					City
					County
					FullAddress
					FullBath
					LandUse
					LivingArea
					LotArea
					OwnerNames
					State
					StreetAddress
					VestingType
					YearBuilt
					Zip
				}
				PropertyId
				PropertyNo
				SellerAccepted
				Step {
					allowed_actions {
						action
						form_action
						next_step_id
					}
					allowed_upload_file_types {
						allow_files_count
						allow_single_file_max_size_mb
						file_type
					}
					buyer_can_submit_offer
					display_on_advisor_portal
					display_on_seller_dashboard
					id
					messages {
						advisor
						buyer
						seller
					}
					name
					waiting_seller_to_act
				}
				StepId
				UpdatedAt
				UpdatedBy
			}
			PropertyData {
				Bedrooms
				City
				County
				FullAddress
				FullBath
				LandUse
				LivingArea
				LotArea
				OwnerNames
				State
				StreetAddress
				VestingOwnershipRight
				VestingTypeMapped
				VestingTypeOverride
				VestingTypeUsed
				YearBuilt
				YearBuiltDisp
				YearBuiltOverride
				YearBuiltUsed
				Zip
			}
			PropertyFormDataStatuses {
				CategoryId
				FormDataCreatedAt
				FormDataStatus
				FormDataSubmitted
				FormDataUpdatedAt
				FormId
				FormTypeId
				Title
			}
			PropertyFormsSubmitted
			PropertyId
			PropertyNo
			PropertyReport {
				ObjectKey
				PresignedUrl
				UpdatedAt
			}
			ReportStatus {
				TaxReportTaxResourceMessage
				TaxReportTaxResourceStatus
				TaxReportTaxResourceUpdatedAt
				TaxReportTotalViewMessage
				TaxReportTotalViewStatus
				TaxReportTotalViewUpdatedAt
			}
			SellerInfo {
				Email
				FirstName
				LastName
				Phone
			}
			StatusId
			Thumbnail {
				file {
					path
				}
				id
				key
				name
				presignedUrl
				size
				type
			}
			UpdatedAt
			UpdatedBy
		}
	}
`;
export const users_GetMyUserProfile = /* GraphQL */ `
	query Users_GetMyUserProfile {
		users_GetMyUserProfile {
			CreatedAt
			CreatedBy
			Email
			Enabled
			FirstName
			LastName
			Phone
			UpdatedAt
			UpdatedBy
			UserGroups {
				Description
				GroupName
			}
			UserId
			UserStatus
		}
	}
`;
export const users_GetUserProfile = /* GraphQL */ `
	query Users_GetUserProfile($UserId: String!) {
		users_GetUserProfile(UserId: $UserId) {
			CreatedAt
			CreatedBy
			Email
			Enabled
			FirstName
			LastName
			Phone
			UpdatedAt
			UpdatedBy
			UserGroups {
				Description
				GroupName
			}
			UserId
			UserStatus
		}
	}
`;
export const users_GetUserProfiles = /* GraphQL */ `
	query Users_GetUserProfiles(
		$Enabled: Boolean
		$PageIndex: Int
		$PageSize: Int
		$Query: String
		$SortBy: [AWSJSON]
		$UserGroups: [String]
	) {
		users_GetUserProfiles(
			Enabled: $Enabled
			PageIndex: $PageIndex
			PageSize: $PageSize
			Query: $Query
			SortBy: $SortBy
			UserGroups: $UserGroups
		) {
			Count
			Items {
				CreatedAt
				CreatedBy
				Email
				Enabled
				FirstName
				LastName
				Phone
				UpdatedAt
				UpdatedBy
				UserGroups {
					Description
					GroupName
				}
				UserId
				UserStatus
			}
			PageIndex
			PageSize
			Total
		}
	}
`;
export const users_Permissions = /* GraphQL */ `
	query Users_Permissions {
		users_Permissions {
			CanCreateForms
			CanCreateProperties
			CanDeleteForms
			CanDeleteProperties
			CanManageUsers
			CanUpdateForms
			CanUpdateOffers
			CanUpdateProperties
			CanViewForms
			CanViewOffers
			CanViewProperties
			UserGroup {
				Description
				GroupName
			}
		}
	}
`;
export const users_UserGroups = /* GraphQL */ `
	query Users_UserGroups {
		users_UserGroups {
			Description
			GroupName
		}
	}
`;
