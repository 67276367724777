/* eslint-disable import/prefer-default-export */

import { FieldPolicy } from '@apollo/client';
import { TSellRequests } from '../../types';

export const listSellRequests: FieldPolicy = {
	keyArgs: false,
	merge<T extends TSellRequests>(existing: T | undefined, incoming: T) {
		if (!existing) return incoming;

		return {
			items: [...existing.items, ...incoming.items],
			nextToken: incoming.nextToken,
		};
	},
};
