import { ApolloCache, FetchResult, FieldPolicy } from '@apollo/client';
import _uniqBy from 'lodash.uniqby';
import _get from 'lodash.get';
import _isEmpty from 'lodash.isempty';
import { TUser, TGetUsers } from '../../types';

type KeyObject = {
	[key: string]: string;
};

const filterUserProfileItems = (items: Array<KeyObject>, userId: string) =>
	items.filter(item => item.UserId !== userId);

export const getUserProfiles: FieldPolicy = {
	keyArgs: ['Query', 'Enabled', 'UserGroups'],
	merge<T extends TGetUsers>(existing: T | undefined, incoming: T) {
		if (!existing) return incoming;

		const newItems = _uniqBy([...existing.Items, ...incoming.Items], 'UserId');

		return {
			Items: newItems,
			Count: existing.Count + incoming.Count,
			Total: incoming.Total,
			PageIndex: incoming.PageIndex,
			PageSize: incoming.PageSize,
		};
	},
};

export const createUserCache = (
	cache: ApolloCache<any>,
	result: FetchResult<TUser>
) => {
	cache.modify({
		fields: {
			users_GetUserProfiles(existingUsers: TGetUsers) {
				const newUser = _get(result, ['data', 'users_CreateUser'], {});
				if (_isEmpty(newUser)) return existingUsers;
				const { Items } = existingUsers;
				return { ...existingUsers, Items: [...Items, newUser] };
			},
		},
	});
};

export const updateUserCache = (
	cache: ApolloCache<any>,
	result: FetchResult<TUser>
) => {
	cache.modify({
		fields: {
			users_GetUserProfiles(existingUsers: TGetUsers) {
				const updatedUser = _get(result, ['data', 'users_UpdateUserProfile'], {});
				const userId = _get(updatedUser, 'UserId');
				if (_isEmpty(updatedUser)) return existingUsers;

				const { Items: items } = existingUsers;
				const filteredItems = filterUserProfileItems(items, userId);
				return { ...existingUsers, Items: [...filteredItems, updatedUser] };
			},
		},
	});
};

export const updateEnableUserCache = (
	cache: ApolloCache<any>,
	result: FetchResult<TUser>
) => {
	cache.modify({
		fields: {
			users_GetUserProfiles(existingUsers: TGetUsers) {
				const updatedUser = _get(result, ['data', 'users_EnableUser'], {});
				const userId = _get(updatedUser, 'UserId');
				if (_isEmpty(updatedUser)) return existingUsers;

				const { Items: items } = existingUsers;
				const filteredItems = filterUserProfileItems(items, userId);
				return { ...existingUsers, Items: [...filteredItems, updatedUser] };
			},
		},
	});
};

export const updateDisableUserCache = (
	cache: ApolloCache<any>,
	result: FetchResult<TUser>
) => {
	cache.modify({
		fields: {
			users_GetUserProfiles(existingUsers: TGetUsers) {
				const updatedUser = _get(result, ['data', 'users_DisableUser'], {});
				if (_isEmpty(updatedUser)) return existingUsers;

				const { Items: items } = existingUsers;
				const filteredItems = items.filter(
					item => item.UserId !== updatedUser.UserId
				);
				return { ...existingUsers, Items: [...filteredItems, updatedUser] };
			},
		},
	});
};
