import { STATUS } from './components/Status/config';

export const APPROVED_STATUS = 'Approved';
export const SUBMITTED_STATUS = 'Submitted';
export const IN_PROGRESS_STATUS = 'In Progress';

export const TERMINATION_DATE_FORMAT = 'MM/dd/yyyy';
export const STATUS_CONFIRMATION_MESSAGES = {
	[STATUS.HOME_PREPARATION]:
		'Please make sure the following items have been finished:\n' +
		'- All Seller Disclosure Modules are submitted and reviewed\n' +
		'- Escrow Number is inserted\n' +
		'- Agent Visual Inspection Disclosure is submitted\n' +
		'- All required Onboarding documents are uploaded\n' +
		'\n' +
		'Are you sure you want to move the Property status to Home Preparation?',
	[STATUS.ON_CONTRACT]:
		'This action will send this property to NILE Property Listing page and Buyer/Buyer Agent can start bidding on it. Please make sure home prepration is finished.\n' +
		'\n' +
		'Are you sure you want to move the Property status to On Market?',
	[STATUS.PENDING]:
		'Please make sure that all contingencies have been released by the Buyer/Buyer Agent\n' +
		'\n' +
		'Are you sure you want to move the Property status to Pending?',
};

export const STATUS_BACK_MESSAGES = {
	[STATUS.HOME_PREPARATION]:
		'This would update the status tracker to Prepare for Market and take the property off listing page. Are you sure you want to do this?',
};

export { STATUS };
