import { FormattedMessage } from 'react-intl';
import { IconFiles } from '@tabler/icons';
import { actions, objectTargets } from '../helpers';
import { PATHS } from '../routes/path';

// constant
const icons = {
	IconFiles,
};

export const offers = {
	id: 'offers',
	title: <FormattedMessage id='offers' />,
	type: 'collapse',
	icon: icons.IconFiles,
	url: PATHS.OFFERS,
	action: actions.view,
	objectTarget: objectTargets.offers,
	children: [
		{
			id: 'offer-list',
			title: <FormattedMessage id='list' />,
			type: 'item',
			url: PATHS.OFFERS,
			action: actions.view,
			objectTarget: objectTargets.offers,
		},
	],
};
