import { FormattedMessage } from 'react-intl';
import { IconNotes } from '@tabler/icons';
import { PATHS } from '../routes/path';
import { actions, objectTargets } from '../helpers';

const icons = {
	IconNotes,
};

export const forms = {
	id: 'forms',
	title: <FormattedMessage id='forms' />,
	type: 'collapse',
	icon: icons.IconNotes,
	url: PATHS.FORMS,
	action: actions.view,
	objectTarget: objectTargets.forms,
	children: [
		{
			id: 'form-list',
			title: <FormattedMessage id='list' />,
			type: 'item',
			url: PATHS.FORMS,
			action: actions.view,
			objectTarget: objectTargets.forms,
		},
		{
			id: 'form-add',
			title: <FormattedMessage id='add' />,
			type: 'item',
			url: PATHS.FORM_ADD,
			action: actions.create,
			objectTarget: objectTargets.forms,
		},
	],
};
