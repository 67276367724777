export const CALENDAR_ACTIONS = {
	setConfig: 'SET_CONFIG',
	setAvailability: 'SET_AVAILABILITY',
	setBreakTime: 'SET_BREAK_TIME',
	setStartOfWeek: 'SET_START_OF_WEEK',
	setEndOfWeek: 'SET_END_OF_WEEK',
	setError: 'SET_ERROR',
	setIsLoading: 'SET_IS_LOADING',
	setAppointments: 'SET_APPOINTMENTS',
	addAppointments: 'ADD_APPOINTMENTS',
	setProperty: 'SET_PROPERTY',
	beginSelectingCell: 'BEGIN_SELECTING_CELL',
	stopSelectingCell: 'STOP_SELECTING_CELL',
	addSelectedCell: 'ADD_SELECTED_CELL',
	setClientTimeZone: 'SET_CLIENT_TIME_ZONE',
};
