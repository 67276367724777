/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdatePropertyReportStatus = /* GraphQL */ `
	subscription OnUpdatePropertyReportStatus($PropertyId: ID!) {
		onUpdatePropertyReportStatus(PropertyId: $PropertyId) {
			DTPropertyId
			PropertyId
			TaxReportTaxResourceMessage
			TaxReportTaxResourceStatus
			TaxReportTaxResourceUpdatedAt
			TaxReportTotalViewMessage
			TaxReportTotalViewStatus
			TaxReportTotalViewUpdatedAt
		}
	}
`;

export const onUpdateDocItem = `
	subscription OnUpdateDocItem($PropertyId: ID!) {
		docs_onUpdateDocItem(PropertyId: $PropertyId) {
			EventName
			DocPermissions
			DocScope
			DocStatus
			DocType
			EventTime
			File {
				name
				type
				size
			}
			FileId
			ItemType
			ObjectKey
			OfferId
			OfferNo
			PropertyId
		}
	}
`;
