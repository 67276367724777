import _get from 'lodash.get';
import { KeyedObject, ArrangementOrder, HeadCell } from '../types';

const ORDER: { [x: string]: ArrangementOrder } = {
	ASC: 'asc',
	DESC: 'desc',
};

const descendingComparator = (
	a: KeyedObject,
	b: KeyedObject,
	callback: (o: KeyedObject) => string | number
) => {
	const getValue = (obj: KeyedObject) =>
		typeof callback === 'string' ? _get(obj, callback) : callback(obj);

	const valueA = getValue(a) || '';
	const valueB = getValue(b) || '';

	if (typeof valueA === 'string' && typeof valueB === 'string') {
		return valueB.localeCompare(valueA);
	}

	if (valueB < valueA) {
		return -1;
	}
	if (valueB > valueA) {
		return 1;
	}
	return 0;
};

const getComparator = (
	order: ArrangementOrder,
	orderBy: HeadCell<any> | undefined
) => {
	if (!orderBy) return () => 0;
	const { id, pathFn } = orderBy;
	const callback = pathFn || (id as any);
	return order === ORDER.DESC
		? (a: KeyedObject, b: KeyedObject) => descendingComparator(a, b, callback)
		: (a: KeyedObject, b: KeyedObject) => -descendingComparator(a, b, callback);
};

const stableSort = <T>(
	array: T[],
	comparator: (a: KeyedObject, b: KeyedObject) => number
): T[] => {
	const stabilizedThis = array.map((el: T, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0] as T, b[0] as T);
		if (order !== 0) return order;
		return (a[1] as number) - (b[1] as number);
	});
	return stabilizedThis.map(([obj]) => obj as T);
};

function getHeadCellById<T>(
	id: string,
	headCells: HeadCell<T>[]
): HeadCell<T> | undefined {
	return headCells.find(headCell => headCell.id === id);
}

export {
	descendingComparator,
	getComparator,
	stableSort,
	getHeadCellById,
	ORDER,
};
