import _get from 'lodash.get';
import _isEmpty from 'lodash.isempty';
import _has from 'lodash.has';
import _invert from 'lodash.invert';
import type { ReactSurveyModel } from 'survey-react';
import {
	SURVEYJS_MODE,
	SURVEYJS_STATUS,
	FORM_TYPE,
} from '../views/application/components/FormBuilder/Constants';
import { uploadFile } from '../services/S3';
import {
	TTemplate,
	TFormData,
	TElements,
	TTemplateElement,
	TTemplateElements,
	TQuestion,
	TUploadS3Files,
	TUploadFile,
} from '../types';
import { generateFiles } from './file';

export const isFormInDisplayMode = (mode: string) =>
	mode === SURVEYJS_MODE.display;

export const isIterable = (obj: Object) => Symbol.iterator in Object(obj);

export const exportDataByTemplateElements = (
	templateElements: TTemplateElements
) => {
	if (templateElements && isIterable(templateElements)) {
		return templateElements.reduce(
			(accumulator: Object, item: TTemplateElement) => {
				const { name, questionId } = item;
				return {
					...accumulator,
					[name]: questionId,
				};
			},
			{}
		);
	}
	return {};
};

export const exportDataByElements = (elements: TElements): TFormData => {
	if (elements && isIterable(elements)) {
		return elements.reduce((accumulator, item) => {
			const questionName = _get(item, 'name');
			const questionId = _get(item, 'questionId');
			const templateElements = _get(item, 'templateElements');
			const elements = _get(item, 'elements');
			const nameAndQuestionIdInTemplate =
				exportDataByTemplateElements(templateElements);
			const nameAndQuestionIdInElement = exportDataByElements(elements);
			return {
				...accumulator,
				...nameAndQuestionIdInTemplate,
				...nameAndQuestionIdInElement,
				[questionName]: questionId,
			};
		}, {});
	}
	return {};
};

export const exportNameAndQuestionId = (template: TTemplate) => {
	const pages = _get(template, 'pages', []) as Array<TTemplate>;
	if (pages && isIterable(pages)) {
		return pages.reduce((accumulator: TFormData, page: TTemplate) => {
			const elements = _get(page, ['elements']);
			const data = exportDataByElements(elements);
			return {
				...accumulator,
				...data,
			};
		}, {});
	}
	return {};
};

export const deepMapKeys = (obj: Object, fn: Function): Object => {
	if (Array.isArray(obj)) {
		return obj.map(val => deepMapKeys(val, fn));
	}
	if (typeof obj === 'object') {
		return Object.keys(obj).reduce((acc: any, current: Object) => {
			const key = fn(current);
			const val = _get(obj, `${current}`);
			// eslint-disable-next-line no-param-reassign
			acc[key] =
				val !== null && typeof val === 'object' ? deepMapKeys(val, fn) : val;
			return acc;
		}, {});
	}
	return obj;
};

export const getFormMode = (status: string) =>
	status === SURVEYJS_STATUS.submitted
		? SURVEYJS_MODE.display
		: SURVEYJS_MODE.edit;

export const switchNameToId = (
	survey: ReactSurveyModel,
	template: TTemplate
) => {
	let nameAndIdInData = {};
	let nameAndIdInTemplate = {};
	const data = _get(survey, ['data'], {});
	if (!_isEmpty(data)) {
		const questions = exportNameAndQuestionId(template);
		nameAndIdInTemplate = deepMapKeys(
			data,
			(name: string) =>
				_get(survey.getQuestionByName(name), 'questionId', '') ||
				_get(questions, name, name)
		);
	}
	if (_has(survey, ['questionHashes', 'names'])) {
		const questionsName = Object.keys(
			_get(survey, ['questionHashes', 'names'], {})
		);
		nameAndIdInData = questionsName.reduce((accumulator, name) => {
			const question = survey.getQuestionByName(name);
			const questionId = _get(question, 'questionId', '');
			const questionDefaultValue = _get(question, 'defaultValue') || '';
			const questionValue = survey.getValue(name) || questionDefaultValue;
			return {
				...accumulator,
				[questionId]: questionValue ?? '',
			};
		}, {});
	}
	return { ...nameAndIdInData, ...nameAndIdInTemplate };
};

export const applyFormProperty = (
	question: TQuestion,
	formProperty: TFormData[]
) => {
	if (_isEmpty(formProperty)) return;
	const questionId = _get(question, 'questionId');
	const formPropertyData = _get(formProperty, questionId) || '';
	if (_isEmpty(formPropertyData)) return;
	const properties = Object.entries(formPropertyData);
	properties.forEach(property => {
		const [propertyName, propertyValue] = property;
		try {
			if (propertyName === 'defaultValue') {
				if (typeof propertyValue === 'string') {
					// eslint-disable-next-line no-param-reassign
					question[propertyName] = propertyValue;
				}
			} else {
				question.setPropertyValue(propertyName, propertyValue);
			}
		} catch (e) {
			// pass this propery
			// avoid improper properties
		}
	});
};

// ReactSurveyModel TFormData TTemplate
export const switchIdToName = (
	survey: ReactSurveyModel,
	data: TFormData,
	template: TTemplate
) => {
	const defaultData = _get(survey, 'data', {});
	let nameAndIdInTemplate = {};
	let nameAndIdInData = {};
	if (data && template) {
		const questions = _invert(exportNameAndQuestionId(template));
		nameAndIdInTemplate = deepMapKeys(data, (id: string) =>
			_get(questions, id, id)
		);
	}
	if (_has(survey, ['questionHashes', 'names'])) {
		const questionsName = Object.keys(
			_get(survey, ['questionHashes', 'names'], {})
		);
		nameAndIdInData = questionsName.reduce((accumulator, name) => {
			const questionId = _get(survey.getQuestionByName(name), 'questionId', '');
			if (data[questionId]) {
				return {
					...accumulator,
					[name]: data[questionId],
				};
			}
			return accumulator;
		}, {});
	}
	return { ...defaultData, ...nameAndIdInData, ...nameAndIdInTemplate };
};

export const formatSurveyData = (
	survey: ReactSurveyModel,
	formTemplate: TTemplate
) => {
	const data = switchNameToId(survey, formTemplate);
	return {
		...data,
	};
};

export const onFilesUploadCallbackHandler = (
	options = {},
	handleUploadFile: (files: File[]) => void
) => {
	const files = _get(options, 'files') || [];
	const question = _get(options, 'question') || {};
	const questionId = _get(question, 'questionId', '');
	const filesToUpload = generateFiles(files);
	if ('callback' in options) {
		// @ts-ignore
		options.callback('success', filesToUpload);
	}
	handleUploadFile(files);
	return { questionId, filesToUpload };
};

export const isFormCounterOffer = (formType: string | undefined) =>
	formType === FORM_TYPE.counterOffer;

export const prepareFilesBeforeUpload = (
	files: Array<File> = [],
	urls: Array<string> = []
) =>
	files.reduce((result: TUploadS3Files, current) => {
		const filesToUpload = _get(current, 'filesToUpload') as Array<TUploadFile>;
		const uploadData = filesToUpload.map((item: TUploadFile) => {
			const uploadUrl = urls.find(urlItem => _get(urlItem, 'id') === item.id);
			return {
				file: _get(item, 'file', {}),
				url: _get(uploadUrl, 'url', ''),
				options: {
					headers: {
						'Content-Type': _get(item, 'type'),
					},
				},
			};
		});

		return [...result, ...uploadData];
	}, []);

export const uploadFilesToS3 = (
	files: Array<File> = [],
	urls: Array<string> = []
) => {
	const fileUploads = prepareFilesBeforeUpload(files, urls) as TUploadS3Files;
	return Promise.all(
		fileUploads.map((data: any) => {
			const url = _get(data, 'url');
			const file = _get(data, 'file');
			const options = _get(data, 'options');
			return uploadFile(url, file, options);
		})
	);
};
