export const listForms = `
  query ListForms($limit: Int, $nextToken: String) {
    listForms(limit: $limit, nextToken: $nextToken) {
      items {
        CategoryId
        CreatedAt
        FormId
        FormStatus
        FormTypeId
        Title
        UpdatedAt
      }
      nextToken
    }
  }
`;
export const getOffer = /* GraphQL */ `
	query GetOffer($OfferId: ID!) {
		getOffer(OfferId: $OfferId) {
			HomeSale {
				Property {
					Status
				}
				OnMarket {
					BrokerageFirm
					ListingAgent {
						FirstName
						LastName
					}
					OnMarketDate
					HasOffers
				}
				Closing {
					ExpectedClosingPeriod
					HasBuyerReleasedContingencies
					ListingAgent {
						FirstName
						LastName
					}
					EscrowCompany
				}
			}
			AdditionalBuyers
			AdditionalBuyerInfo
			AgentInfo {
				DRENumber
				Email
				Phone
				Name
			}
			BuyerInfo {
				FirstName
				LastName
			}
			BuyerType
			Contingencies {
				AllRemoved
				FinanceContingency
				FinanceContingencyDays
				FinanceContingencyRemoved
				InspectionContingency
				InspectionContingencyDays
				InspectionContingencyRemoved
				AppraisalContingency
				AppraisalContingencyDays
				AppraisalContingencyRemoved
				SaleOfHomeContingency
				SaleOfHomeContingencyRemoved
			}
			Offer {
				ClosingPeriod
				FinanceContingency
				Furniture
				InspectionContingency
				OfferPrice
				SaleOfHomeContingency
				FinanceContingency
				FinanceContingencyDays
				InspectionContingency
				InspectionContingencyDays
				AppraisalContingency
				AppraisalContingencyDays
			}
			CounterOffer {
				SellerCounter {
					OfferPrice
					SaleOfHomeContingency
					InspectionContingencyDays
					FinanceContingencyDays
					AppraisalContingencyDays
					InspectionContingency
					FinanceContingency
					AppraisalContingency
					Furniture
					FurnitureOther
					RentBackInititated
					RentBack
					RentBackDailyAmount
					RentBackDays
					ClosingPeriod
					IsBestOffer
				}
				BuyerCounter {
					OfferPrice
					SaleOfHomeContingency
					InspectionContingencyDays
					FinanceContingencyDays
					AppraisalContingencyDays
					InspectionContingency
					FinanceContingency
					AppraisalContingency
					Furniture
					FurnitureOther
					RentBackInititated
					RentBack
					RentBackDailyAmount
					RentBackDays
					ClosingPeriod
					IsBestOffer
				}
				CounterOfferId
				Forms {
					FormId
					FormTypeId
				}
			}
			Entity {
				EntityType
				IndividualOnBehalf
				Name
				TitleName
			}
			FirmInfo {
				CompanyName
				CoordinatorEmail
				CoordinatorFirstName
				CoordinatorLastName
				CoordinatorPhone
				DRENumber
			}
			Funding {
				PurchaseType
				LoanType
				DownPayment
				CashProofOfFundsAmount
				FinancingProofOfFundsAmount
				FicoScore
			}
			Property {
				ListingDetails {
					ListPrice
				}
				SellerInfo {
					Email
					FirstName
					LastName
					Phone
				}
				StatusId
			}
			PropertyData {
				FullAddress
				OwnerNames
			}
			OfferId
			OfferNo
			PropertyId
			PropertyNo
			SCARReport {
				PresignedUrl
			}
			Step {
				allowed_actions {
					action
					form_action
					next_step_id
				}
				allowed_upload_file_types {
					allow_files_count
					allow_single_file_max_size_mb
					file_type
				}
				buyer_can_submit_offer
				display_on_advisor_portal
				display_on_seller_dashboard
				id
				messages {
					advisor
					buyer
					seller
				}
				name
				waiting_seller_to_act
				waiting_buyer_to_act
				waiting_advisor_to_act
			}
			StepId
			UpdatedAt
			UpdatedBy
			CounterOfferHistory {
				BuyerCounter {
					AppraisalContingency
					AppraisalContingencyDays
					SubmittedAt
					Status
					SaleOfHomeContingency
					RentBackInititated
					RentBackDays
					RentBackDailyAmount
					RentBack
					Position
					OfferPrice
					IsBestOffer
					InspectionContingency
					InspectionContingencyDays
					FurnitureOther
					Furniture
					FinanceContingency
					FinanceContingencyDays
					ClosingPeriod
				}
				SellerCounter {
					AppraisalContingency
					AppraisalContingencyDays
					ClosingPeriod
					FinanceContingency
					FinanceContingencyDays
					Furniture
					FurnitureOther
					InspectionContingency
					InspectionContingencyDays
					OfferPrice
					IsBestOffer
					Position
					RentBack
					RentBackDailyAmount
					RentBackDays
					RentBackInititated
					SaleOfHomeContingency
					Status
					SubmittedAt
				}
			}
		}
	}
`;
export const getOffers = /* GraphQL */ `
	query GetOffers(
		$PageIndex: Int
		$PageSize: Int
		$Query: String
		$StepIds: [Int]
		$PropertyId: String
		$SortBy: [AWSJSON]
		$PropertyStatusIds: [Int]
	) {
		getOffers(
			PageIndex: $PageIndex
			PageSize: $PageSize
			Query: $Query
			StepIds: $StepIds
			SortBy: $SortBy
			PropertyId: $PropertyId
			PropertyStatusIds: $PropertyStatusIds
		) {
			Total
			Count
			PageIndex
			PageSize
			Items {
				BuyerType
				BuyerInfo {
					FirstName
					LastName
				}
				AgentInfo {
					Name
				}
				Entity {
					EntityType
					IndividualOnBehalf
					Name
					TitleName
				}
				PropertyId
				PropertyStatusId
				CreatedAt
				Funding {
					DownPayment
					LoanType
					PurchaseType
				}
				OfferId
				OfferNo
				PropertyData {
					FullAddress
					OwnerNames
				}
				Offer {
					OfferPrice
				}
				PropertyId
				PropertyNo
				StepId
				Step {
					messages {
						advisor
					}
					id
				}
				CounterOffer {
					BuyerCounter {
						OfferPrice
						SaleOfHomeContingency
						InspectionContingencyDays
						FinanceContingencyDays
						AppraisalContingencyDays
						InspectionContingency
						FinanceContingency
						AppraisalContingency
						Furniture
						FurnitureOther
						RentBack
						RentBackDailyAmount
						RentBackDays
						ClosingPeriod
						IsBestOffer
					}
					SellerCounter {
						OfferPrice
						SaleOfHomeContingency
						InspectionContingencyDays
						FinanceContingencyDays
						AppraisalContingencyDays
						InspectionContingency
						FinanceContingency
						AppraisalContingency
						Furniture
						FurnitureOther
						RentBack
						RentBackDailyAmount
						RentBackDays
						ClosingPeriod
						IsBestOffer
					}
				}
			}
		}
	}
`;
export const getProperties = /* GraphQL */ `
	query GetProperties(
		$PageIndex: Int
		$PageSize: Int
		$Query: String
		$StatusIds: [Int]
		$SortBy: [AWSJSON]
	) {
		getProperties(
			PageIndex: $PageIndex
			PageSize: $PageSize
			Query: $Query
			StatusIds: $StatusIds
			SortBy: $SortBy
		) {
			Total
			Count
			PageIndex
			PageSize
			Items {
				CreatedAt
				DTPropertyId
				PropertyData {
					City
					County
					FullAddress
					OwnerNames
					State
					StreetAddress
					Zip
				}
				PropertyId
				PropertyNo
				NewPropertyWizardStep
				StatusId
			}
		}
	}
`;

export const getPermissions = /* GraphQL */ `
	query users_Permissions {
		users_Permissions {
			CanCreateForms
			CanDeleteProperties
			CanCreateProperties
			CanManageUsers
			CanUpdateOffers
			CanUpdateForms
			CanUpdateProperties
			CanViewForms
			CanViewOffers
			CanViewProperties
		}
	}
`;
export const getProperty = /* GraphQL */ `
	query GetProperty($PropertyId: ID!) {
		getProperty(PropertyId: $PropertyId) {
			Offers(SellerAcceptedOnly: true) {
				Offer {
					AppraisalContingency
					AppraisalContingencyDays
					ClosingPeriod
					FinanceContingency
					FinanceContingencyDays
					InspectionContingency
					InspectionContingencyDays
				}
				OfferId
				StepId
			}
			PAUploadedDate
			JurisdictionalCost {
				HOADetected
				HOADetectedOverride
				Items {
					ItemKey
					ItemName
					PayAmount
					Payor
					PayorId
					PayorIdOverride
					PayorOverride
					PayAmountOverride {
						Amount
						AmountType
					}
				}
				PayorOptions {
					Payor
					PayorId
				}
			}
			AgentAvidFormDataStatuses {
				CategoryId
				FormDataApproved
				FormDataApprovedAt
				FormDataCreatedAt
				FormDataStatus
				FormDataSubmitted
				FormDataUpdatedAt
				FormId
				FormTypeId
				Title
			}
			AgentAvidFormsSubmitted
			AgentFdFormDataStatuses {
				CategoryId
				FormDataApproved
				FormDataApprovedAt
				FormDataCreatedAt
				FormDataStatus
				FormDataSubmitted
				FormDataUpdatedAt
				FormId
				FormTypeId
				Title
			}
			AgentFdFormsSubmitted
			AdditionalInfo
			ListingTermsConditions
			NewPropertyWizardStep
			BuyerAgentFeePct
			ListingDetails {
				ListPrice
			}
			CalendarUrl
			HomeViewingCalendarProfileId
			CreatedAt
			CreatedBy
			DTPropertyId
			OfferDueDate
			ListPriceRange {
				Max
				MaxFormatted
				Min
				MinFormatted
			}
			ListingAgentFeePct
			ListingTerminationDate
			ListingTermsConditions
			NileAgentId
			ExternalId
			PropertyData {
				Bedrooms
				City
				County
				FullAddress
				FullBath
				LandUse
				LivingArea
				LotArea
				OwnerNames
				State
				StreetAddress
				VestingOwnershipRight
				VestingTypeMapped
				VestingTypeOverride
				VestingTypeUsed
				YearBuilt
				YearBuiltDisp
				YearBuiltOverride
				YearBuiltUsed
				Zip
			}
			PropertyFormDataStatuses {
				Title
				CategoryId
				FormDataApproved
				FormDataApprovedAt
				FormDataStatus
				FormId
				FormTypeId
				FormDataSubmitted
			}
			PropertyId
			PropertyNo
			PropertyReport {
				ObjectKey
				PresignedUrl
				UpdatedAt
			}
			ReportStatus {
				TaxReportTaxResourceMessage
				TaxReportTaxResourceStatus
				TaxReportTaxResourceUpdatedAt
				TaxReportTotalViewMessage
				TaxReportTotalViewStatus
				TaxReportTotalViewUpdatedAt
			}
			SellerInfo {
				Email
				FirstName
				LastName
				Phone
			}
			StatusId
			Thumbnail {
				file {
					path
				}
				id
				key
				name
				presignedUrl
				size
				type
			}
			UpdatedAt
			UpdatedBy
			EscrowNumber
		}
	}
`;

export const getPropertyOffers = /* GraphQL */ `
	query GetPropertyOffers($PropertyId: ID!) {
		getProperty(PropertyId: $PropertyId) {
			PropertyId
			Offers {
				AdditionalBuyerInfo
				AdditionalBuyers
				AgentInfo {
					DRENumber
					Email
					FirstName
					LastName
					Name
					Phone
				}
				BuyerInfo {
					FirstName
					LastName
					MiddleName
					TitleName
				}
				BuyerType
				CounterOffer {
					BuyerCounter {
						OfferPrice
						SaleOfHomeContingency
						InspectionContingencyDays
						FinanceContingencyDays
						AppraisalContingencyDays
						InspectionContingency
						FinanceContingency
						AppraisalContingency
						Furniture
						FurnitureOther
						RentBackInititated
						RentBack
						RentBackDailyAmount
						RentBackDays
						ClosingPeriod
						IsBestOffer
					}
					CounterOfferId
					Forms {
						DataStatus
						FormGroup
						FormId
						FormTypeId
						Name
						Order
					}
					Position
					SellerCounter {
						OfferPrice
						SaleOfHomeContingency
						InspectionContingencyDays
						FinanceContingencyDays
						AppraisalContingencyDays
						InspectionContingency
						FinanceContingency
						AppraisalContingency
						Furniture
						FurnitureOther
						RentBackInititated
						RentBack
						RentBackDailyAmount
						RentBackDays
						ClosingPeriod
						IsBestOffer
					}
				}
				CreatedAt
				CreatedBy
				Entity {
					EntityType
					IndividualOnBehalf
					Name
					TitleName
				}
				FirmInfo {
					CompanyName
					CoordinatorEmail
					CoordinatorFirstName
					CoordinatorLastName
					CoordinatorPhone
					DRENumber
				}
				Funding {
					DownPayment
					FicoScore
					LoanType
					PreapprovedForLoan
					PurchaseType
				}
				NileAgentId
				Offer {
					ClosingPeriod
					FinanceContingency
					Furniture
					FurnitureOther
					InspectionContingency
					OfferPrice
					SaleOfHomeContingency
				}
				OfferId
				OfferNo
				PropertyData {
					Bedrooms
					City
					County
					FullAddress
					FullBath
					LandUse
					LivingArea
					LotArea
					OwnerNames
					State
					StreetAddress
					VestingType
					YearBuilt
					Zip
				}
				PropertyId
				PropertyNo
				SellerAccepted
				Step {
					allowed_actions {
						action
						form_action
						next_step_id
					}
					allowed_upload_file_types {
						allow_files_count
						allow_single_file_max_size_mb
						file_type
					}
					buyer_can_submit_offer
					display_on_advisor_portal
					display_on_seller_dashboard
					id
					messages {
						advisor
						buyer
						seller
					}
					name
					waiting_seller_to_act
				}
				StepId
				UpdatedAt
				UpdatedBy
			}
		}
	}
`;
export const getPropertyDocuments = /* GraphQL */ `
	query GetProperty($PropertyId: ID!) {
		getProperty(PropertyId: $PropertyId) {
			PropertyId
			PAUploadedDate
			Offers {
				OfferId
				OfferNo
				SellerAccepted
			}
			Docs {
				DocPermissions
				DocScope
				DocStatus
				DocType
				EventTime
				File {
					name
					type
					size
				}
				FileId
				ItemType
				ObjectKey
				OfferId
				OfferNo
				PropertyId
			}
		}
	}
`;
export const getSellerCounterOfferFormData = /* GraphQL */ `
	query Formdata_GetSellerCounterOffer(
		$CounterOfferId: String!
		$FormId: String!
	) {
		formdata_GetSellerCounterOffer(
			CounterOfferId: $CounterOfferId
			FormId: $FormId
		) {
			Form {
				Template
			}
			FormData {
				DataStatus
				DataValue
			}
			FormProperty
		}
	}
`;

export const getForm = /* GraphQL */ `
	query GetForm($FormId: ID!) {
		getForm(FormId: $FormId) {
			FormId
			FormStatus
			Template
		}
	}
`;

export const getDocSettings = `
	query GetDocSettings {
		docs_GetDocTypes {
      id
      name
      scope
			available_permissions
		},
		docs_GetDocPermissions {
			id
			name
		},
		docs_GetDocStatuses {
			id
			name
		}
	}
`;

export const getDocsUploadUrls = /* GraphQL */ `
	query GetDocsUploadUrls(
		$FilesToUpload: [ModelDocItemFileInput!]!
		$PropertyId: String!
		$DocStatus: String!
		$DocType: String!
		$DocPermissions: [String!]!
		$OfferId: String
	) {
		docs_GetUploadUrls(
			FilesToUpload: $FilesToUpload
			PropertyId: $PropertyId
			DocType: $DocType
			DocStatus: $DocStatus
			DocPermissions: $DocPermissions
			OfferId: $OfferId
		) {
			url
			objectkey
		}
	}
`;

export const getDocsDownloadUrls = /* GraphQL */ `
	query GetDocsDownloadUrls($ObjectKeys: [String!]!, $PropertyId: String!) {
		docs_GetDownloadUrls(ObjectKeys: $ObjectKeys, PropertyId: $PropertyId) {
			url
			name
		}
	}
`;
export const getUsers = /* GraphQL */ `
	query Users_GetUserProfiles(
		$Enabled: Boolean
		$PageIndex: Int
		$PageSize: Int
		$Query: String
		$SortBy: [AWSJSON]
		$UserGroups: [String]
	) {
		users_GetUserProfiles(
			Enabled: $Enabled
			PageIndex: $PageIndex
			PageSize: $PageSize
			Query: $Query
			SortBy: $SortBy
			UserGroups: $UserGroups
		) {
			Count
			Items {
				Email
				FirstName
				LastName
				Phone
				UserGroups {
					Description
					GroupName
				}
				UserId
				UserStatus
			}
			PageIndex
			PageSize
			Total
		}
	}
`;

export const getUser = /* GraphQL */ `
	query Users_GetUserProfile($UserId: String!) {
		users_GetUserProfile(UserId: $UserId) {
			CreatedAt
			CreatedBy
			Email
			Enabled
			FirstName
			LastName
			LicenseType
			MiddleName
			DRENumber
			BrokerageDRE
			BrokerageName
			Phone
			UpdatedAt
			UpdatedBy
			UserGroups {
				Description
				GroupName
			}
			UserId
			UserStatus
		}
	}
`;

export const getMyProfile = /* GraphQL */ `
	query Users_GetMyUserProfile {
		users_GetMyUserProfile {
			Email
			Enabled
			FirstName
			MiddleName
			LastName
			Phone
			LicenseType
			DRENumber
			BrokerageDRE
			BrokerageName
			UserGroups {
				Description
				GroupName
			}
			UserId
			UserStatus
		}
	}
`;

export const getPropertyForms = /* GraphQL */ `
	query Formdata_GetPropertyFormDataList($PropertyId: String!) {
		formdata_GetPropertyFormDataList(PropertyId: $PropertyId) {
			FormId
			Title
			CategoryId
			FormTypeId
		}
	}
`;
export const getPropertyFormData = /* GraphQL */ `
	query Formdata_GetPropertyFormDataItem(
		$FormId: String!
		$PropertyId: String!
	) {
		formdata_GetPropertyFormDataItem(FormId: $FormId, PropertyId: $PropertyId) {
			Form {
				CategoryId
				CreatedAt
				CreatedBy
				FormId
				FormStatus
				FormTypeId
				Template
				Title
				UpdatedAt
				UpdatedBy
			}
			FormData {
				DataStatus
				DataValue
				FormId
				ItemId
				ItemType
			}
			FormProperty
		}
	}
`;

export const getFormTemplateAndData = /* GraphQL */ `
	query GetFormTemplateAndData($FormId: String!, $ItemId: String!) {
		formdata_GetFormData(FormId: $FormId, ItemId: $ItemId) {
			FormProperty
			FormData {
				DataValue
				DataStatus
			}
			Form {
				Template
				FormTypeId
				Title
			}
		}
	}
`;
