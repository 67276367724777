import {
	isValidPhoneNumber as doValidatePhoneNumber,
	parsePhoneNumber,
} from 'react-phone-number-input';
import _get from 'lodash.get';
import _isEmpty from 'lodash.isempty';
import config from '../config';

export const convertPhoneNumber = (value: string = '') => {
	const phoneNumber = parsePhoneNumber(value, config.locate.countryCode);
	return _get(phoneNumber, 'number', '');
};

export const isValidPhoneNumber = (value: string) => {
	const phone = convertPhoneNumber(value);
	return _isEmpty(value) || doValidatePhoneNumber((phone as string) || '');
};
