import _get from 'lodash.get';
import { FieldPolicy, FetchResult, ApolloCache, gql } from '@apollo/client';
import _isEmpty from 'lodash.isempty';
import _uniqBy from 'lodash.uniqby';
import { TGetProperties, TProperty } from '../../types';
import graphqlSchema from '../../graphql';

export const getProperty: FieldPolicy = {
	merge<T extends TProperty>(existing: T | undefined, incoming: T) {
		if (!existing) return incoming;

		const oldPropertyId = _get(existing, 'PropertyId') || '';
		const newPropertyId = _get(incoming, 'PropertyId') || '';

		if (newPropertyId !== oldPropertyId) return incoming;

		return { ...existing, ...incoming };
	},
};

export const getProperties: FieldPolicy = {
	keyArgs: ['Query', 'StepIds'],
	merge<T extends TGetProperties>(existing: T | undefined, incoming: T) {
		const firstLoad = !_get(incoming, 'PageIndex', 0);
		const noCache = !existing || firstLoad;
		if (noCache) return incoming;

		const newItems = _uniqBy(
			[...existing.Items, ...incoming.Items],
			'PropertyId'
		);

		return {
			Items: newItems,
			Count: existing.Count + incoming.Count,
			Total: incoming.Total,
			PageIndex: incoming.PageIndex,
			PageSize: incoming.PageSize,
		};
	},
};

export const updatePropertyCache = (
	cache: ApolloCache<any>,
	result: FetchResult<TProperty>
) => {
	const updateProperty = _get(result, ['data', 'updatePropertyData'], {});
	const { PropertyId: id } = updateProperty;
	const cachedData = cache.readQuery({
		query: gql(graphqlSchema.getProperty),
		variables: { PropertyId: id },
	});

	const cachedProperty = _get(cachedData, 'getProperty', {});
	const newProperty = { ...cachedProperty, ...updateProperty };

	cache.writeQuery({
		query: gql(graphqlSchema.getProperty),
		data: {
			getProperty: newProperty,
		},
		variables: { PropertyId: id },
	});

	cache.modify({
		fields: {
			getProperties(existingProperties: TGetProperties) {
				if (_isEmpty(updateProperty)) return existingProperties;

				const { Items: items } = existingProperties;
				const filteredItems = items.filter(item => item.PropertyId !== id);
				return { ...existingProperties, Items: [...filteredItems, newProperty] };
			},
		},
	});
};
