import _get from 'lodash.get';
import _isEmpty from 'lodash.isempty';
import { TOffer, TCounter } from '../types';
import config from '../config';
import { formatCurrency } from './currency';
import {
	CONTINGENCY_TYPES,
	displayContingency,
	displayDownPayment,
} from '../views/application/offer/helper';
import { STATUS as PROPERTY_STATUS } from '../views/application/property/config';

const COUNTER_OFFER_STEPS = [
	config.offerSteps.buyerNeedToReviewNewOffer,
	config.offerSteps.sellerNeedToCounter,
];

const BEST_OFFER_VALUE = 'Yes';
export const BEST_OFFER_LABEL = 'Best & Final';
export const EMPTY_TEXT = '-';

export const isBestOffer = (counter: TCounter) => {
	const bestOfferValues: Array<string> = _get(counter, 'IsBestOffer') || [];
	return bestOfferValues.includes(BEST_OFFER_VALUE);
};

export const displayOfferPrice = (counter: TCounter) => {
	if (isBestOffer(counter)) return BEST_OFFER_LABEL;

	const offerPrice = _get(counter, 'OfferPrice');
	return displayPriceValue(offerPrice);
};

export const displayPriceValue = (price: number | null) =>
	price ? formatCurrency(price) : EMPTY_TEXT;

export const getOfferPrice = (offer: TOffer): string => {
	const counterOffer = getLatestCounter(offer);
	const price = displayOfferPrice(counterOffer);
	if (price === EMPTY_TEXT) {
		const offerPrice = _get(offer, ['Offer', 'OfferPrice']);
		return offerPrice ? formatCurrency(offerPrice) : EMPTY_TEXT;
	}
	return price;
};

export const getOfferPriceValue = (offer: TOffer) => {
	const counterOffer = getLatestCounter(offer);
	if (isBestOffer(counterOffer)) return Number.MAX_VALUE;
	return (
		_get(counterOffer, 'OfferPrice', '') ||
		_get(offer, ['Offer', 'OfferPrice']) ||
		0
	);
};

const BUYER_TYPE_INDIVIDUAL = 'Individual';

export const getBuyerName = (offer: TOffer): string => {
	const buyerType = _get(offer, 'BuyerType', '');
	const buyerName =
		buyerType === BUYER_TYPE_INDIVIDUAL
			? `${_get(offer, ['BuyerInfo', 'FirstName'])} ${_get(offer, [
					'BuyerInfo',
					'LastName',
			  ])}`
			: _get(offer, ['Entity', 'Name']);

	return buyerName;
};

const FURNITURE_OTHER = 'other';

export const hasOtherValue = (values: string[]): boolean =>
	values.some(value => value.toLowerCase() === FURNITURE_OTHER);

export const removeOtherValue = (values: string[]): string[] =>
	values.filter(value => value.toLowerCase() !== FURNITURE_OTHER);

export const displayFurniture = (offer: TOffer): string => {
	const furniture = _get(offer, 'CounterOffer.BuyerCounter.Furniture', []);
	if (!furniture || !furniture.length) return '';

	if (hasOtherValue(furniture)) {
		const furnitureOther = _get(
			offer,
			'CounterOffer.BuyerCounter.FurnitureOther',
			''
		);
		const filteredValues = removeOtherValue(furniture).join(', ');
		return `${filteredValues ? `${filteredValues}, ` : ''}${
			furnitureOther || ''
		}`;
	}

	return furniture.join(', ');
};

const ADDITIONAL_BUYERS = {
	YES: 'Yes',
	NO: 'No',
};

export const getAdditionalBuyers = (offer: TOffer): string => {
	const additionalBuyers = _get(offer, 'AdditionalBuyers');
	if (additionalBuyers === ADDITIONAL_BUYERS.NO) return additionalBuyers;

	const additionalBuyerInfo = _get(offer, 'AdditionalBuyerInfo');
	try {
		const buyers = JSON.parse(additionalBuyerInfo);
		return buyers
			.map((buyer: { 'FullName:': string }) => buyer['FullName:'])
			.join(', ');
	} catch (error) {
		return '';
	}
};

export const getSellerName = (offer: TOffer): string => {
	const sellerInfo = _get(offer, 'Property.SellerInfo', {});
	if (_isEmpty(sellerInfo)) return '';
	const { FirstName: first, LastName: last } = sellerInfo;
	return `${first} ${last}`;
};

export const getLatestCounter = (offer: TOffer): TCounter => {
	const stepId = _get(offer, ['StepId']);

	if (COUNTER_OFFER_STEPS.includes(stepId)) {
		return _get(offer, ['CounterOffer', 'SellerCounter']);
	}

	return _get(offer, ['CounterOffer', 'BuyerCounter']);
};

export const isNo = (value: string) => value.toLowerCase() === 'no';

export const isYes = (value: string) => value.toLowerCase() === 'yes';

export const displayRentBack = (counterData: TCounter) => {
	if (_isEmpty(counterData)) return EMPTY_TEXT;
	const {
		RentBackInititated: initialRentBack,
		RentBack: rentBack,
		RentBackDailyAmount: amount,
		RentBackDays: days,
	} = counterData;
	if (!initialRentBack || isNo(initialRentBack))
		return config.defaultText.noValue;

	if (!rentBack || isNo(rentBack)) return 'No';
	const total = +amount * +days || 0;
	const dayUnit = +days > 1 ? 'days' : 'day';
	return `${formatCurrency(+amount) || '$0'} x ${+days} ${dayUnit} = ${
		formatCurrency(total) || '$0'
	}`;
};

export const getTransactionCoordinatorName = (offer: TOffer): string =>
	`${_get(offer, ['FirmInfo', 'CoordinatorFirstName'], '')} ${_get(
		offer,
		['FirmInfo', 'CoordinatorLastName'],
		''
	)}`;

export const getTransactionCoordinatorContact = (offer: TOffer): string => {
	const email = _get(offer, ['FirmInfo', 'CoordinatorEmail'], '');
	const phone = _get(offer, ['FirmInfo', 'CoordinatorPhone'], '');
	if (!email && !phone) return 'N/A';
	if (email && phone) return `${email}/${phone}`;
	return email || phone;
};

export const getOfferInfo = (offer: TOffer) => {
	const counterOffer = getLatestCounter(offer);
	const funding = _get(offer, 'Funding.PurchaseType', '');
	const propertyStatusId = _get(offer, 'PropertyStatusId', 0);

	return {
		funding,
		offerId: _get(offer, 'OfferId'),
		offerNo: _get(offer, 'OfferNo', '') || config.defaultText.noValue,
		buyerName: getBuyerName(offer),
		agentName: _get(offer, ['AgentInfo', 'Name']) || config.defaultText.noValue,
		price: getOfferPrice(offer),
		financeContingency: displayContingency(
			counterOffer,
			CONTINGENCY_TYPES.financeContingency
		),
		loanType: _get(offer, 'Funding.LoanType', '') || config.defaultText.noValue,
		downPayment: displayDownPayment(offer),
		inspectionContingency: displayContingency(
			counterOffer,
			CONTINGENCY_TYPES.inspectionContingency
		),
		appraisalContingency: displayContingency(
			counterOffer,
			CONTINGENCY_TYPES.appraisalContingency
		),
		saleOfHomeContingency: _get(
			counterOffer,
			CONTINGENCY_TYPES.saleOfHomeContingency,
			''
		),
		closingPeriod:
			_get(counterOffer, 'ClosingPeriod', '') || config.defaultText.noValue,
		status: getOfferStatus(offer, propertyStatusId),
	};
};

export const filterOfferSteps = (conditionFilter: (value: number) => boolean) =>
	Object.values(config.offerSteps).filter((step: number) =>
		conditionFilter(step)
	);

export const APPROVED_OFFER_STEPS = filterOfferSteps(
	step =>
		step >= config.offerSteps.paBeingCreated && step <= config.offerSteps.close
);

export const isPropertySold = (propertyStatusId: number) =>
	propertyStatusId === PROPERTY_STATUS.CLOSED;

export const getOfferStatus = (offer: TOffer, propertyStatusId: number) =>
	isPropertySold(propertyStatusId)
		? 'Property Sold'
		: _get(offer, 'Step.messages.advisor', '');
