import { FormattedMessage } from 'react-intl';
import { IconHome } from '@tabler/icons';
import { actions, objectTargets } from '../helpers';
import { PATHS } from '../routes/path';

// constant
const icons = {
	IconHome,
};

export const properties = {
	id: 'properties',
	title: <FormattedMessage id='properties' />,
	type: 'collapse',
	icon: icons.IconHome,
	action: actions.view,
	url: PATHS.PROPERTIES,
	objectTarget: objectTargets.properties,
	children: [
		{
			id: 'property-list',
			title: <FormattedMessage id='list' />,
			type: 'item',
			url: PATHS.PROPERTIES,
			action: actions.view,
			objectTarget: objectTargets.properties,
		},
		{
			id: 'property-add',
			title: <FormattedMessage id='add' />,
			type: 'item',
			url: PATHS.PROPERTY_ADD,
			action: actions.create,
			objectTarget: objectTargets.properties,
		},
	],
};
