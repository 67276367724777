import { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import MinimalLayout from '../layout/MinimalLayout';
import NavMotion from '../layout/NavMotion';
import Loadable from '../ui-component/Loadable';
import { MAINTENANCE_PATHS } from './path';

const Forbidden = Loadable(
	lazy(() => import('../views/pages/maintenance/Forbidden'))
);

const MaintenanceRoutes = () => {
	const location = useLocation();

	return (
		<Route path={[MAINTENANCE_PATHS.FORBIDDEN]}>
			<MinimalLayout>
				<Switch location={location} key={location.pathname}>
					<NavMotion>
						<>
							<Route path={MAINTENANCE_PATHS.FORBIDDEN} component={Forbidden} />
						</>
					</NavMotion>
				</Switch>
			</MinimalLayout>
		</Route>
	);
};

export default MaintenanceRoutes;
