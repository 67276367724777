const STEP_LIMIT = 6;
enum STATUS {
	ONBOARDING_STEP_ID = 1,
	HOME_PREPARATION,
	ON_CONTRACT,
	PENDING,
	CLOSED,
}
const MAXIMUM_STEP = STATUS.CLOSED;

const NEXT_BUTTON: { [key: number]: string } = {
	[STATUS.ONBOARDING_STEP_ID]: 'Onboarding Complete',
	[STATUS.HOME_PREPARATION]: 'Publish on Market',
	[STATUS.ON_CONTRACT]: 'Confirm Acceptance',
	[STATUS.PENDING]: 'Close Property',
};

export { STEP_LIMIT, MAXIMUM_STEP, STATUS, NEXT_BUTTON };
