import { FormattedMessage } from 'react-intl';
import { IconDashboard } from '@tabler/icons';
import { PATHS } from '../routes/path';

export const dashboard = {
	id: 'dashboard',
	title: <FormattedMessage id='dashboard' />,
	type: 'item',
	icon: IconDashboard,
	isByPassCheckPermission: true,
	url: PATHS.DASHBOARD,
};
