import { combineReducers } from 'redux';

// reducer import
import calendarReducer from '../views/application/property/components/Calendar/redux/reducer';
import customizationReducer from './customizationReducer';
import snackbarReducer from './snackbarReducer';

// -----------------------|| COMBINE REDUCER ||-----------------------//

const reducer = combineReducers({
	customization: customizationReducer,
	snackbar: snackbarReducer,
	calendar: calendarReducer,
});

export default reducer;
