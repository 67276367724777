import { FormattedMessage } from 'react-intl';
import { IconUser } from '@tabler/icons';
import { actions, objectTargets } from '../helpers';
import { PATHS } from '../routes/path';

const icons = {
	IconUser,
};

export const users = {
	id: 'users',
	title: <FormattedMessage id='users' />,
	type: 'collapse',
	icon: icons.IconUser,
	action: actions.manage,
	objectTarget: objectTargets.users,
	children: [
		{
			id: 'users-list',
			title: <FormattedMessage id='list' />,
			type: 'item',
			url: PATHS.USERS_LIST,
			breadcrumbs: false,
			action: actions.manage,
			objectTarget: objectTargets.users,
		},
		{
			id: 'uses-add',
			title: <FormattedMessage id='add' />,
			type: 'item',
			url: PATHS.USER_ADD,
			breadcrumbs: false,
			action: actions.manage,
			objectTarget: objectTargets.users,
		},
	],
};
