import { PaletteMode } from '@material-ui/core';
import { CountryCode } from 'libphonenumber-js';
import { uploadFile, TUploadFileConfig } from './file';

const config: {
	basename: string;
	defaultPath: string;
	fontFamily: string;
	borderRadius: number;
	outlinedFilled: boolean;
	theme: PaletteMode;
	presetColor: string;
	i18n: string;
	rtlLayout: boolean;
	awsAppSyncConfig: {
		endpoint: string;
		region: string;
		userPoolId: string;
		userPoolWebClientId: string;
	};
	pagination: {
		limit: number;
	};
	locate: {
		country: string;
		currency: string;
		timezone: string;
		countryCode: CountryCode;
		currencySymbol: string;
		currencyDecimal: number;
		currencyThousand: string;
	};
	sentry: {
		dsn: string;
		env: string;
	};
	offerSteps: {
		pleaseComplete: number;
		awaitingAdvisorApproval: number;
		pleaseResubmit: number;
		advisorNeedToReviewNewOffer: number;
		buyerNeedToReviewNewOffer: number;
		advisorNeedToReviewOffer: number;
		sellerNeedToReviewSuggestion: number;
		sellerNeedToCounter: number;
		advisorNeedToReReviewByBuyerDenied: number;
		sellerNeedToReReviewSuggestion: number;
		advisorNeedToReReviewSuggestion: number;
		paBeingCreated: number;
		paBeingSigned: number;
		confirmTransfer: number;
		scheduleAProperty: number;
		submitCorrectiveAction: number;
		reviewScarRequest: number;
		removeContingencies: number;
		close: number;
		advisorDecline: number;
		sellerDecline: number;
		advisorCancel: number;
	};
	formTypes: {
		counterOfferSubmission: string;
	};
	uploadFile: TUploadFileConfig;
	dispatcherUrl: string;
	enableCalendar: boolean;
	enabledDocuments: boolean;
	defaultText: {
		noValue: string;
		noneValue: string;
		query: string;
	};
} = {
	// basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
	// like '/berry-material-react/react/default'
	basename: '/',
	defaultPath: '/dashboard',
	fontFamily: "'Poppins', sans-serif",
	borderRadius: 12,
	outlinedFilled: true,
	theme: 'light',
	presetColor: 'theme4', // default, theme1, theme2, theme3, theme4, theme5, theme6
	// 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
	i18n: 'en',
	rtlLayout: false,
	awsAppSyncConfig: {
		endpoint: process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT ?? '',
		region: process.env.REACT_APP_APPSYNC_REGION ?? '',
		userPoolId: process.env.REACT_APP_COGNITO_USER_POOLS_ID ?? '',
		userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID ?? '',
	},
	pagination: {
		limit: 25,
	},
	locate: {
		country: 'en-US',
		currency: 'USD',
		timezone: 'America/Los_Angeles',
		countryCode: 'US',
		currencySymbol: '$',
		currencyDecimal: 0,
		currencyThousand: ',',
	},
	sentry: {
		dsn: process.env.REACT_APP_SENTRY_DSN ?? '',
		env: process.env.REACT_APP_SENTRY_ENV ?? '',
	},
	offerSteps: {
		pleaseComplete: 1,
		awaitingAdvisorApproval: 100,
		pleaseResubmit: 150,
		advisorNeedToReviewNewOffer: 200,
		buyerNeedToReviewNewOffer: 220,
		advisorNeedToReviewOffer: 230,
		advisorNeedToReReviewByBuyerDenied: 240,
		sellerNeedToReviewSuggestion: 250,
		sellerNeedToCounter: 260,
		sellerNeedToReReviewSuggestion: 270,
		advisorNeedToReReviewSuggestion: 290,
		paBeingCreated: 300,
		paBeingSigned: 350,
		confirmTransfer: 500,
		scheduleAProperty: 600,
		submitCorrectiveAction: 700,
		reviewScarRequest: 720,
		removeContingencies: 800,
		close: 900,
		advisorDecline: 910,
		sellerDecline: 920,
		advisorCancel: 940,
	},
	formTypes: {
		counterOfferSubmission: 'cof',
	},
	uploadFile,
	dispatcherUrl: process.env.REACT_APP_DISPATCHER_URL ?? '/',
	enableCalendar: process.env.REACT_APP_ENABLE_CALENDAR === '1',
	enabledDocuments: process.env.REACT_APP_ENABLE_DOCUMENTS === '1',
	defaultText: {
		noValue: 'N/A',
		noneValue: 'NONE',
		query: '*',
	},
};

export default config;
