export const SURVEYJS = {
	theme: 'modern',
	metaData: 'JsonObject.metaData',
	widgetCollectionInstance: 'CustomWidgetCollection.Instance',
	customType: 'customtype',
	fileContent: 'data:image/png;base64,',
};
export const ELEMENTS_TYPE = {
	datetimePicker: 'datetimepicker',
	file: 'file',
	radioGroup: 'radiogroup',
	singleInput: 'text',
	multipleText: 'multipletext',
	checkbox: 'checkbox',
	dropdown: 'dropdown',
	comment: 'comment',
	matrixSingle: 'matrix',
	matrixDynamic: 'matrixdynamic',
	matrixMultiple: 'matrixdropdown',
	boolean: 'boolean',
	phoneInput: 'phoneinput',
	expression: 'expression',
	text: 'text',
	multipleTextItem: 'multipletextitem',
	matrixDropdownColumn: 'matrixdropdowncolumn',
	panelDynamic: 'paneldynamic',
};
export const ELEMENTS_TITLE = {
	datetimePicker: 'Datetime Picker',
	phoneInput: 'Phone Input',
};
export const COLOR = {
	primary: '#44b07b',
};
export const SURVEY_CREATOR = {
	theme: 'default',
};
export const SURVEYJS_PROPERTY = {
	action: 'action',
	flag: 'formFlag',
	questionId: 'questionId',
	panelCssType: 'panelCssType',
	expressionCssType: 'expressionCssType',
};

export const PROPERTY_TYPE = {
	string: 'text',
	number: 'number',
};

export const SURVEYJS_SECTION = {
	survey: 'survey',
	question: 'question',
	panel: 'panel',
};

export const SURVEYJS_CATEGORY = {
	GENERAL: 'general',
};

export const SURVEYJS_COMMON_CSS_CLASSES = {
	title: 'sv-title-text',
	mainRoot: 'sv-form-control',
};

export const SURVEYJS_MODE = {
	edit: 'edit',
	display: 'display',
};

export const SURVEYJS_STATUS = {
	submitted: 'Submitted',
};

export const FORM_STATUS = {
	editing: 'Editing',
	submitted: 'Submitted',
	suspended: 'Suspended',
};

export const FORM_ITEM_TYPE = {
	property: 'Property',
	offer: 'Offer',
};
export const FILE_UPLOADER_ACTION_ADD = 'add';

export const FORM_TYPE = {
	counterOffer: 'cof',
};
