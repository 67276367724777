import { createContext, FC, useEffect } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import _get from 'lodash.get';
import graphqlSchema from '../graphql';
import { AclContextState } from '../types';
import { userCan } from '../helpers';

const contextDefaultValues: AclContextState = {
	permissions: undefined,
	userCan: () => false,
	fetchPermissions: () => false,
};

const AclContext = createContext<AclContextState>(contextDefaultValues);

const AclProvider: FC = ({ children }) => {
	const [doFetch, { client, data }] = useLazyQuery(
		gql(graphqlSchema.getPermissions)
	);
	const permissions = _get(data, 'users_Permissions');

	useEffect(() => {
		doFetch();
	}, []);

	const fetchPermissions = () => {
		client?.resetStore();
		doFetch();
	};

	const can =
		(object: string) =>
		(action: string) =>
		(isByPassCheckPermission: boolean = false) => {
			if (isByPassCheckPermission) return true;
			return userCan(action, object, permissions);
		};

	return (
		<AclContext.Provider
			value={{
				fetchPermissions,
				permissions,
				userCan: can,
			}}
		>
			{children}
		</AclContext.Provider>
	);
};

export { AclContext, AclProvider };
