import { v4 as uuidv4 } from 'uuid';
import { TUploadFile, TFile, TFileItem } from '../types';

export const generateFiles = (files: File[]): TUploadFile[] =>
	files.map((file: any) => {
		const { name, path, size, type } = file;
		const id = uuidv4();
		return {
			name,
			file: {
				path,
			},
			id,
			size,
			type,
			action: 'add',
		};
	});

export const addPreviewProp = (files: File[]): TFile[] =>
	files.map(file =>
		Object.assign(file, {
			preview: URL.createObjectURL(file),
		})
	);

export const convertToFile = (fileItem: TFileItem): TFile => {
	const { id, name, type, presignedUrl } = fileItem;
	const file = Object.assign(new File([''], name, { type }), {
		id,
		preview: presignedUrl,
	});
	return file;
};

export const convertBytesToMib = (size: number) => size / 1024 / 1024;
